import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getCookie, setCookie, deleteCookie } from "../../../Cookies";
import { functions } from "../../../Firebase";
import loginImg from "../../../utilities/images/algaith.png";
import responsiveLoginImg from "../../../utilities/images/svgImages/responsive-login-img.svg";
import classes from "../../styles/LoginpageStyle/LoginPage.module.css";
import loginLogo from "../img/logo/logo.png";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { httpsCallable } from "firebase/functions";
import {
  displayOnDesktop,
  displayOnMobile,
  responsiveReverseFlexDirection,
} from "../../../utilities/commonStyles/commonStyles";

import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
} from "firebase/auth";

import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { auth } from "../../../Firebase";

import "../../styles/Training/Training.css";
import "../../Translater/Translater";
import { IP } from "../../../config";
import axios from "axios";
const { createTheme } = require("@mui/material");

function UpadtePassword({ messagingToken }) {
  const navigate = useNavigate();
  const goToHome = () => navigate("/");
  const location = useLocation();

  const goToUserDashboard = () => navigate("/user/home");
  const goToAdminDashboard = () => navigate("/admin");
  const goToInstructorDashboard = () => navigate("/admin");
  const fetchUserProfile = httpsCallable(functions, "fetchUserProfile");
  const updateUserProfileInvitation = httpsCallable(
    functions,
    "updateUserProfileInvitation"
  );
  // const [role, setRole] = useState("");
  const saveToken = httpsCallable(functions, "saveToken");
  const [user, setUser] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [userNameError, setUserNameError] = useState("");
  const [userSetNotification, setUserSetNotification] = useState("");
  const [notification, setNotification] = useState("");
  const [loading, setLoading] = useState(false);

  const [keep, setKeep] = useState(false);
  ///========visibility password=======//
  const [showPassword, setShowPassword] = useState(false);

  const [newPassword, setNewPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();

  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const clearInputs = () => {
    setUserNameError("");
    setPassword("");
    setNotification("");
  };

  const clearErrors = () => {
    setNotification("");
    setUserNameError("");
    setPasswordError("");
  };

  ///if user is logged in, redirect to user dashboard
  // console.log(location.state);
  const handleLogin = () => {
    clearErrors();
    if (!newPassword || !confirmPassword) {
      alert("Please fill all fields");
      return;
    }
    if (newPassword.length < 6) {
      alert("Password must be at least 6 characters");
      return;
    }
    if (!userName) {
      alert("Please enter your current password");
    }
    if (newPassword != confirmPassword) {
      alert("New password and confirm password are not match");
      return;
    }
    const user = auth.currentUser;
    // console.log(user)
    setLoading(true);

    //  updatePassword
    let configProfile = {
      method: "post",
      // params: { userId: uid },
      url: `${IP}/auth/updatePassword`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${location.state?.idToken}`,
      },
      data: JSON.stringify({
        current: userName,
        newpass: newPassword,
      }),
    };

    axios(configProfile)
      .then(async (userCred) => {
        //console.log(userCred);
        // await updatePassword(userCred.user, newPassword);

        let configProfile = {
          method: "patch",
          // params: { userId: uid },
          url: `${IP}/userProfile/updateUserProfileInvitation`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${location.state?.idToken}`,
          },
          data: JSON.stringify({
            gs: "sj",
          }),
        };

        axios(configProfile)
          .then((r) => {
            setLoading(false);
            navigate("/");
          })
          .catch((e) => {
            setLoading(false);
          });
        alert("Password Changed");
      })
      .catch((error) => {
        if (error.response?.data?.error == "Incorrect old password") {
          setNotification("Incorrect Temporary password");
          alert("Incorrect Temporary password");
          setLoading(false);
        }
        // Incorrect old password
      });
  };

  const handleLogout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        goToHome();
      })
      .catch((error) => {
        // An error happened.
      });
    //   fire.auth().signOut();
  };

  const authListener = () => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        clearInputs();
        const uid = user.uid;
        // //console.log(user.accessToken);
        setUser(user);
      } else {
        setUser("");
      }
    });
  };

  const setKipemeLog = (e) => {
    setKeep(!keep);
    ////console.log(keep)
    //  //console.log(e)
  };
  useEffect(() => {
    authListener();
  }, []);
  //console.log(keep)
  return (
    <Box sx={{ my: { xs: "50px", md: "0" } }}>
      <Container
        sx={{
          ...responsiveReverseFlexDirection,
          height: { xs: "auto", md: "100vh" },
          gap: "20px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: { xs: "100%", md: "50%" },
            border: "3px solid #007848",
            borderRadius: "10px",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <img className={classes.logo} src={loginLogo} alt="" />
          </Box>
          <Box sx={{ padding: { xs: "25px", md: "50px" } }}>
            <Box>
              <Typography
                sx={{
                  color: (theme) => theme.palette.primary.main,
                  fontWeight: 700,
                  marginTop: "-20px",
                  fontSize: { xs: "24px", md: "48px" },
                }}
              >
                Update Password
              </Typography>
              <Typography
                sx={{
                  my: 2,
                  color: (theme) => theme.palette.primary.btn,
                  fontSize: { xs: "10px", md: "18px" },
                }}
              >
                Enter your credentials
              </Typography>
            </Box>
            <form
              action=""
              onSubmit={(e) => {
                e.preventDefault();
                handleLogin();
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontWeight: "600",
                    mb: 1,
                    color: (theme) => theme.palette.primary.main,
                    fontSize: { xs: "12px", md: "16px" },
                  }}
                >
                  Temporary Password
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    borderRadius: "6px",
                    padding: "10px 15px",
                    border: "1px solid #007848",
                    outline: "none",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="text"
                    required
                    id="tsr"
                    autofocus={userName.length == 0}
                    value={userName}
                    autocomplete="off"
                    onChange={(e) => setUserName(e.target.value)}
                    placeholder="password"
                    style={{
                      width: "100%",
                      padding: "7px",
                      border: "none",
                      outline: "none",
                    }}
                  />
                </Box>
              </Box>
              <Box>
                {userSetNotification && (
                  <span style={{ color: "red", marginTop: "20px" }}>
                    {userSetNotification}
                  </span>
                )}
              </Box>
              <Box sx={{ mt: 3, marginBottom: "2px" }}>
                <Typography
                  sx={{
                    fontWeight: "600",
                    mb: 1,
                    color: (theme) => theme.palette.primary.main,
                    fontSize: { xs: "12px", md: "16px" },
                  }}
                >
                  Enter New Password
                </Typography>
                <div
                  style={{
                    width: "100%",
                    borderRadius: "6px",
                    padding: "10px 15px",
                    border: "1px solid #007848",
                    outline: "none",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    required
                    value={newPassword}
                    id={showPassword ? "showPassword" : "hiddePassword"}
                    onChange={(e) => setNewPassword(e.target.value)}
                    style={{ border: "none", outline: "none", width: "90%" }}
                  />
                  <div onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? (
                      <Tooltip title="Hide Password" placement="top-start">
                        <VisibilityIcon sx={{ color: "#007848" }} />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Show Password" placement="top-start">
                        <VisibilityOffIcon sx={{ color: "#007848" }} />
                      </Tooltip>
                    )}
                  </div>
                </div>
              </Box>

              <Box sx={{ mt: 3, marginBottom: "2px" }}>
                <Typography
                  sx={{
                    fontWeight: "600",
                    mb: 1,
                    color: (theme) => theme.palette.primary.main,
                    fontSize: { xs: "12px", md: "16px" },
                  }}
                >
                  Confirm Password
                </Typography>
                <div
                  style={{
                    width: "100%",
                    borderRadius: "6px",
                    padding: "10px 15px",
                    border: "1px solid #007848",
                    outline: "none",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    placeholder="Password"
                    required
                    value={confirmPassword}
                    id={
                      showConfirmPassword
                        ? "showConfirmPassword"
                        : "hiddePassword"
                    }
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    style={{ border: "none", outline: "none", width: "90%" }}
                  />
                  <div
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  >
                    {showConfirmPassword ? (
                      <Tooltip title="Hide Password" placement="top-start">
                        <VisibilityIcon sx={{ color: "#007848" }} />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Show Password" placement="top-start">
                        <VisibilityOffIcon sx={{ color: "#007848" }} />
                      </Tooltip>
                    )}
                  </div>
                </div>
              </Box>
              <Box>
                {notification && (
                  <span style={{ color: "red", marginTop: "20px" }}>
                    {notification}
                  </span>
                )}
              </Box>

              <Box sx={{ mt: 3 }}>
                <Button
                  // onClick={handleLogin}
                  type="submit"
                  sx={{
                    textTransform: "none",
                    width: "100%",
                    py: 1,
                    borderRadius: "15px",
                    fontSize: "16px",
                    fontWeight: 500,
                    background: (theme) => theme.palette.primary.main,
                    color: "#fff",
                    "&:hover": {
                      background: (theme) => theme.palette.primary.main,
                    },
                  }}
                >
                  {loading ? "Saving ..." : "Save"}
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
        <Box
          sx={{
            ...displayOnDesktop,
            width: "50%",
            maxHeight: "630px",
            borderRadius: "10px",
          }}
        >
          <img
            style={{ width: "100%", height: "630px", borderRadius: "10px" }}
            src={loginImg}
            alt=""
          />
        </Box>
        <Box
          sx={{
            ...displayOnMobile,
            width: "100%",
            borderRadius: "10px",
          }}
        >
          <img
            style={{ width: "100%", borderRadius: "10px" }}
            src={responsiveLoginImg}
            alt=""
          />
        </Box>
      </Container>
    </Box>
  );
}

export default UpadtePassword;

/* <div className="loginpage">
    <div className="loginpage-main">
      <div className="loginpage-main-left">
        <div className="loginpage-main-left-cnt">
          <div className="loginpage-main-left-cnt-items">
            <div className="loginpage-main-left-cnt-items-text-1">Log in</div>
            <div className="loginpage-main-left-cnt-items-text-2">
              Log in with your credentials provided by admin
            </div>
            <div className="loginpage-main-left-cnt-items-text-3">
              Enter Your Username
            </div>
            <div className="loginpage-main-left-cnt-items-input">
              <input type="text" placeholder="name@domain.com" required
                value={userName}
                onChange={(e) => setUserName(e.target.value)} />
              <p className="errorMsg">{userNameError}</p>
            </div>
            <div className="loginpage-main-left-cnt-items-text-4">
              Enter Your Password
            </div>
            <div className="loginpage-main-left-cnt-items-input">
              <input type="password" placeholder="At least 8 charactor" required
                value={password}
                onChange={(e) => setPassword(e.target.value)} />
              <p className="errorMsg"> {passwordError}</p>
            </div>
            <div className="loginpage-main-left-cnt-items-text-5">
              <FormControlLabel
                control={<Checkbox color="success" />}
                label="Keep me logged in"
              />
            </div>
            <div className="loginpage-main-left-cnt-items-login-btn">
              <button onClick={handleLogin}>Log in</button>
            </div>
            <div className="loginpage-main-left-cnt-items-text-6">
              Forgot Password?
            </div>
          </div>
        </div>
      </div>
      <div className="loginpage-main-right">
        <div className="loginpage-main-right-cnt">
          <img src={loginImg} alt="loading" />
        </div>
      </div>
    </div>
  </div> */
