import { CREATEPROGRESS } from "./action";

const initialstate = {
  progress: {},
};

export const userProgressReducer = (
  store = initialstate,
  { type, payload }
) => {
  switch (type) {
    case CREATEPROGRESS:
      // console.log("hii");
      return { ...store, progress: { ...payload } };

    default:
      return store;
  }
};

// export const userScoreReducer = (state = {}, action) => {
//   switch (action.type) {
//     case "create":
//       return { score: action.payload };
//     default:
//       return state;
//   }
// };
