import { CREATESCORE } from "./Action";

const initialstate = {
  score: {},
};

export const userScoreReducer = (store = initialstate, { type, payload }) => {
  switch (type) {
    case CREATESCORE:
      // console.log("hii");
      return { ...store, score: { ...payload } };

    default:
      return store;
  }
};

// export const userScoreReducer = (state = {}, action) => {
//   switch (action.type) {
//     case "create":
//       return { score: action.payload };
//     default:
//       return state;
//   }
// };
