import { Timestamp } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { functions } from "../../../Firebase";
import Navbar from "../../Component/NavbarTop";
import Sidebar from "../../Component/Sidebar";
import "../StyleAdminComponents/GroupList.css";
import Notify from "../Dashboard/Notify";
import "../Dashboard/OverdueEnrollments.css";

import { create } from "sortablejs";
import { IP } from "../../../config";
import { getCookie } from "../../../Cookies";
import axios from "axios";
function GroupEnrollmentView() {
  const { clrs } = useSelector((state) => state.createClr);
  // const location = useLocation();
  // const overDueInfo = location.state;
  // //console.log(overDueInfo);

  const courseOverdueUserNotificationCallable = httpsCallable(
    functions,
    "courseOverdueUserNotificationCallable"
  );
  const groupEnrollmentView = httpsCallable(functions, "groupEnrollmentView");
  const fetchGroups = httpsCallable(functions, "fetchGroups");
  const [openEnrollment, setOpenEnrollment] = useState(false);
  const [reportLoading, setReportLoading] = useState(true);
  const [showLoading, setLoading] = useState(false);
  const [overDueArray, setOverDueArray] = useState(null);
  const [details, setDetails] = useState(null);
  const [arrLength, setArrLength] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [displayNotify, setDisplayNotify] = useState(false);
  const [courseName, setCourseName] = useState([]);
  const [newEmployeeArray, setNewEmployeeArray] = useState(null);

  const [selectDate, setSelectDate] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [courseType, setCourseType] = useState("");
  const [selectDefault, setselectDefault] = useState("default");

  ///////Company DAta///////////

  /////////////////////Group ////////
  const [getApiDataForGroupName, setGetApiDataForGroupName] = useState([]);
  const [selectGroupName, setSelectGroupName] = useState("");
  const [getDataForASelectedGroup, setGetDataForASelectedGroup] = useState([]);
  const [groupCourseName, setGroupCourseName] = useState("");
  const [getDataForASelectedCourse, setGetDataForASelectedCourse] = useState(
    []
  );
  const [tempstateForLoading, setTempStateForLoading] = useState("test");
  const initState = {
    courseName: "default",
    date: "",
  };
  const [query, setQuery] = useState(initState);
  const stateDataForGroupName = useLocation();
  const [groupNameFromState, setGroupNameFromState] = useState(
    stateDataForGroupName.state.groupId
  );

  //console.log("overDueArray", overDueArray);

  const fetchGroupDataFromApi = (e) => {
    setLoading(true);
    //console.log(e.target.value)

    setSelectGroupName(e.target.value);
    let tempArr = getApiDataForGroupName.filter(
      (item) => item.groupId == e.target.value
    );
    //console.log(tempArr)
    let filterdata = tempArr[0].courseList.map((e) => ({
      ...e,
      courseName: e.id.split("_")[0],
    }));
    //console.log(filterdata)

    setGetDataForASelectedGroup(filterdata);
    setLoading(false);
    //console.log(getDataForASelectedGroup)
  };

  const callApiForGetCourse = (e, type) => {
    //console.log(e.target.value)

    if (type == "course") {
      setLoading(true);
      setGroupCourseName(e.target.value);
      if (e.target.value == "All") {
        let configgroupEnrollment = {
          method: "post",
          // params: { tempuid: data.id },
          url: `${IP}/groupEnrollment/userInfo`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("bearer")}`,
          },
          data: JSON.stringify({
            group: selectGroupName,
          }),
        };
        axios(configgroupEnrollment)
          .then((res) => {
            setLoading(false);
            //console.log(res.data)
            let filterdata = res.data.map((e) => ({ ...e, checked: false }));
            //console.log(filterdata)
            //  setGetDataForASelectedCourse(filterdata)
            //  setDetails(filterdata)
            setArrLength(filterdata.length);

            let finalFilterData = filterdata.map((item) => {
              let status = "In Progress";
              if (
                item.issuedOn !== undefined &&
                item.issuedOn != "" &&
                item.expiry != "" &&
                item.expiry !== undefined &&
                item.expiry?._seconds > Timestamp.fromDate(new Date()).seconds
              ) {
                status = "complete";
              } else if (
                item.issuedOn == "" &&
                item.dueDate !== undefined &&
                item.dueDate != "" &&
                item.dueDate?._seconds < Timestamp.fromDate(new Date()).seconds
              ) {
                status = "overdue";
              } else if (
                item.expiry !== undefined &&
                item.expiry != "" &&
                item.expiry._seconds > Timestamp.fromDate(new Date()).seconds
              ) {
                status = "expired";
              } else if (item.renewed == true) {
                status = "renew";
              } else if (item.finalStatus == "fail") {
                status = "fail";
              }
              return { ...item, status: status };
            });

            setGetDataForASelectedCourse(finalFilterData);
            setDetails(finalFilterData);

            //console.log(finalFilterData)
          })
          .catch((err) => {
            setLoading(false);
          });
      } else {
        setLoading(true);

        let configgroupEnrollment = {
          method: "post",
          // params: { tempuid: data.id },
          url: `${IP}/groupEnrollment/userInfo`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("bearer")}`,
          },
          data: JSON.stringify({
            group: groupNameFromState,
          }),
        };
        axios(configgroupEnrollment)
          .then((res) => {
            setLoading(false);
            //console.log(res.data)
            let filterdata = res.data.map((e) => ({ ...e, checked: false }));
            //console.log(filterdata)

            setArrLength(filterdata.length);

            let finalFilterData = filterdata.map((item) => {
              let status = "In Progress";
              if (
                item.issuedOn !== undefined &&
                item.issuedOn != "" &&
                item.expiry != "" &&
                item.expiry !== undefined &&
                item.expiry?._seconds > Timestamp.fromDate(new Date()).seconds
              ) {
                status = "complete";
              } else if (
                item.issuedOn == undefined ||
                (item.issuedOn == "" &&
                  item.dueDate !== undefined &&
                  item.dueDate != "" &&
                  item.dueDate?._seconds <
                    Timestamp.fromDate(new Date()).seconds)
              ) {
                status = "overdue";
              } else if (
                item.expiry !== undefined &&
                item.expiry != "" &&
                item.expiry._seconds > Timestamp.fromDate(new Date()).seconds
              ) {
                status = "expired";
              } else if (item.renewed == true) {
                status = "renew";
              } else if (item.finalStatus == "fail") {
                status = "fail";
              }
              return { ...item, status: status };
            });

            setGetDataForASelectedCourse(finalFilterData);
            setDetails(finalFilterData);

            //console.log(finalFilterData)
          })
          .catch((err) => {
            setLoading(false);
          });
      }
    }

    // <option value="all">All</option>
    // <option value="overdue">Overdue</option>
    // <option value="complete">Complete</option>
    // <option value="Expired">Expired</option>
    // <option value="renew">Renew</option>
    else if (type == "status") {
      if (e.target.value == "all") {
        setDetails(getDataForASelectedCourse);
      } else if (e.target.value == "complete") {
        let dataTemp = getDataForASelectedCourse.filter((item) => {
          if (item.status == "complete") {
            return item;
          }
        });
        //console.log(dataTemp)
        setArrLength(dataTemp.length);
        setDetails(dataTemp);
      } else if (e.target.value == "overdue") {
        let dataTemp = getDataForASelectedCourse.filter((item) => {
          if (item.status == "overdue") {
            return item;
          }
        });
        //console.log(dataTemp)
        setArrLength(dataTemp.length);
        setDetails(dataTemp);
      } else if (e.target.value == "expired") {
        let dataTemp = getDataForASelectedCourse.filter((item) => {
          if (item.status == "expired") {
            return item;
          }
        });
        //console.log(dataTemp)
        setArrLength(dataTemp.length);
        setDetails(dataTemp);
      } else if (e.target.value == "renew") {
        // let dataTemp= getDataForASelectedCourse.filter((item)=>{  if(item.expiry!=""&&item.expiry._seconds>Timestamp.fromDate(new Date()).seconds){

        //    return item
        //  }

        // }
        //  )
        //  //console.log(dataTemp)
        let dataTemp = getDataForASelectedCourse.filter((item) => {
          if (item.status == "renew") {
            return item;
          }
        });
        setArrLength(dataTemp.length);
        setDetails(dataTemp);
      } else if (e.target.value == "failed") {
        // let dataTemp= getDataForASelectedCourse.filter((item)=>{  if(item.expiry!=""&&item.expiry._seconds>Timestamp.fromDate(new Date()).seconds){

        //    return item
        //  }

        // }
        //  )
        //  //console.log(dataTemp)
        let dataTemp = getDataForASelectedCourse.filter((item) => {
          if (item.status == "fail") {
            return item;
          }
        });
        setArrLength(dataTemp.length);
        setDetails(dataTemp);
      }
    }
  };

  useEffect(() => {
    setLoading(true);

    let configgroupEnrollment = {
      method: "post",
      // params: { tempuid: data.id },
      url: `${IP}/groupEnrollment/userInfo`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        group: groupNameFromState,
        courseId: stateDataForGroupName.state.courseId,
        select: "group",
      }),
    };
    axios(configgroupEnrollment)
      .then((res) => {
        setLoading(false);
        //console.log(res.data)
        let filterdata = res.data.map((e) => ({ ...e, checked: false }));
        //console.log(filterdata)
        //  setGetDataForASelectedCourse(filterdata)
        //  setDetails(filterdata)
        setArrLength(filterdata.length);

        let finalFilterData = filterdata.map((item) => {
          let status = "In Progress";
          if (
            item.issuedOn !== undefined &&
            item.issuedOn != "" &&
            item.expiry != "" &&
            item.expiry !== undefined &&
            item.expiry?._seconds > Timestamp.fromDate(new Date()).seconds
          ) {
            status = "complete";
          } else if (
            item.issuedOn == "" &&
            item.dueDate !== undefined &&
            item.dueDate != "" &&
            item.dueDate?._seconds < Timestamp.fromDate(new Date()).seconds
          ) {
            status = "overdue";
          } else if (
            item.expiry !== undefined &&
            item.expiry != "" &&
            item.expiry._seconds > Timestamp.fromDate(new Date()).seconds
          ) {
            status = "expired";
          } else if (item.renewed == true) {
            status = "renew";
          } else if (item.finalStatus == "fail") {
            status = "fail";
          }
          return { ...item, status: status };
        });

        setGetDataForASelectedCourse(finalFilterData);
        setDetails(finalFilterData);

        //console.log(finalFilterData)
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  const hendelChecked = (id, type) => {};

  const selectedDateTohumanDate = (type) => {
    // //console.log(result.data)
    //  setDetails(newEmployeeArray);

    if (type == "clear") {
      setDetails([]);
      setArrLength(0);
      setCurrentPage(0);
    }
    // //console.log(newarr)
  };

  // //console.log("courseName",courseName);
  const clickhandler = (i) => {
    if (i < 0) return;
    if (i + 1 > Math.ceil(arrLength / pageSize)) return;
    //TODO: limit upper
    setCurrentPage(i);
  };
  const NotifyClick = () => {
    setDisplayNotify(!displayNotify);
  };

  const handelActionStateChange = (type, item, id) => {
    if (type == "complete") {
      window.open(item.downloadLink, "_blank", "noopener,noreferrer");
    } else if (type == "overdue") {
      setTempStateForLoading(id);
      courseOverdueUserNotificationCallable({
        courseName: item.courseName,
        courseId: item.courseId,
        dueDate: item.dueDate,
        uid: item.userId,
      }).then((res) => {
        setTempStateForLoading("test");
        alert("reminder done");
      });
    }
  };

  return (
    <div className="section" style={{ backgroundColor: "#e5e5e5" }}>
      {displayNotify && <Notify NotifyClick={NotifyClick} />}
      <Navbar page={t("Enrollments")} />
      <div className="createcourse-main">
        <Sidebar page={"enrollments"} hidepage={true} />
        <div className="admin-overdue">
          <div className="admin-overdue-top">
            <div className="admin-overdue-top-item">
              {t("View Group Enrollment")}
            </div>
          </div>

          <div className="admin-overdue-mid" style={{}}>
            <div className="admin-overdue-mid-left">
              <div className="admin-overdue-mid-left-txt">{t("Status")}</div>
              <div className="admin-overdue-mid-left-input">
                <select
                  name="groupStatus"
                  id="group"
                  onChange={(e) => {
                    callApiForGetCourse(e, "status");
                  }}
                >
                  <option value="" selected disabled hidden>
                    Select a status
                  </option>
                  <option value="all">All</option>
                  <option value="overdue">Overdue</option>
                  <option value="complete">Complete</option>
                  <option value="failed">Failed</option>
                  <option value="expired">Expired</option>
                  <option value="renew">Renew</option>
                </select>
              </div>
            </div>

            <div className="admin-overdue-mid-right">
              {/* <div className="admin-overdue-mid-left-txt">{t("Date")}</div> */}
              <div className="admin-overdue-mid-left-input-right">
                {/* <input type="date" name="dueDate"  onChange={handleDateChange} /> */}
                <div
                  className="admin-dashboard-main-mid-item-last-filter"
                  style={{ marginTop: "5vh" }}
                >
                  {" "}
                  <div className="admin-dashboard-main-top-last-filter">
                    <button
                      style={{
                        background: `${
                          clrs.CourseListBtn
                            ? clrs.CourseListBtn
                            : "hwb(224deg 78% 3%)"
                        }`,
                        width: "8vw",
                      }}
                      onClick={() => {
                        selectedDateTohumanDate("clear");
                      }}
                    >
                      <div>
                        <svg
                          width="auto"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M14.5411 0.306653C14.4915 0.270984 14.4322 0.25 14.3711 0.25H1.96654C1.3371 0.25 0.481658 0.546574 0.872148 1.04025C1.11132 1.34261 1.3505 1.64497 1.58968 1.94732C2.90969 3.61597 4.22969 5.28461 5.54797 6.95471C5.60263 7.02401 5.65578 7.0522 5.74312 7.05203C7.07158 7.04905 8.40008 7.04905 9.72854 7.05206C9.81602 7.05226 9.86925 7.02387 9.92375 6.95477C10.8668 5.75927 11.8117 4.56525 12.7565 3.37123C12.9509 3.12564 13.1452 2.88004 13.3396 2.63444C13.4306 2.51932 13.5217 2.40421 13.6128 2.28911C13.9566 1.85476 14.3003 1.42041 14.6435 0.985631C14.7914 0.798352 14.8165 0.605055 14.6948 0.442077C14.6546 0.388249 14.5979 0.347451 14.5411 0.306653ZM9.39023 8.05537C9.39023 7.9714 9.32216 7.90332 9.23818 7.90332H6.21498C6.14052 7.90332 6.08016 7.96368 6.08016 8.03814C6.08016 8.56841 6.08014 9.09867 6.08012 9.62894C6.08006 11.1763 6.08 12.7236 6.08047 14.2709C6.08058 14.675 6.38111 14.8623 6.7375 14.6802C7.02361 14.534 7.30944 14.3872 7.59527 14.2404C8.09356 13.9845 8.59185 13.7286 9.09161 13.4758C9.30188 13.3694 9.39333 13.2126 9.39264 12.9707C9.38915 11.7332 9.38956 10.4956 9.38997 9.25803C9.3901 8.85715 9.39023 8.45626 9.39023 8.05537Z"
                            fill="white"
                          />
                        </svg>
                      </div>
                      <div>{t("Clear")}</div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="admin-overdue-bottom">
            <div className="admin-overdue-bottom-table">
              <div
                className="admin-overdue-bottom-table-cnt"
                style={{
                  gridTemplateColumns:
                    " 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr",
                }}
              >
                {/*     <div style={{width:"1vw"}} className="admin-overdue-bottom-table-head"></div> */}
                <div className="admin-overdue-bottom-table-head">
                  {" "}
                  {t("SrNo")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("Name")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("employeeId")}
                </div>

                <div className="admin-overdue-bottom-table-head">
                  {t("CourseName")}
                </div>

                <div className="admin-overdue-bottom-table-head">
                  {t("GroupId")}
                </div>
                {/* <div className="admin-overdue-bottom-table-head">
                  {t("Taning Provider")}
                </div> */}

                <div className="admin-overdue-bottom-table-head">
                  {t("Start Date")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("Completion Date")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("Course Expiry")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("Status")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("Action")}
                </div>
                {/* <div className="admin-overdue-bottom-table-head">{t("Action")}</div> */}
              </div>
              {showLoading && (
                <div className="admin-overdue-bottom-table-cnt-certi" key={0}>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td">
                    <div className="spinner-container">
                      <div className="loading-spinner"></div>
                    </div>
                  </div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div
                    className="admin-overdue-bottom-table-td"
                    style={{ color: "#004577" }}
                  ></div>
                </div>
              )}
              {details?.map((item, id) => {
                if (id < pageSize * currentPage) return;
                if (id >= pageSize * (currentPage + 1)) return;

                let expiry = item.expiry
                  ? new Date(item.expiry?._seconds * 1000)
                      .toISOString()
                      .split("T")[0]
                  : "No Date";
                let issuedOn = item.issuedOn
                  ? new Date(item.issuedOn?._seconds * 1000)
                      .toISOString()
                      .split("T")[0]
                  : "No Date";
                let startDate = item.startDate
                  ? new Date(item.startDate?._seconds * 1000)
                      .toISOString()
                      .split("T")[0]
                  : "No Date";

                return (
                  <div
                    className="admin-overdue-bottom-table-cnt"
                    key={id}
                    style={{
                      gridTemplateColumns:
                        "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr",
                    }}
                  >
                    <div className="admin-overdue-bottom-table-td">
                      {id + 1}
                    </div>
                    <div className="admin-overdue-bottom-table-td">
                      {item.name}
                    </div>
                    <div className="admin-overdue-bottom-table-td">
                      {item.employeeId}
                    </div>
                    <div className="admin-overdue-bottom-table-td">
                      {stateDataForGroupName.state.courseName}
                    </div>

                    <div className="admin-overdue-bottom-table-td">
                      {groupNameFromState}
                    </div>

                    <div className="admin-overdue-bottom-table-td">
                      {startDate}
                    </div>

                    <div className="admin-overdue-bottom-table-td">
                      {issuedOn}
                    </div>
                    <div className="admin-overdue-bottom-table-td">
                      {expiry}
                    </div>
                    <div className="admin-overdue-bottom-table-td">
                      {item.status}
                    </div>
                    <div className="admin-overdue-bottom-table-td">
                      <select
                        style={{
                          border: "none",
                          backgroundColor: "#F2F2F2",
                          height: "4vh",
                          width: "7vw",
                          padding: "0 1vh",
                          fontWeight: "700",
                          color: "#717579",
                          borderRadius: "6px",
                        }}
                        onChange={(e) =>
                          handelActionStateChange(e.target.value, item, id)
                        }
                      >
                        <option>action</option>
                        {item.status == "complete" && (
                          <option value={item.status}>download</option>
                        )}
                        {item.status == "overdue" ? (
                          tempstateForLoading == id ? (
                            <p>Loading...</p>
                          ) : (
                            <option value={item.status}>sendReminder</option>
                          )
                        ) : (
                          ""
                        )}
                      </select>
                    </div>
                    {/* <div
                      className="admin-overdue-bottom-table-td"
                      style={{ color: "#004577" }}
                      onClick={NotifyClick}
                    >
                      {item.Action} */}
                    {/* </div> */}
                  </div>
                );
              })}
            </div>

            <div className="admin-overdue-bottom-pagination">
              <div className="admin-overdue-bottom-pagination-cnt">
                <div className="admin-overdue-bottom-pagination-cnt-item">
                  <svg
                    onClick={() => clickhandler(currentPage - 1)}
                    width="auto"
                    height="22"
                    viewBox="0 0 14 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.0293 10.4584L12.1855 0.837334C12.6016 0.479912 13.2109 0.802178 13.2109 1.37933V20.6215C13.2109 21.1987 12.6016 21.5209 12.1855 21.1635L1.0293 11.5424C0.709961 11.267 0.709961 10.7338 1.0293 10.4584Z"
                      fill="#717579"
                    />
                  </svg>
                </div>
                <div className="admin-overdue-bottom-pagination-cnt-item-btn">
                  {currentPage + 1} of {Math.ceil(arrLength / pageSize)}
                </div>
                <div
                  style={{ marginRight: "19vw" }}
                  className="admin-overdue-bottom-pagination-cnt-item"
                >
                  <svg
                    onClick={() => clickhandler(currentPage + 1)}
                    width="auto"
                    height="20"
                    viewBox="0 0 13 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.1816 9.22727L1.02539 0.141497C0.609375 -0.196038 0 0.108297 0 0.653332V18.8249C0 19.3699 0.609375 19.6742 1.02539 19.3367L12.1816 10.2509C12.501 9.99087 12.501 9.48733 12.1816 9.22727Z"
                      fill="#717579"
                    />
                  </svg>
                </div>
                <div
                  style={{ display: "flex", marginRight: "-30vw" }}
                  className="admin-overdue-bottom-pagination-cnt-item"
                >
                  <label className="admin-row">{t("Show")}</label>
                  <select
                    style={{
                      width: "4vw",
                      margin: "0.5vw",
                      marginBottom: "1.5vw",
                      height: "1.5vw",
                    }}
                    value={pageSize}
                    onChange={(e) => setPageSize(e.target.value)}
                  >
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={15}>15</option>
                    <option value={20}>20</option>
                  </select>
                  <label className="admin-row"> {t("PagePerItem")}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{ height: "100%", width: "6vw", backgroundColor: "white" }}
        ></div>
        <div className="courselist-main-cnt-back">
          <div className="courselist-main-cnt-back-btn">
            <Link to={"/admin/groupEnrollment"}>
              <button
                style={{
                  background: `${
                    clrs.CourseListBtn
                      ? clrs.CourseListBtn
                      : "hwb(224deg 78% 3%)"
                  }`,
                }}
              >
                {t("Back")}
              </button>{" "}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GroupEnrollmentView;
