import React, { useEffect, useState } from "react";
import { t } from "i18next";
import { Timestamp } from "firebase/firestore";
// import"../../Dashboard/UserPassed.css";
import { auth, db, functions } from "../../../../Firebase";

import "../../Dashboard/OverdueEnrollments.css";
import "../../StyleAdminComponents/User.css";
import { httpsCallable } from "firebase/functions";

import { width } from "@mui/system";
import { IP } from "../../../../config";
import { getCookie } from "../../../../Cookies";
import axios from "axios";
const ListOfAllUsers = ({ userList, setUserList, dataList }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [showLoading, setShowLoading] = useState(false);
  const [togelPage, setTogelPage] = useState(true);

  const [dtatest, setdataTest] = useState([]);
  // const [tempState, setTempState] = useState(userList);

  const fetchDepartment = httpsCallable(functions, "fetchDepartment");
  const fetchCompany = httpsCallable(functions, "fetchCompany");
  const [companyData, setCompanyData] = useState([]);

  const [departmentData, setDepartmentData] = useState([]);
  const [search, setSearch] = useState({
    name: "",
    jobTitle: "",
    department: "",
    company: "",
    role: "",
    employeeId: "",
  });

  const [arrDataForSort, setArrDataForSort] = useState(userList);
  const [companyObj, setCompanyObj] = useState({});
  const [departmentObj, setDepartmentObj] = useState({});

  const getCompanyList = () => {
    let configLesson = {
      method: "get",
      // params: query,
      url: `${IP}/common/company`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      // data: "dataLesson",
    };
    axios(configLesson).then((res) => {
      setCompanyData(res.data);
      res.data.map((el) => {
        setCompanyObj((prev) => ({ ...prev, [el.companyId]: el.companyName }));
      });
    });
  };

  const getDepartmentList = () => {
    let configLesson = {
      method: "get",
      // params: query,
      url: `${IP}/common/department`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      // data: "dataLesson",
    };
    axios(configLesson).then((res) => {
      setDepartmentData(res.data);
      res.data.map((el) => {
        setDepartmentObj((prev) => ({ ...prev, [el.departmentId]: el.name }));
      });
    });
  };
  useEffect(() => {
    getDepartmentList();

    getCompanyList();
  }, []);

  useEffect(() => {
    if (userList) {
      setShowLoading(false);
    }
  }, []);
  useEffect(() => {
    // setTempState(userList);
    setArrDataForSort(userList);
  }, [userList]);
  // console.log(userList);
  const arrLength = userList ? userList : 0;
  const clickhandler = (i) => {
    if (i < 0) return;
    if (i + 1 > Math.ceil(arrLength / 5)) return;
    //TODO: limit upper
    setCurrentPage(i);
  };
  const handleCheck = (e, id) => {
    // //console.log(id)
    setUserList((prev) => {
      let newArr = Array.from(prev);
      newArr[id].checked = e.target.checked;
      // //console.log(newArr[id]);
      return newArr;
    });
  };
  // //console.log(userList)
  const handleSearch = (type) => {
    let ss = dataList;
    if (type == "name") {
      ss = dataList.filter((ele) =>
        ele.name.toLowerCase().includes(search.name.toLowerCase())
      );
    } else if (type == "jobTitle") {
      // console.log(search.jobTitle);
      ss = dataList.filter((ele) =>
        ele?.jobTitle?.toLowerCase().includes(search.jobTitle.toLowerCase())
      );
    } else if (type == "department") {
      ss = dataList.filter((ele) =>
        ele.departmentId.includes(search.department)
      );
    } else if (type == "company") {
      ss = dataList.filter((ele) => ele.companyId.includes(search.company));
    } else if (type == "role") {
      ss = dataList.filter((ele) =>
        ele.role.toLowerCase().includes(search.role.toLowerCase())
      );
    } else if (type == "employeeId") {
      ss = dataList.filter((ele) =>
        ele.employeeId.toLowerCase().includes(search.employeeId.toLowerCase())
      );
    }
    setCurrentPage(0);
    setUserList(ss);
  };
  const clearSearch = () => {
    console.log(dataList.sort((a, b) => b.nu - a.nu));
    setUserList([]);

    setUserList(dataList.sort((a, b) => a.nu - b.nu));
    setTogelPage(!togelPage);

    // setEmployeeNo("");
    setCurrentPage(0);

    setSearch({
      name: "",
      jobTitle: "",
      department: "",
      company: "",
      role: "",
      employeeId: "",
    });
  };

  const HandelSort = (e) => {
    setdataTest(dataList);

    if (e == 0) {
      setShowLoading(true);
      let newara = arrDataForSort.sort(
        (a, b) => a.joiningDate._seconds - b.joiningDate._seconds
      );
      ////console.log(newara)
      setTogelPage(!togelPage);
      setUserList(newara);
      setShowLoading(false);
    } else if (e == 1) {
      setShowLoading(true);
      let newara = arrDataForSort.sort(
        (a, b) => b.joiningDate._seconds - a.joiningDate._seconds
      );
      // //console.log(newara)
      setTogelPage(!togelPage);
      setUserList(newara);

      setShowLoading(false);
    } else if (e == 2) {
      function compare(a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      }
      let newara = arrDataForSort.sort(compare);
      // //console.log(newara)
      setTogelPage(!togelPage);
      setUserList(newara);
      setShowLoading(false);
    }
  };
  return (
    <div style={{ margin: "0vw 2vw 2vw 2vw" }} className="admin-overdue-bottom">
      <div className="admin-overdue">
        <div
          style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr" }}
          className="admin-dashboard-main-mid"
        >
          <div className="admin-dashboard-main-mid-item">
            <div className="admin-dashboard-main-mid-item-txt">
              {t("UserName")}
            </div>
            <div style={{ position: "relative" }}>
              <input
                style={{ width: "11vw" }}
                value={search.name}
                name="name"
                type="text"
                onChange={(e) => {
                  setSearch({
                    ...search,
                    [e.target.name]: e.target.value,
                  });
                }}
                placeholder={t("Enter Username")}
              />
              <div
                className="admin-dashboard-main-top-last"
                style={{
                  position: "absolute",
                  top: "-0.5vw",
                  right: "1.15vw",
                  display: "flex",
                }}
              >
                <button
                  style={{
                    background: "rgb(36,24,135)",
                    height: "4.1vh",
                    borderRadius: "0px 6px 6px 0px",
                    marginRight: "9vw",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  onClick={() => handleSearch("name")}
                >
                  <div style={{ textAlign: "center" }}>{t("Search")}</div>
                </button>
              </div>{" "}
            </div>
          </div>
          <div className="admin-dashboard-main-mid-item">
            <div className="admin-dashboard-main-mid-item-txt">
              {t("Job Title")}
            </div>
            <div style={{ position: "relative" }}>
              <input
                style={{ width: "11vw" }}
                value={search.jobTitle}
                name="jobTitle"
                type="text"
                onChange={(e) => {
                  setSearch({
                    ...search,
                    [e.target.name]: e.target.value,
                  });
                }}
                placeholder={t("Enter Username")}
              />
              <div
                className="admin-dashboard-main-top-last"
                style={{
                  position: "absolute",
                  top: "-0.5vw",
                  right: "1.15vw",
                  display: "flex",
                }}
              >
                <button
                  style={{
                    background: "rgb(36,24,135)",
                    height: "4.1vh",
                    borderRadius: "0px 6px 6px 0px",
                    marginRight: "9vw",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  onClick={() => handleSearch("jobTitle")}
                >
                  <div style={{ textAlign: "center" }}>{t("Search")}</div>
                </button>
              </div>
            </div>
          </div>
          <div className="admin-dashboard-main-mid-item">
            <div className="admin-dashboard-main-mid-item-txt">
              {t("Department")}
            </div>
            <div style={{ position: "relative" }}>
              {/* <input
                style={{ width: "11vw" }}
                value={search.department}
                name="department"
                type="text"
                onChange={(e) => {
                  setSearch({
                    ...search, [e.target.name]: e.target.value
                  });
                }}
                placeholder={t("Enter Username")}
              /> */}

              <select
                style={{ width: "11vw" }}
                value={search.department}
                name="department"
                type="text"
                onChange={(e) => {
                  setSearch({
                    ...search,
                    [e.target.name]: e.target.value,
                  });
                }}
              >
                <option value={""} disabled>
                  select
                </option>

                {departmentData.map((e) => (
                  <option value={e.departmentId}>{e.name}</option>
                ))}
              </select>

              <div
                className="admin-dashboard-main-top-last"
                style={{
                  position: "absolute",
                  top: "-0.5vw",
                  right: "1.15vw",
                  display: "flex",
                }}
              >
                <button
                  style={{
                    background: "rgb(36,24,135)",
                    height: "4.1vh",
                    borderRadius: "0px 6px 6px 0px",
                    marginRight: "9vw",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  onClick={() => handleSearch("department")}
                >
                  <div style={{ textAlign: "center" }}>{t("Search")}</div>
                </button>
              </div>
            </div>
          </div>
          <div className="admin-dashboard-main-mid-item">
            <div className="admin-dashboard-main-mid-item-txt">
              {t("Company")}
            </div>
            <div style={{ position: "relative" }}>
              <select
                style={{ width: "11vw" }}
                value={search.company}
                name="company"
                type="text"
                onChange={(e) => {
                  setSearch({
                    ...search,
                    [e.target.name]: e.target.value,
                  });
                }}
              >
                <option value={""} disabled>
                  select
                </option>

                {companyData.map((e) => (
                  <option value={e.companyId}>{e.companyName}</option>
                ))}
              </select>

              <div
                className="admin-dashboard-main-top-last"
                style={{
                  position: "absolute",
                  top: "-0.5vw",
                  right: "1.15vw",
                  display: "flex",
                }}
              >
                <button
                  style={{
                    background: "rgb(36,24,135)",
                    height: "4.1vh",
                    borderRadius: "0px 6px 6px 0px",
                    marginRight: "9vw",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  onClick={() => handleSearch("company")}
                >
                  <div style={{ textAlign: "center" }}>{t("Search")}</div>
                </button>
              </div>
            </div>
          </div>
          <div className="admin-dashboard-main-mid-item">
            <div className="admin-dashboard-main-mid-item-txt">{t("Role")}</div>
            <div style={{ position: "relative" }}>
              {/* <input
                style={{ width: "11vw" }}
                value={search.role}
                name="role"
                type="text"
                onChange={(e) => {
                  setSearch({
                    ...search, [e.target.name]: e.target.value
                  });
                }}
                placeholder={t("Enter Username")}
              /> */}
              <select
                style={{ width: "11vw" }}
                value={search.role}
                name="role"
                onChange={(e) => {
                  setSearch({
                    ...search,
                    [e.target.name]: e.target.value,
                  });
                }}
              >
                <option value={""} disabled>
                  select
                </option>
                <option value={"admin"}>admin</option>
                <option value={"user"}>user</option>
                <option value={"instructor"}>instructor</option>
              </select>
              <div
                className="admin-dashboard-main-top-last"
                style={{
                  position: "absolute",
                  top: "-0.5vw",
                  right: "1.15vw",
                  display: "flex",
                }}
              >
                <button
                  style={{
                    background: "rgb(36,24,135)",
                    height: "4.1vh",
                    borderRadius: "0px 6px 6px 0px",
                    marginRight: "9vw",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  onClick={() => handleSearch("role")}
                >
                  <div style={{ textAlign: "center" }}>{t("Search")}</div>
                </button>
              </div>
            </div>
          </div>
          <div className="admin-dashboard-main-mid-item">
            <div className="admin-dashboard-main-mid-item-txt">
              {t("EmployeeNo")}
            </div>
            <div style={{ position: "relative" }}>
              <input
                style={{ width: "11vw" }}
                value={search.employeeId}
                name="employeeId"
                type="text"
                onChange={(e) => {
                  setSearch({
                    ...search,
                    [e.target.name]: e.target.value,
                  });
                }}
                placeholder={t("Employee No")}
              />
              <div
                className="admin-dashboard-main-top-last"
                style={{
                  position: "absolute",
                  top: "-0.5vw",
                  right: "1.15vw",
                  display: "flex",
                }}
              >
                <button
                  style={{
                    background: "rgb(36,24,135)",
                    height: "4.1vh",
                    borderRadius: "0px 6px 6px 0px",
                    marginRight: "0vw",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  onClick={() => handleSearch("employeeId")}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {t("Search")}
                  </div>
                </button>
                <button
                  style={{
                    background: "rgb(36,24,135)",
                    height: "4.1vh",
                    borderRadius: "6px 6px 6px 6px",
                    marginLeft: "4%",
                    width: "10vw",
                  }}
                  onClick={() => clearSearch()}
                >
                  <div
                    style={{
                      marginRight: "0vw",
                      textAlign: "center",
                      width: "10vw",
                    }}
                  >
                    {t("Clear Filter")}
                  </div>
                </button>
              </div>
            </div>
          </div>

          <div className="admin-dashboard-main-mid-item">
            <div className="admin-dashboard-main-mid-item-txt">{t("Sort")}</div>
            <div style={{ position: "relative" }}>
              <select
                onChange={(e) => {
                  HandelSort(e.target.value);
                }}
              >
                <option value={1} selected>
                  CreatedDateDesc
                </option>
                <option value={0}>CreatedDateAsc</option>
                <option value={2}>NameInAsc</option>
              </select>
              <div
                className="admin-dashboard-main-top-last"
                style={{
                  position: "absolute",
                  top: "-0.5vw",
                  right: "3vw",
                  display: "flex",
                }}
              >
                {" "}
                <button
                  style={{
                    background: "rgb(36,24,135)",
                    height: "4.1vh",
                    borderRadius: "6px 6px 6px 6px",
                    marginLeft: "4%",
                    width: "10vw",
                  }}
                  onClick={() => clearSearch()}
                >
                  <div
                    style={{
                      marginRight: "0vw",
                      textAlign: "center",
                      width: "10vw",
                    }}
                  >
                    {t("Clear Sort")}
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>{" "}
      </div>
      <div className="admin-overdue-bottom-table">
        <div
          style={{ gridTemplateColumns: "repeat(8, 1fr)" }}
          className="admin-overdue-bottom-table-cnt-grp"
        >
          <div className="admin-overdue-bottom-table-head">
            {t("EmployeeNo")}
          </div>
          <div className="admin-overdue-bottom-table-head">{t("Name")}</div>
          <div className="admin-overdue-bottom-table-head">{t("JobTitle")}</div>
          <div className="admin-overdue-bottom-table-head">
            {t("Department")}
          </div>
          <div className="admin-overdue-bottom-table-head">{t("Company")}</div>
          <div className="admin-overdue-bottom-table-head">
            {t("JoiningDate")}
          </div>
          <div className="admin-overdue-bottom-table-head">{t("Role")}</div>
          <div className="admin-overdue-bottom-table-head">{t("EmailID")}</div>
        </div>
        {showLoading && (
          <div className="admin-overdue-bottom-table-cnt-certi" key={0}>
            <div className="admin-overdue-bottom-table-td"></div>
            <div className="admin-overdue-bottom-table-td"></div>
            <div className="admin-overdue-bottom-table-td"></div>
            <div className="admin-overdue-bottom-table-td"></div>
            <div className="admin-overdue-bottom-table-td">
              <div className="spinner-container">
                <div className="loading-spinner"></div>
              </div>
            </div>
            <div className="admin-overdue-bottom-table-td"></div>
            <div className="admin-overdue-bottom-table-td"></div>
            <div className="admin-overdue-bottom-table-td"></div>
            <div
              className="admin-overdue-bottom-table-td"
              style={{ color: "#004577" }}
            ></div>
          </div>
        )}
        {userList?.map((item, id) => {
          if (id < 5 * currentPage) return;
          if (id >= 5 * (currentPage + 1)) return;
          let joiningDate = Timestamp.fromMillis(
            item?.joiningDate?._seconds * 1000
          )
            .toDate()
            .toDateString();
          return (
            <div className="admin-overdue-bottom-table-cnt-grped" key={id}>
              <div className="admin-overdue-bottom-table-td">
                <input
                  style={{
                    height: "1vw",
                    width: "2vw",
                    marginRight: "2vw",
                    marginLeft: "-3vw",
                  }}
                  onChange={(e) => handleCheck(e, id)}
                  checked={item.checked}
                  type="checkbox"
                />
                {item.employeeId}
              </div>
              <div className="admin-overdue-bottom-table-td">{item.name}</div>
              <div className="admin-overdue-bottom-table-td">
                {item.jobTitle}
              </div>
              <div className="admin-overdue-bottom-table-td">
                {departmentObj[item.departmentId]}
              </div>
              <div className="admin-overdue-bottom-table-td">
                {companyObj[item.companyId]}
              </div>
              <div className="admin-overdue-bottom-table-td">{joiningDate}</div>
              <div className="admin-overdue-bottom-table-td">{item.role}</div>
              <div className="admin-overdue-bottom-table-td">{item.email}</div>
            </div>
          );
        })}
      </div>
      <div className="admin-overdue-bottom-pagination">
        <div className="admin-overdue-bottom-pagination-cnt">
          <div className="admin-overdue-bottom-pagination-cnt-item">
            <svg
              onClick={() => clickhandler(currentPage - 1)}
              width="auto"
              height="22"
              viewBox="0 0 14 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.0293 10.4584L12.1855 0.837334C12.6016 0.479912 13.2109 0.802178 13.2109 1.37933V20.6215C13.2109 21.1987 12.6016 21.5209 12.1855 21.1635L1.0293 11.5424C0.709961 11.267 0.709961 10.7338 1.0293 10.4584Z"
                fill="#717579"
              />
            </svg>
          </div>
          <div className="admin-overdue-bottom-pagination-cnt-item-btn">
            {currentPage + 1} of {Math.ceil(userList ? userList.length / 5 : 0)}
          </div>
          <div className="admin-overdue-bottom-pagination-cnt-item">
            <svg
              onClick={() => clickhandler(currentPage + 1)}
              width="auto"
              height="20"
              viewBox="0 0 13 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.1816 9.22727L1.02539 0.141497C0.609375 -0.196038 0 0.108297 0 0.653332V18.8249C0 19.3699 0.609375 19.6742 1.02539 19.3367L12.1816 10.2509C12.501 9.99087 12.501 9.48733 12.1816 9.22727Z"
                fill="#717579"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListOfAllUsers;
