import React, { useEffect, useRef } from "react";
import { useState } from "react";
// import $ from "jquery";
import { auth, db, functions } from "../../../Firebase";
import { httpsCallable } from "firebase/functions";
import "./Form.css";
import "../StyleAdminComponents/GroupList.css";
import { useNavigate } from "react-router-dom";
import { EditSvg, DeleteSVg } from "./icons";
import { getCookie } from "../../../Cookies";

import Select from "@mui/material/Select";
import { Button, MenuItem } from "@mui/material";
import EnterBodyAndTittel from "./bodyAndTittel";
import { IP } from "../../../config";
import axios from "axios";
import { toast } from "react-toastify";

const Course = ({ editCourseInfo, setIsCourseIdSet, checkEditFlag }) => {
  //Course
  const navigate = useNavigate();

  const [openLessonPopUp, setopenLessonPopUp] = useState(false);
  const createCourse = httpsCallable(functions, "createCourse");
  const addCourseExtraInfo = httpsCallable(functions, "addCourseExtraInfo");
  const getCourses = httpsCallable(functions, "getCourses");

  const courseEditUserNotification = httpsCallable(
    functions,
    "courseEditUserNotification"
  );
  const fetchCertificateTemplate = httpsCallable(
    functions,
    "fetchCertificateTemplate"
  );
  const getAdminAndInstructorList = httpsCallable(
    functions,
    "getAdminAndInstructorList"
  );
  const courseSharedNotification = httpsCallable(
    functions,
    "courseSharedNotification"
  );

  const fetchCompany = httpsCallable(functions, "fetchCompany");
  const [courseName, setCourseName] = useState("");

  const [cirtificateExp, setCirtificateExp] = useState("");
  const [expirationReminderDays, setExpirationReminderDays] = useState(0);
  const [timeInMinute, settimeInMinute] = useState("0");
  const [companyData, setCompanyData] = useState([]);

  const [selectCompany, setSelectCompany] = useState({});

  const [courseDescription, setCourseDescription] = useState("");
  const [courseDuration, setCourseDuration] = useState(0);
  const [trainingProvider, setTrainingProvider] = useState("");
  const [courseExpirationDays, setCourseExpirationDays] = useState(0);
  const [templateArr, setTemplateArr] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [accessMode, setAccessMode] = useState("free");
  const [hasQuiz, setHasQuiz] = useState("yes");
  const [instructorArr, setInstructorArr] = useState(null);
  const [instructorId, setInstructorId] = useState("default");
  const [sharedByArr, setSharedByArr] = useState(null);
  const [courseId, setCourseId] = useState("");
  // const [courseList, setCourseList] = useState(null);
  let newDate = new Date().toDateString();
  const [createdDate, setCreatedDate] = useState(newDate);
  const [lastVisited, setLastVisited] = useState(newDate);
  const [courseShare, setCourseShare] = useState(null);
  const [dependencyArr, setDependencyArr] = useState([]);
  const [dependencyMode, setDependencyMode] = useState("or");
  const [flow, setFlow] = useState("linear");
  // const storage = getStorage();
  const [editable, setEditable] = useState(false);
  const [checkFailed, setCheckFailed] = useState(false);
  const [base64Img, setBase64Img] = useState("");
  const [imgfilename, setImgFileName] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [checkErrorInForm, setCheckErrorInForm] = useState({
    courseName: "",
    courseDescription: "",
    trainingProvider: "",
    courseDuration: "",
    courseExpirationDays: "",
    imageUrl: "",
    instructorId: "",
    selectedTemplate: "",
    cirtificateExp: "",
  });
  const [editdataflagForLoading, setEditdataflagForLoading] = useState(true);
  const imageRef = useRef(null);
  var docURL = [];
  var files = [];
  const [role, setRole] = useState("");

  const handleCourseShare = (event) => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setCourseShare(value);
  };

  const onImageClick = () => {
    imageRef.current.click();
  };
  const formCheck = () => {
    console.log(selectCompany);
    if (courseName === "") {
      setCheckErrorInForm((prev) => ({ ...prev, courseName: "error" }));
      return "Course Name";
    }
    if (courseDescription === "") {
      setCheckErrorInForm((prev) => ({ ...prev, courseDescription: "error" }));
      return "Description";
    }
    if (trainingProvider === "") {
      setCheckErrorInForm((prev) => ({ ...prev, trainingProvider: "error" }));
      return "Traing Provider";
    }

    if (courseDuration === "") {
      setCheckErrorInForm((prev) => ({ ...prev, courseDuration: "error" }));
      return "Duration";
    }
    if (courseExpirationDays === 0) {
      setCheckErrorInForm((prev) => ({
        ...prev,
        courseExpirationDays: "error",
      }));
      return "Expiration Days";
    }
    if (cirtificateExp === "") {
      setCheckErrorInForm((prev) => ({
        ...prev,
        cirtificateExp: "error",
      }));
      return "Expiration Days";
    }

    if (imageUrl === "") {
      setCheckErrorInForm((prev) => ({ ...prev, imageUrl: "error" }));
      alert("Upload image");
      return "base64Img";
    }
    if (role == "admin") {
      if (instructorId == "default") {
        setCheckErrorInForm((prev) => ({ ...prev, instructorId: "error" }));
        return "Instructor";
      }
    }

    if (selectedTemplate == "") {
      setCheckErrorInForm((prev) => ({ ...prev, selectedTemplate: "error" }));
      return "Certificate Template";
    }
    return "allIsWell";
  };

  if (editdataflagForLoading) {
    // //console.log(checkEditFlag)

    document.getElementsByClassName("course-builder-container").display =
      "none";
  }

  useEffect(() => {
    if (!editCourseInfo) return;
    setCourseId(editCourseInfo.courseId);
    setCourseName(editCourseInfo.courseName);
    setCirtificateExp(editCourseInfo?.valiedYears);

    settimeInMinute(editCourseInfo.extraInfo.courseDurationInminute);
    setCourseDescription(editCourseInfo.courseDescription);
    setImageUrl(editCourseInfo.courseImageURL);
    setCourseDuration(editCourseInfo.extraInfo.courseDuration);
    setFlow(editCourseInfo.extraInfo.courseProgress);
    setInstructorId(editCourseInfo.courseInstructor);
    setSelectedTemplate(editCourseInfo.extraInfo.certifiateTemplate);
    setAccessMode(editCourseInfo.extraInfo.accessMode);
    setCourseShare(editCourseInfo.extraInfo.courseShare);
    //courseProgress
    setTrainingProvider(editCourseInfo.extraInfo.trainingProvider);
    setCourseExpirationDays(editCourseInfo.extraInfo.courseExpirationDays);
    setExpirationReminderDays(editCourseInfo.extraInfo.expirationReminderDays);
    setDependencyArr(editCourseInfo.extraInfo.courseDependency);

    console.log(editCourseInfo.extraInfo.companyTemplate);
    setSelectCompany(
      editCourseInfo.extraInfo.companyTemplate
        ? editCourseInfo.extraInfo.companyTemplate
        : {}
    );
    let created = new Date(
      editCourseInfo.created._seconds * 1000
    ).toDateString();
    setCreatedDate(created);
    setEditdataflagForLoading(false);
    //console.log("i am in useEffect", new Date());
    //console.log(editCourseInfo);
  }, [editCourseInfo]);

  useEffect(() => {
    let tRole = getCookie("ROLE");
    setRole(tRole);
    let uid = getCookie("UID");
    if (tRole == "instructor") {
      setInstructorId(uid);
    }
    // fetchCertificateTemplate().then((res) => {});

    let configCertificate = {
      method: "get",
      // params: { userId: uid },
      url: `${IP}/certificate`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataexp",
    };
    axios(configCertificate).then((res) => {
      setTemplateArr(res.data);
    });

    let configcompany = {
      method: "get",
      // params: { userId: uid },
      url: `${IP}/common/company`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataexp",
    };
    axios(configcompany).then((res) => {
      setCompanyData(res.data);
    });
    // getCourses().then((result) => {
    //   setCourseList([...result.data].map((ele) => ({ ...ele, select: false })));
    // });

    let configadminAndInstructor = {
      method: "get",
      // params: { userId: uid },
      url: `${IP}/userProfile/adminAndInstructor`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataexp",
    };
    axios(configadminAndInstructor).then((res) => {
      setInstructorArr(res.data.instructor_list);
      setSharedByArr([...res.data.instructor_list, ...res.data.admin_list]);
    });
    // getAdminAndInstructorList().then((res) => {
    //   setInstructorArr(res.data.instructor_list);
    //   setSharedByArr([...res.data.instructor_list, ...res.data.admin_list]);
    //   // let shared = res.data.admin_list;
    //   // let instructorList = [];
    //   // res.data.instructor_list;
    //   // instructorList.forEach(ele=>)
    // });
  }, []);

  const handleCourseImage = (e) => {
    let reader = new FileReader();
    //console.log(e);

    let filenameType = e.target.value.split(".");

    //console.log(filenameType[filenameType.length - 1]);

    let filetypedata = filenameType[filenameType.length - 1].toLowerCase();
    if (
      filetypedata == "png" ||
      filetypedata == "jpeg" ||
      filetypedata == "jpg"
    ) {
    } else {
      alert("only jpeg  , gif  ,png  file accepted");
      e.target.value = null;

      return;
    }

    let rfilesize = Number(e.target.files[0].size) / 1024;

    rfilesize = rfilesize / 1024;
    //console.log(rfilesize);
    if (Number(rfilesize) > 5) {
      alert("size must be less than 5 mb");
      setImgFileName("");
      e.target.value = null;
      return;
    } else {
      //console.log(rfilesize);
      let url = URL.createObjectURL(e.target.files[0]);
      setImageUrl(url);
      setImgFileName(e.target.files[0].name);
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = function () {
        //console.log(reader.result);
        setBase64Img(reader.result);
      };
    }
  };

  const addCourse = () => {
    console.log(instructorId);
    const check = formCheck();

    let nsn = courseName.replaceAll(" ", "");

    let dat = /\//i.test(nsn);

    if (!dat) {
    } else {
      alert("you can not use (/) characters");
      return;
    }
    if (check != "allIsWell") {
      setCheckFailed(true);
      return;
    }

    if (Number(expirationReminderDays) >= courseExpirationDays) {
      alert(
        "Set reminder before access expiry is greater then course Expiration Days"
      );
      return;
    }
    setCheckErrorInForm({
      courseName: "",
      courseDescription: "",
      trainingProvider: "",
      courseDuration: "",
      courseExpirationDays: "",
      imageUrl: "",
      instructorId: "",
      selectedTemplate: "",
      cirtificateExp: "",
    });
    setCheckFailed(false); // removes error message after succesful creation
    let dependency = [];
    // for (var option of document.getElementById("courseDependency").options) {
    //   if (option.selected) {
    //     dependency.push(option.value);
    //   }
    // }
    let shared = [];
    for (var option of document.getElementById("courseShare").options) {
      if (option.selected) {
        //console.log(option.value);
        shared.push(option.value);
      }
    }
    if (shared.length > 0) {
      if (!editCourseInfo) {
        let configcreateCourse = {
          method: "post",
          // params: { userId: uid },
          url: `${IP}/mail/sendMailNewCourseCreated`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("bearer")}`,
          },
          data: JSON.stringify({
            courseId: courseId,
            courseName: courseName,
            userArr: shared,
          }),
        };
        axios(configcreateCourse)
          .then((res) => {})
          .catch((err) => {});
      }
      // courseSharedNotification({
      //   courseId: courseId,
      //   course: courseName,
      //   users: shared,
      // }).then((res) => {
      //   //console.log("notification sent");
      // });
    }

    const dataCourse = JSON.stringify({
      courseId: courseId,
      courseDescription: courseDescription,
      image: base64Img ? base64Img : imageUrl,
      courseInstructor: role == "admin" ? instructorId : instructorId,
      courseName: courseName,
      created: createdDate, // date
      valiedYears: cirtificateExp,
    });
    let configcreateCourse = {
      method: "post",
      // params: { userId: uid },
      url: `${IP}/courseBuilder/course`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataCourse,
    };
    axios(configcreateCourse)
      .then((res) => {
        //console.log(res);

        const dataCourse = JSON.stringify({
          courseDuration: courseDuration,
          courseDurationInminute: timeInMinute,
          trainingProvider: trainingProvider,
          courseExpirationDays: courseExpirationDays,
          accessMode: accessMode,
          hasQuiz: hasQuiz,

          expirationReminderDays: Number(expirationReminderDays),
          courseDependency: dependency,
          courseComposeMode: dependencyMode,
          certifiateTemplate: selectedTemplate,
          companyTemplate: selectCompany,
          courseId: courseId,
          courseProgress: flow,
          courseShare: shared,
        });
        let configcreateCourse = {
          method: "patch",
          // params: { userId: uid },
          url: `${IP}/courseBuilder/course`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("bearer")}`,
          },
          data: dataCourse,
        };
        axios(configcreateCourse)
          .then((ress) => {
            sessionStorage.setItem("courseId", courseId);

            if (editCourseInfo) {
              alert("Course " + courseName + " updated");
            } else {
              alert("Course " + courseName + " added");
            }
            document.getElementById("courseName").disabled = true; // disable the input field
            const elements =
              document.getElementsByClassName("course-builder-top");
            for (let i = 0; i < elements.length; i++) {
              elements[i].style.display = "none";
            }
            document.getElementById("addC").style.display = "none";
            document.getElementById("editC").style.display = "";
            localStorage.setItem("courseId", courseId);

            setIsCourseIdSet(courseId);
          })
          .catch((er) => {
            toast.error("something went wrong");
          });
      })
      .catch((er) => {
        toast.error("something went wrong");
      });
  };
  function edit() {
    document.getElementById("addC").style.display = "";
    const elements = document.getElementsByClassName("course-builder-top");
    for (let i = 0; i < elements.length; i++) {
      elements[i].style.display = "";
    }
  }
  //change this and do it the React Way
  const delfunction = (data) => {
    let configcreateCourse = {
      method: "post",
      // params: { userId: uid },
      url: `${IP}/mail/sendMailEditCourseNotification`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        courseId: courseId,
        coursename: courseName,
        body: data.body,
        trigger: `Your course ${courseName} is Modified`,
      }),
    };
    axios(configcreateCourse);

    // console.log(data);
  };
  return (
    <>
      {" "}
      {!checkEditFlag ? (
        <div className="course-builder-container">
          <EnterBodyAndTittel
            open={openLessonPopUp}
            onClose={() => setopenLessonPopUp(false)}
            delfun={(topic) => {
              delfunction(topic);
            }}
          />
          <h2 className="course-builder-heading">Internal Training LMS</h2>
          <div
            id="editC"
            style={{ display: "none" }}
            className="course-builder-top-info"
          >
            <table className="course-builder-top-table">
              <thead className="course-builder-top-thead">
                <tr>
                  <th>Course Name</th>
                  <th>Created Date</th>
                  <th>Last Updated</th>
                  <th>Training Provider</th>
                  <th>Action</th>
                  <th>Send Notification</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{courseName}</td>
                  <td>{createdDate}</td>
                  <td>{lastVisited}</td>
                  <td>{trainingProvider}</td>
                  <td>
                    <div style={{ display: "flex" }}>
                      <div
                        onClick={() => {
                          setEditable(true);
                          edit();
                        }}
                      >
                        <EditSvg />
                      </div>
                    </div>
                  </td>
                  <td>
                    <button
                      className="course-builder-button"
                      onClick={() => {
                        setopenLessonPopUp(true);
                      }}
                    >
                      {" "}
                      Notify
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="course-builder-top">
            <div className="course-builder-info">
              <label htmlFor="courseName" className="course-builder-inputLable">
                Course Title *
              </label>
              <input
                type="text"
                className="course-builder-inputBar"
                id="courseName"
                value={courseName}
                style={{
                  border: `${
                    checkErrorInForm.courseName == "error"
                      ? "2px solid red"
                      : "1px solid #d7d7d7"
                  }`,
                }}
                onChange={(e) => {
                  setCourseName(e.target.value);
                  let value = e.target.value.replaceAll(" ", "");
                  let time = new Date().getTime();
                  setCheckErrorInForm((prev) => ({ ...prev, courseName: "" }));
                  if (!editCourseInfo) {
                    setCourseId(value + "_" + time);
                  }
                }}
              />
              {checkErrorInForm.courseName == "error" && (
                <span
                  style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
                >
                  Enter Course Title
                </span>
              )}
            </div>
            <div className="course-builder-info">
              <p className="course-builder-inputLable">Featured Image *</p>
              {imageUrl == "" ? (
                <div
                  style={{
                    width: "20vw",
                    height: "8em",
                    background: "#d5d5d5",
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    borderRadius: "10px",
                  }}
                  onClick={onImageClick}
                >
                  Click to upload Image
                </div>
              ) : (
                <img
                  style={{
                    width: "20vw",
                    height: "8em",
                    objectFit: "cover",
                    borderRadius: "10px",
                  }}
                  onClick={onImageClick}
                  src={imageUrl}
                ></img>
              )}
              <input
                ref={imageRef}
                style={{ display: "none" }}
                type="file"
                accept="image/png, image/gif, image/jpeg"
                className="course-builder-inputImg"
                id="courseImage"
                onChange={(e) => {
                  handleCourseImage(e);
                  setCheckErrorInForm((prev) => ({ ...prev, imageUrl: "" }));
                }}
              />
              {checkErrorInForm.imageUrl == "error" && (
                <span
                  style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
                >
                  {" "}
                  upload Image
                </span>
              )}
            </div>
            <div className="course-builder-info">
              <p className="course-builder-inputLable">Short description*</p>
              <textarea
                type="text"
                className="course-builder-textarea"
                value={courseDescription}
                style={{
                  border: `${
                    checkErrorInForm.courseDescription == "error"
                      ? "2px solid red"
                      : "1px solid #d7d7d7"
                  }`,
                }}
                id="courseDescription"
                onChange={(event) => {
                  setCourseDescription(event.target.value);
                  setCheckErrorInForm((prev) => ({
                    ...prev,
                    courseDescription: "",
                  }));
                }}
              />
              {checkErrorInForm.courseDescription == "error" && (
                <span
                  style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
                >
                  Enter Course Description
                </span>
              )}
            </div>
            <div className="course-builder-info">
              <p className="course-builder-inputLable">Course Duration *</p>

              <div style={{ width: "35%" }}>
                {" "}
                Hour{" "}
                <input
                  type="number"
                  className="course-builder-inputBar"
                  value={courseDuration}
                  style={{
                    border: `${
                      checkErrorInForm.courseDuration == "error"
                        ? "2px solid red"
                        : "1px solid #d7d7d7"
                    }`,
                    width: "22%",
                  }}
                  id="courseDuration"
                  min={0}
                  onChange={(event) => {
                    setCourseDuration(event.target.value);
                    setCheckErrorInForm((prev) => ({
                      ...prev,
                      courseDuration: "",
                    }));
                  }}
                />
                Minute
                <input
                  type="number"
                  className="course-builder-inputBar"
                  value={timeInMinute}
                  style={{
                    width: "22%",
                    border: `${
                      checkErrorInForm.courseDuration == "error"
                        ? "2px solid red"
                        : "1px solid #d7d7d7"
                    }`,
                  }}
                  id="courseDuration"
                  min={0}
                  onChange={(event) => {
                    settimeInMinute(event.target.value);
                    setCheckErrorInForm((prev) => ({
                      ...prev,
                      courseDuration: "",
                    }));
                  }}
                />
              </div>

              {checkErrorInForm.courseDuration === "error" && (
                <span
                  style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
                >
                  Enter Course Duration
                </span>
              )}
            </div>
            <div className="course-builder-info">
              <p className="course-builder-inputLable">Training Provider*</p>
              <input
                type="text"
                className="course-builder-inputBar"
                value={trainingProvider}
                style={{
                  border: `${
                    checkErrorInForm.trainingProvider == "error"
                      ? "2px solid red"
                      : "1px solid #d7d7d7"
                  }`,
                }}
                id="trainingProvider"
                onChange={(event) => {
                  setTrainingProvider(event.target.value);
                  setCheckErrorInForm((prev) => ({
                    ...prev,
                    trainingProvider: "",
                  }));
                }}
              />
              {checkErrorInForm.trainingProvider == "error" && (
                <span
                  style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
                >
                  Enter Training Provider Name
                </span>
              )}
            </div>
            <div className="course-builder-info">
              <div
                className="course-builder-inputLable"
                style={{ marginTop: "2vw" }}
              >
                Access Mode
              </div>
              <select
                value={accessMode}
                id="accessMode"
                className="course-builder-inputSelect"
                onChange={(e) => setAccessMode(e.target.value)}
              >
                <option value="free">Free</option>
                <option value="closed">Closed</option>
              </select>
            </div>
            {/* <div className="course-builder-info">
            <div className="course-builder-inputLable" style={{ marginTop: "2vw" }}>
              Quiz
            </div>
            <select
              value={hasQuiz}
              onChange={(e) => setHasQuiz(e.target.value)}
              id="hasQuiz"
              className="course-builder-inputSelect"
            >
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div> */}

            <div className="course-builder-info">
              <div
                className="course-builder-inputLable"
                style={{ marginTop: "2vw" }}
              >
                Certificate Expiration
              </div>
              <select
                value={cirtificateExp}
                style={{
                  border: `${
                    checkErrorInForm.cirtificateExp == "error"
                      ? "2px solid red"
                      : "1px solid #d7d7d7"
                  }`,
                }}
                onChange={(e) => {
                  setCirtificateExp(e.target.value);

                  setCheckErrorInForm((prev) => ({
                    ...prev,
                    cirtificateExp: "",
                  }));
                }}
                id="hasQuiz"
                className="course-builder-inputSelect"
              >
                <option value="">Select</option>

                <option value="1">1 Year</option>
                <option value="2">2 Year</option>
                <option value="3">3 Year</option>
                <option value="4">4 Year</option>
                <option value="5">5 Year</option>
                <option value="--">NA</option>
              </select>

              {checkErrorInForm.cirtificateExp == "error" && (
                <span
                  style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
                >
                  Select cirtificate Expiration
                </span>
              )}
            </div>
          </div>
          <div className="course-builder-top">
            <div className="course-builder-info">
              <p className="course-builder-inputLable">
                Course Access Expiration (Days)*
              </p>
              <input
                type="number"
                className="course-builder-inputBar"
                id="courseExpirationDays"
                value={courseExpirationDays}
                style={{
                  border: `${
                    checkErrorInForm.courseExpirationDays == "error"
                      ? "2px solid red"
                      : "1px solid #d7d7d7"
                  }`,
                }}
                onChange={(event) => {
                  if (event.target.value < 0) {
                    event.target.value = courseExpirationDays;
                  } else {
                    setCourseExpirationDays(event.target.value);
                    setCheckErrorInForm((prev) => ({
                      ...prev,
                      courseExpirationDays: "",
                    }));
                  }
                }}
              />
              {checkErrorInForm.courseExpirationDays === "error" && (
                <span
                  style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
                >
                  Enter Course ExpirationDays
                </span>
              )}
            </div>

            <div className="course-builder-info">
              <p className="course-builder-inputLable">
                Set reminder before access expiry (days)
              </p>
              <input
                type="number"
                className="course-builder-inputBar"
                id="setcourseExpirationDays"
                value={expirationReminderDays}
                onChange={(event) => {
                  if (event.target.value < 0) {
                    event.target.value = courseExpirationDays;
                  } else {
                    setExpirationReminderDays(event.target.value);
                  }
                }}
              />
            </div>
            <div className="course-builder-info">
              <div
                className="course-builder-inputLable"
                style={{ marginTop: "2vw" }}
              >
                Course Progress
              </div>
              <select
                id="flow"
                value={flow}
                onChange={(e) => setFlow(e.target.value)}
                className="course-builder-inputSelect"
              >
                <option value="linear">Sequential</option>
                <option value="free">Free flow</option>
              </select>
            </div>
            <div className="course-builder-info">
              <p className="course-builder-inputLable">Course ID</p>
              <input
                disabled
                type="text"
                className="course-builder-inputBar"
                id="courseId"
                value={courseId}
              />
            </div>
            <div className="course-builder-info">
              <p className="course-builder-inputLable">
                Certificate Template *
              </p>
              <select
                className="course-builder-inputSelect"
                value={selectedTemplate}
                onChange={(e) => {
                  setSelectedTemplate(e.target.value);
                  setCheckErrorInForm((prev) => ({
                    ...prev,
                    selectedTemplate: "",
                  }));
                }}
                style={{
                  border: `${
                    checkErrorInForm.selectedTemplate == "error"
                      ? "2px solid red"
                      : "1px solid #d7d7d7"
                  }`,
                }}
              >
                <option value="" selected disabled hidden>
                  Select an Option
                </option>
                {templateArr?.map((item, id) => (
                  <option key={id} value={item.id}>
                    {item.templateName}
                  </option>
                ))}
              </select>
              {checkErrorInForm.selectedTemplate === "error" && (
                <span
                  style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
                >
                  {" "}
                  select Template
                </span>
              )}
            </div>

            {companyData.map((ele) => (
              <div className="course-builder-info">
                <p className="course-builder-inputLable">
                  {" "}
                  {ele.companyName} Certificate Template{" "}
                </p>
                <select
                  className="course-builder-inputSelect"
                  value={
                    selectCompany[ele.companyId]
                      ? selectCompany[ele.companyId]
                      : ele.companyTemplate
                  }
                  onChange={(e) => {
                    setSelectCompany((prev) => ({
                      ...prev,
                      [ele.companyId]: e.target.value,
                    }));
                    setCheckErrorInForm((prev) => ({
                      ...prev,
                      selectedTemplate: "",
                    }));
                  }}
                  style={{
                    border: `${
                      checkErrorInForm.selectedTemplate == "error"
                        ? "2px solid red"
                        : "1px solid #d7d7d7"
                    }`,
                  }}
                >
                  <option value="" selected disabled hidden>
                    Select an Option
                  </option>
                  {templateArr?.map((item, id) => (
                    <option key={id} value={item.id}>
                      {item.templateName}
                    </option>
                  ))}
                </select>
                {checkErrorInForm.selectedTemplate === "error" && (
                  <span
                    style={{
                      marginLeft: "20px",
                      color: "red",
                      fontSize: "15px",
                    }}
                  >
                    {" "}
                    select Template
                  </span>
                )}
              </div>
            ))}

            {role == "admin" && (
              <div className="course-builder-info">
                <div
                  className="course-builder-inputLable"
                  style={{ marginTop: "2vw" }}
                >
                  Course Instructor *
                </div>
                <Select
                  //size={10}
                  //   onFocus={10}
                  //   onBlur={1}
                  // onfocus='size=5'
                  // onblur='this.size=1;'
                  MenuProps={{
                    sx: {
                      maxHeight: "250px",
                    },
                  }}
                  id="courseInstructor"
                  className="course-builder-inputSelect"
                  value={instructorId}
                  onChange={(e) => {
                    setInstructorId(e.target.value);
                    setCheckErrorInForm((prev) => ({
                      ...prev,
                      instructorId: "",
                    }));
                  }}
                  style={{
                    border: `${
                      checkErrorInForm.instructorId == "error"
                        ? "2px solid red"
                        : "1px solid #d7d7d7"
                    }`,
                  }}
                >
                  <MenuItem value="default">Select an Option</MenuItem>
                  {/* {instructorArr?.map((item, id) => (
                    <option value={item.userId} key={id}>
                      {item.name}
                    </option>
                  ))} */}

                  {sharedByArr?.map((item, id) => (
                    <MenuItem key={id} value={item.userId}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>

                {checkErrorInForm.instructorId === "error" && (
                  <span
                    style={{
                      marginLeft: "20px",
                      color: "red",
                      fontSize: "15px",
                    }}
                  >
                    {" "}
                    select instructor
                  </span>
                )}
              </div>
            )}
            <div className="course-builder-info">
              <div
                className="course-builder-inputLable"
                style={{ marginTop: "2vw" }}
              >
                Course Shared With
              </div>
              <Select
                id="courseShare"
                // className="course-builder-multipleSelect"
                value={courseShare}
                onChange={handleCourseShare}
                native
                multiple
              >
                {sharedByArr?.map((item, id) => (
                  <option key={id} value={item.userId}>
                    {item.name}
                  </option>
                ))}
              </Select>
            </div>
            <br></br>
            {checkFailed && (
              <div>
                <p style={{ color: "red" }}>Fill all the field in the Form</p>
              </div>
            )}
          </div>
          <button
            id="addC"
            className="course-builder-button"
            onClick={addCourse}
          >
            Save Course
          </button>
          {/* <div style={{ height: "100px", margin: "auto" }} className="course-builder-top">
        <button
        id="addC"
          style={{ margin: "auto" }}
          className="course-builder-button"
          onClick={() => navigate(-1)}
        >
          Back
        </button>{" "}
      </div> */}
          {/* {courseAdd==false?<ViewCourses/>:<Lesson/>} */}
        </div>
      ) : (
        <div className="admin-overdue-bottom-table-cnt-certi" key={0}>
          <div className="admin-overdue-bottom-table-td"></div>
          <div className="admin-overdue-bottom-table-td"></div>
          <div className="admin-overdue-bottom-table-td"></div>
          <div className="admin-overdue-bottom-table-td"></div>
          <div className="admin-overdue-bottom-table-td">
            <div className="spinner-container">
              <div className="loading-spinner"></div>
            </div>
          </div>
          <div className="admin-overdue-bottom-table-td"></div>
          <div className="admin-overdue-bottom-table-td"></div>
          <div className="admin-overdue-bottom-table-td"></div>
          <div
            className="admin-overdue-bottom-table-td"
            style={{ color: "#004577" }}
          ></div>
        </div>
      )}
    </>
  );
};

export default Course;
