import { Box, Button, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { httpsCallable } from "firebase/functions";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { getCookie } from "../../../Cookies";
import { functions } from "../../../Firebase";
import {
  displayOnDesktop,
  displayOnMobile,
  flexCenter,
  responsiveFlexDirection,
  responsiveReverseFlexDirection,
  responsiveWidth
} from "../../../utilities/commonStyles/commonStyles";
import "../../styles/Training/Training.css";
import "../../Translater/Translater";
import Navbar from "../Navbar/Navbar";
import ResponsiveNavbar from "../Navbar/ResponsiveNavbar";
import "./MyAccount.css";

function MyAccount() {
  const navigate = useNavigate();
  const goToHome = () => navigate("/");

  /// ===========fetch user details==============//
  const fetchUserProfile = httpsCallable(functions, "fetchUserProfile");
  const [formInputs, setFormInputs] = useState({});

  const [imgshow, setimgshow] = useState("");

  useEffect(() => {
    fetchUserProfile({})
      .then((res) => {
        setFormInputs({
          name: res.data.name ? res.data.name : "",
          phoneNumber: res.data.phoneNumber ? res.data.phoneNumber : "",
          email: res.data.email ? res.data.email : "",
          department: res.data.department ? res.data.department : "",
          jobTitle: res.data.jobTitle ? res.data.jobTitle : "",
          gender: res.data.gender ? res.data.gender : "male",
          employeeId: res.data.employeeId ? res.data.employeeId : "",
          city: res.data.city ? res.data.city : "",
          country: res.data.country ? res.data.country : "",
          aboutMe: res.data.aboutMe ? res.data.aboutMe : "",
          dateOfBirth: res.data.dateOfBirth
            ? new Date(res.data.dateOfBirth._seconds * 1000)
                .toISOString()
                .split("T")[0]
            : "", //// convering seconds to date
          photoURL: res.data.photoURL ? res.data.photoURL : "",
        });
        setimgshow(res.data.photoURL); //setting url of image
        // //console.log({...res.data});
      })
      .catch((err) => {
        //console.log(err);
      });
  }, []);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormInputs({
      ...formInputs,
      [name]: value,
    });
  };

  ///===================update user details function ====================//

  const updateUserProfile = httpsCallable(functions, "updateUserProfile");

  const updateUserDetails = () => {
    //console.log(formInputs);
    if (formInputs.dateOfBirth !== "") {
      if (new Date(formInputs.dateOfBirth).getTime() > new Date().getTime()) {
        alert("Date of birth can not be greater than current date");
        return;
      }
    }
    updateUserProfile({
      ...formInputs,
    })
      .then((res) => {
        //console.log(res);
        alert("Profile Updated !");
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  //==================get photo url and set it to state====================//
  const [image, setImage] = useState({
    file: null,
    url: "",
  });

  const handleFileChange = (e, index) => {
    const { files } = e.target;
    const url = URL.createObjectURL(files[0]);
    setImage((prev) => ({
      file: files[0],
      url: url,
    }));

    //console.log(url);
    const reader = new FileReader();
    reader.onloadend = () => {
      // //console.log("Base64 converssion:", reader.result);
      setFormInputs({
        ...formInputs,
        base64image: reader.result,
      });
    };
    reader.readAsDataURL(files[0]);
  };

  // //console.log(formInputs);

  useEffect(() => {
    if (getCookie("UID") === null || getCookie("ROLE") !== "user") {
      goToHome();
      return <></>;
    }
  });
  const { t } = useTranslation();

  const [selectedValue, setSelectedValue] = React.useState("a");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: "color-radio-button-demo",
    inputProps: { "aria-label": item },
  });
  return (
    <Box>
      <Box sx={displayOnDesktop}>
        <Navbar page={t("My Account")} />
      </Box>
      <Box sx={displayOnMobile}>
        <ResponsiveNavbar page={t("My Account")} />
      </Box>
      <Box sx={{ my: 5 }}>
        <Container>
          <Box
            sx={{
              ...responsiveReverseFlexDirection,
              padding: "20px",
              background: "#fff",
              display: "flex",
            }}
          >
            <Box sx={{ ...responsiveWidth }}>
              <form>
                <Box sx={{ mt: 2 }}>
                  <Typography
                    sx={{ fontWeight: "700", mb: 1, color: "#007C84" }}
                  >
                    Name
                  </Typography>
                  <input
                    type="text"
                    value={formInputs.name}
                    onChange={handleOnChange}
                    required
                    name="name"
                    style={{
                      width: "100%",
                      borderRadius: "4px",
                      padding: "10px 15px",
                      border: "1px solid #007C84",
                      outline: "none",
                    }}
                  />
                </Box>

                <Box sx={{ mt: 2 }}>
                  <Typography
                    sx={{ fontWeight: "700", mb: 1, color: "#007C84" }}
                  >
                    Email Address
                  </Typography>
                  <input
                    type="text"
                    disabled
                    value={formInputs.email}
                    style={{
                      width: "100%",
                      borderRadius: "4px",
                      padding: "10px 15px",
                      border: "1px solid #007C84",
                      outline: "none",
                    }}
                  />
                </Box>
                <Box sx={{ mt: 2 }}>
                  <Typography
                    sx={{ fontWeight: "700", mb: 1, color: "#007C84" }}
                  >
                    Phone Number
                  </Typography>
                  <input
                    type="number"
                    value={formInputs.phoneNumber}
                    onChange={handleOnChange}
                    required
                    name="phoneNumber"
                    className="phoneNumber_only_number"
                    style={{
                      width: "100%",
                      borderRadius: "4px",
                      padding: "10px 15px",
                      border: "1px solid #007C84",
                      outline: "none",
                    }}
                  />
                </Box>
                <Box sx={{ mt: 2 }}>
                  <Typography
                    sx={{ fontWeight: "700", mb: 1, color: "#007C84" }}
                  >
                    Job Title
                  </Typography>
                  <input
                    type="text"
                    value={formInputs.jobTitle}
                    onChange={handleOnChange}
                    required
                    name="jobTitle"
                    style={{
                      width: "100%",
                      borderRadius: "4px",
                      padding: "10px 15px",
                      border: "1px solid #007C84",
                      outline: "none",
                    }}
                  />
                </Box>
                <Box sx={{ mt: 2 }}>
                  <Typography
                    sx={{ fontWeight: "700", mb: 1, color: "#007C84" }}
                  >
                    Department
                  </Typography>
                  <input
                    type="text"
                    value={formInputs.department}
                    onChange={handleOnChange}
                    required
                    name="department"
                    style={{
                      width: "100%",
                      borderRadius: "4px",
                      padding: "10px 15px",
                      border: "1px solid #007C84",
                      outline: "none",
                    }}
                  />
                </Box>
                <Box className="radioButton" sx={{ mt: 2 }}>
                  <Typography
                    sx={{ fontWeight: "700", mb: 1, color: "#007C84" }}
                  >
                    Gender
                  </Typography>
                  <label>
                    {/* checked={status === "Inprogress"} */}
                    <input
                      type="radio"
                      name="gender"
                      value="male"
                      checked={formInputs.gender === "male"}
                      onChange={handleOnChange}
                    />
                    <span
                      style={{
                        color: "#007C84",
                        fontWeight: 600,
                        fontSize: "16px",
                      }}
                    >
                      Male
                    </span>
                  </label>
                  <label style={{ marginLeft: "20px" }}>
                    <input
                      type="radio"
                      name="gender"
                      value="female"
                      checked={formInputs.gender === "female"}
                      onChange={handleOnChange}
                    />
                    <span
                      style={{
                        color: "#007C84",
                        fontWeight: 600,
                        fontSize: "16px",
                      }}
                    >
                      Female
                    </span>
                  </label>
                </Box>
                <Box sx={{ mt: 2 }}>
                  <Typography
                    sx={{ fontWeight: "700", mb: 1, color: "#007C84" }}
                  >
                    Date Of Birth
                  </Typography>
                  <input
                    type="date"
                    value={formInputs.dateOfBirth}
                    onChange={handleOnChange}
                    required
                    name="dateOfBirth"
                    style={{
                      width: "100%",
                      borderRadius: "4px",
                      padding: "10px 15px",
                      border: "1px solid #007C84",
                      outline: "none",
                      color: "#007C84",
                    }}
                  />
                </Box>

                <Box
                  sx={{
                    ...responsiveFlexDirection,
                    display: "flex",
                    justifyContent: "space-between",
                    mt: 2,
                  }}
                >
                  <Box>
                    <Typography
                      sx={{ fontWeight: "700", mb: 1, color: "#007C84" }}
                    >
                      City
                    </Typography>
                    <input
                      type="text"
                      value={formInputs.city}
                      onChange={handleOnChange}
                      required
                      name="city"
                      style={{
                        width: "100%",
                        borderRadius: "4px",
                        padding: "10px 15px",
                        border: "1px solid #007C84",
                        outline: "none",
                      }}
                    />
                  </Box>
                  <Box>
                    <Typography
                      sx={{ fontWeight: "700", mb: 1, color: "#007C84" }}
                    >
                      Country
                    </Typography>
                    <input
                      type="text"
                      value={formInputs.country}
                      onChange={handleOnChange}
                      required
                      name="country"
                      style={{
                        width: "100%",
                        borderRadius: "4px",
                        padding: "10px 15px",
                        border: "1px solid #007C84",
                        outline: "none",
                      }}
                    />
                  </Box>
                </Box>
                <Box sx={{ mt: 2 }}>
                  <Typography
                    sx={{ fontWeight: "700", mb: 1, color: "#007C84" }}
                  >
                    Employee ID
                  </Typography>
                  <input
                    type="text"
                    disabled
                    value={formInputs.employeeId}
                    style={{
                      width: "100%",
                      borderRadius: "4px",
                      padding: "10px 15px",
                      border: "1px solid #007C84",
                      outline: "none",
                    }}
                  />
                </Box>
                <Box sx={{ mt: 2 }}>
                  <Typography
                    sx={{ fontWeight: "700", mb: 1, color: "#007C84" }}
                  >
                    About Me
                  </Typography>
                  <textarea
                    type="text"
                    value={formInputs.aboutMe}
                    onChange={handleOnChange}
                    required
                    name="aboutMe"
                    style={{
                      width: "100%",
                      height: "100px",
                      borderRadius: "4px",
                      padding: "10px 15px",
                      border: "1px solid #007C84",
                      outline: "none",
                    }}
                  />
                </Box>

                <Box sx={displayOnMobile}>
                  <Box
                    sx={{
                      ...flexCenter,
                      mt: "20px",
                    }}
                  >
                    <Button
                      onClick={updateUserDetails}
                      sx={{
                        width: "180px",
                        textTransform: "none",
                        color: "#fff",
                        padding: "5px 30px",
                        background:
                          "linear-gradient(to right, #007848, #004577)",
                        borderRadius: "12px",
                        fontWeight: 600,
                      }}
                    >
                      Submit
                    </Button>
                  </Box>
                </Box>
              </form>
            </Box>
            <Box sx={{ ...responsiveWidth }}>
              <Box
                sx={{
                  height: "35%",
                  display: "flex",
                  justifyContent: { xs: "flex-start", md: "flex-end" },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: { xs: "row", md: "column" },
                    gap: "20px",
                  }}
                >
                  <Box
                    sx={{
                      width: "140px",
                      height: "150px",
                      border: "1px solid #007C8480",
                    }}
                  >
                    <img
                      src={image.url ? image.url : imgshow}
                      alt={"image"}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </Box>
                  <Button
                    component="label"
                    sx={{
                      textTransform: "none",
                      background: "linear-gradient(to right, #007848, #004577)",
                      width: "180px",
                      borderRadius: "12px",
                      color: "#fff",
                    }}
                  >
                    {"Upload"}
                    <input type={"file"} onChange={handleFileChange} hidden />
                  </Button>
                </Box>
              </Box>
              <Box sx={displayOnDesktop}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "flex-end",
                    height: "65%",
                  }}
                >
                  <Button
                    onClick={updateUserDetails}
                    sx={{
                      width: "180px",
                      textTransform: "none",
                      color: "#fff",
                      padding: "5px 30px",
                      background: "linear-gradient(to right, #007848, #004577)",
                      borderRadius: "12px",
                      fontWeight: 600,
                    }}
                  >
                    Submit
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
}

export default MyAccount;
