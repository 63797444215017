import { t } from "i18next";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getCookie } from "../../Cookies";
import {
  ExternalTrainingIcons,
  InternalOfflineTrainingIcons,
  InternalTrainingLmsIcons
} from "../../utilities/Icons/iconsComponent";
import Navbar from "../Component/NavbarTop";
import Sidebar from "../Component/Sidebar";
import "./Trainings.css";

function Trainings() {
  const { clrs } = useSelector((state) => state.createClr);
  const navigate = useNavigate();
  const goToHome = () => navigate;
  useEffect(() => {
    if (
      getCookie("UID") == null ||
      (getCookie("ROLE") != "admin" && getCookie("ROLE") != "instructor")
    ) {
      goToHome();
      return <></>;
    }
  });

  return (
    <div className="courselist">
      <Navbar page={t("Training")} />
      <div className="courselist-main">
        <Sidebar page={"training"} hidepage={false} />
        <div className="courselist-main-cnt">
          {/* <div className="admin-overdue-top">
            <div className="admin-overdue-top-item">{t("InternalTrainingLMS")}</div>
          </div> */}
          <div className="admin-dashboard-main-bottom">
            <div className="admin-dashboard-main-bottom-cnt">
              <div className="admin-dashboard-main-bottom-left-icon">
                <InternalTrainingLmsIcons />
              </div>
              <div className="admin-dashboard-main-bottom-right">
                <div className="admin-dashboard-main-bottom-right-top">
                  {t("InternalTrainingLMS")}{" "}
                </div>
                <div className="admin-dashboard-main-bottom-right-btm">
                  {t("ListOfInternalTrainingLMS")}
                </div>
                <div className="admin-dashboard-main-bottom-right-btn">
                  <div className="admin-dashboard-main-bottom-item-left-btm">
                    <Link to={"/admin/training/internal"}>
                      <button
                        style={{
                          background: `${
                            clrs.CourseListBtn
                              ? clrs.CourseListBtn
                              : "hwb(224deg 78% 3%)"
                          }`,
                        }}
                      >
                        <div>{t("View")}</div>
                      </button>
                    </Link>
                  </div>
                  <div className="admin-dashboard-main-bottom-item-left-btm">
                    <Link to={"/admin/courseBuilder"}>
                      <button
                        style={{
                          background: `${
                            clrs.CourseListBtn
                              ? clrs.CourseListBtn
                              : "hwb(224deg 78% 3%)"
                          }`,
                        }}
                      >
                        <div>{t("Add")}</div>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="admin-dashboard-main-bottom-cnt">
              <div className="admin-dashboard-main-bottom-left-icon">
                <ExternalTrainingIcons />
              </div>
              <div className="admin-dashboard-main-bottom-right">
                <div className="admin-dashboard-main-bottom-right-top">
                  {t("ExternalTraining")}
                </div>
                <div className="admin-dashboard-main-bottom-right-btm">
                  {t("ListOfExternalTraining")}
                </div>
                <div className="admin-dashboard-main-bottom-right-btn">
                  <div className="admin-dashboard-main-bottom-item-left-btm">
                    <Link to={"/admin/training/extrenal"}>
                      <button
                        style={{
                          background: `${
                            clrs.CourseListBtn
                              ? clrs.CourseListBtn
                              : "hwb(224deg 78% 3%)"
                          }`,
                        }}
                      >
                        <div>{t("View")}</div>
                      </button>
                    </Link>
                  </div>
                  <div className="admin-dashboard-main-bottom-item-left-btm">
                    <Link to={"/admin/training/extrenal/add"}>
                      <button
                        style={{
                          background: `${
                            clrs.CourseListBtn
                              ? clrs.CourseListBtn
                              : "hwb(224deg 78% 3%)"
                          }`,
                        }}
                      >
                        <div>{t("Add")}</div>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="admin-dashboard-main-bottom-cnt">
              <div className="admin-dashboard-main-bottom-left-icon">
                <InternalOfflineTrainingIcons />
              </div>
              <div className="admin-dashboard-main-bottom-right">
                <div className="admin-dashboard-main-bottom-right-top">
                  {t("InternalOfflineTraining")}
                </div>
                <div className="admin-dashboard-main-bottom-right-btm">
                  {t("ListOfInternalOfflineTraining")}
                </div>
                <div className="admin-dashboard-main-bottom-right-btn">
                  <div className="admin-dashboard-main-bottom-item-left-btm">
                    <Link to={"/admin/training/internalOff"}>
                      <button
                        style={{
                          background: `${
                            clrs.CourseListBtn
                              ? clrs.CourseListBtn
                              : "hwb(224deg 78% 3%)"
                          }`,
                        }}
                      >
                        <div>{t("View")}</div>
                      </button>
                    </Link>
                  </div>

                  <div className="admin-dashboard-main-bottom-item-left-btm">
                    <Link to={"/admin/training/internalOff/add"}>
                      <button
                        style={{
                          background: `${
                            clrs.CourseListBtn
                              ? clrs.CourseListBtn
                              : "hwb(224deg 78% 3%)"
                          }`,
                        }}
                      >
                        <div>{t("Add")}</div>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Trainings;
