import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as Play } from "./icons/play.svg";
import { ReactComponent as Pause } from "./icons/pause.svg";
import { ReactComponent as Mute } from "./icons/mute.svg";
import { ReactComponent as Forward10 } from "./icons/forward10s.svg";
import { ReactComponent as Back10 } from "./icons/back10s.svg";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";

export const CustomAudioPlayer = ({ url }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isMuted, setIsMuted] = useState(true);

  const audioSeekRef = useRef();
  const audioElementRef = useRef();
  useEffect(() => {
    audioElementRef.current.ontimeupdate = (e) => {
      const percentagePosition =
        (audioElementRef.current.currentTime /
          audioElementRef.current.duration) *
        100;
      audioSeekRef.current.style.backgroundSize = `${percentagePosition}% 100%`;
      setProgress(percentagePosition);
    };
  }, []);

  const handleSeek = (e) => {
    const { value } = e.target;
    audioSeekRef.current.style.backgroundSize = `${value}% 100%`;
    const newTime = (value * audioElementRef.current.duration) / 100;
    audioElementRef.current.currentTime = newTime;
    setProgress(value);
  };

  const changeTime = (action) => {
    if (action == "forward") {
      audioElementRef.current.currentTime += 10;
    } else if (action == "back") {
      audioElementRef.current.currentTime -= 10;
    }
    const percentagePosition =
      (audioElementRef.current.currentTime / audioElementRef.current.duration) *
      100;
    audioSeekRef.current.style.backgroundSize = `${percentagePosition}% 100%`;
    setProgress(percentagePosition);
  };

  const handlePlayPause = () => {
    //console.log(audioElementRef.current);
    if (isPlaying) {
      audioElementRef.current.pause();
    } else {
      audioElementRef.current.play();
    }
    setIsPlaying((prev) => !prev);
  };

  const handleMute = () => {
    audioElementRef.current.muted = !audioElementRef.current.muted;
    setIsMuted(!audioElementRef.current.muted);
  };

  return (
    <div style={{ width: "100%" }}>
      <audio src={url} ref={audioElementRef} loop>
        Your Browser doesn't support audio.
      </audio>

      <input
        className="customAudioSeek"
        type="range"
        value={progress}
        onChange={handleSeek}
        ref={audioSeekRef}
      />
      <div className="customControls">
        <Back10
          onClick={() => changeTime("back")}
          style={{ marginLeft: "auto", marginRight: "15px" }}
        />
        {isPlaying ? (
          <Pause
            width={"1.5em"}
            onClick={handlePlayPause}
            style={{ marginRight: "15px" }}
          />
        ) : (
          <Play
            width={"1.5em"}
            onClick={handlePlayPause}
            style={{ marginRight: "15px" }}
          />
        )}

        <Forward10
          onClick={() => changeTime("forward")}
          style={{ marginRight: "15px" }}
        />
        {isMuted ? (
          <VolumeUpIcon onClick={handleMute} style={{ marginRight: "auto", color:"#00495F"  }} />
        ) : (
          <VolumeOffIcon onClick={handleMute} style={{ marginRight: "auto", color:"#00495F" }} />
        )}
      </div>
    </div>
  );
};
