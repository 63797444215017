import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getCookie, setCookie } from "../../../Cookies";
import { functions } from "../../../Firebase";
import loginImg from "../../../utilities/images/algaith.png";
import responsiveLoginImg from "../../../utilities/images/svgImages/responsive-login-img.svg";
import classes from "../../styles/LoginpageStyle/LoginPage.module.css";
import loginLogo from "../img/logo/logo.png";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { httpsCallable } from "firebase/functions";
import {
  displayOnDesktop,
  displayOnMobile,
  responsiveReverseFlexDirection,
} from "../../../utilities/commonStyles/commonStyles";
import UpadtePassword from "../updatePassword/updatePassword";
import axios from "axios";
import { IP } from "../../../config";
import { createClrs } from "../../Redux/Actions";
import { newGreen, defultGreen } from "../../Redux/Colors";
import { useDispatch } from "react-redux";
const { createTheme } = require("@mui/material");

function LoginPage({ messagingToken }) {
  const navigate = useNavigate();
  const goToHome = () => navigate("/");
  const goToUserDashboard = () => navigate("/user/home");
  const goToAdminDashboard = () => navigate("/admin");
  const goToInstructorDashboard = () => navigate("/admin");
  const fetchUserProfile = httpsCallable(functions, "fetchUserProfile");
  const updateUserProfileInvitation = httpsCallable(
    functions,
    "updateUserProfileInvitation"
  );
  // const [role, setRole] = useState("");
  const saveToken = httpsCallable(functions, "saveToken");
  const [user, setUser] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [userNameError, setUserNameError] = useState("");
  const [userSetNotification, setUserSetNotification] = useState("");
  const [notification, setNotification] = useState("");
  const [loading, setLoading] = useState(false);

  const [keep, setKeep] = useState(false);
  ///========visibility password=======//
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const clearInputs = () => {
    setUserNameError("");
    setPassword("");
    setNotification("");
  };

  const clearErrors = () => {
    setNotification("");
    setUserNameError("");
    setPasswordError("");
  };
  useEffect(() => {
    // authListener();

    dispatch(createClrs(newGreen));
  }, []);
  ///if user is logged in, redirect to user dashboard
  useEffect(() => {
    if (getCookie("ROLE") === "admin") {
      goToAdminDashboard();
      return;
    }
    if (getCookie("ROLE") === "instructor") {
      goToInstructorDashboard();
      return;
    }
    if (getCookie("ROLE") === "user") {
      goToUserDashboard();
      return;
    }
  }, []);

  const handleLogin = () => {
    clearErrors();
    if (userName == "") {
      return setUserSetNotification("Please enter your username");
    } else if (!userName.includes("@")) {
      return setUserSetNotification("Invalid Email");
    } else {
      setUserSetNotification("");
    }
    if (password == "") {
      return setNotification("Please enter your password");
    } else {
      setNotification("");
    }
    setLoading(true);
    const auth = getAuth();
    // signInWithEmailAndPassword(auth, userName, password)

    let configSignIn = {
      method: "post",
      // params: { userId: uid },
      url: `${IP}/auth/signin
      `,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        email: userName.toLowerCase().trim(),
        password: password,
      }),
    };

    axios(configSignIn)
      .then((userCredential) => {
        setLoading(false);
        const user = userCredential.data;
        // saveToken({ token: messagingToken });
        let configLesson = {
          method: "post",
          // params: { userArr:userArr },
          url: `${IP}/mail/saveToken`,

          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.idToken}`,
          },
          data: JSON.stringify({
            token: messagingToken,
          }),
        };
        axios(configLesson);

        // setCookie("UID", user.uid);
        setCookie("rfToken", user.refreshToken);
        setCookie("bearer", user.idToken);

        let configProfile = {
          method: "get",
          // params: { userId: uid },
          url: `${IP}/userProfile`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.idToken}`,
          },
          data: "dataexp",
        };

        axios(configProfile).then((result) => {
          if (!result.data.invitationAccept) {
            navigate("/update-password", { state: { idToken: user.idToken } });

            return;
          } else {
            const role = result.data.role;
            setCookie("ROLE", role.toLowerCase());
            // setRole(role);
            const name = result.data.name;
            setCookie("NAME", name);
            const empId = result.data.employeeId;
            setCookie("EMPID", empId);
            setCookie("keepMeLogin", keep);
            setCookie("UID", result.data.uid);

            if (role == "ADMIN") goToAdminDashboard();
            else if (role == "INSTRUCTOR") goToInstructorDashboard();
            else if (role == "USER") goToUserDashboard();
          }
        });
      })
      .catch((err) => {
        setLoading(false);
        let error = err?.response?.data?.error;
        console.log(err?.response?.data?.error);
        if (error === "USER_DISABLED") {
          // //console.log("fff");
          return setNotification(
            "This user archived Please contact to Alghaith"
          );
        }
        if (error == "EMAIL_NOT_FOUND" || error == "INVALID_PASSWORD")
          return setNotification("Enter valid Credentials");
        else return setNotification(error);
        switch (error) {
          case "auth/invalid-username":
          case "auth/user-disabled":
          case "auth/user-not-found":
            setUserNameError(err.message);
            break;
          case "auth/wrong-password":
            setPasswordError(err.message);
            break;
        }
      });
  };

  const handleLogout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        goToHome();
      })
      .catch((error) => {
        // An error happened.
      });
    //   fire.auth().signOut();
  };

  const authListener = () => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        clearInputs();
        const uid = user.uid;
        // //console.log(user.accessToken);
        setUser(user);
      } else {
        setUser("");
      }
    });
  };

  const setKipemeLog = (e) => {
    setKeep(!keep);
    ////console.log(keep)
    //  //console.log(e)
  };

  //console.log(keep)
  return (
    <Box sx={{ my: { xs: "50px", md: "0" } }}>
      <Container
        sx={{
          ...responsiveReverseFlexDirection,
          height: { xs: "auto", md: "100vh" },
          gap: "20px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: { xs: "100%", md: "50%" },
            border: "3px solid #007848",
            borderRadius: "10px",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <img className={classes.logo} src={loginLogo} alt="" />
          </Box>
          <Box sx={{ padding: { xs: "25px", md: "50px" } }}>
            <Box>
              <Typography
                sx={{
                  color: (theme) => theme.palette.primary.main,
                  fontWeight: 700,
                  marginTop: "-20px",
                  fontSize: { xs: "24px", md: "48px" },
                }}
              >
                Log in
              </Typography>
              <Typography
                sx={{
                  my: 2,
                  color: (theme) => theme.palette.primary.btn,
                  fontSize: { xs: "10px", md: "18px" },
                }}
              >
                Enter your credentials
              </Typography>
            </Box>
            <form
              action=""
              onSubmit={(e) => {
                e.preventDefault();
                handleLogin();
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontWeight: "600",
                    mb: 1,
                    color: (theme) => theme.palette.primary.main,
                    fontSize: { xs: "12px", md: "16px" },
                  }}
                >
                  Enter Your Email
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    borderRadius: "6px",
                    padding: "10px 15px",
                    border: "1px solid #007848",
                    outline: "none",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="text"
                    required
                    autofocus={userName.length == 0}
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                    placeholder="name@domain.com"
                    style={{
                      width: "100%",
                      padding: "7px",
                      border: "none",
                      outline: "none",
                    }}
                  />
                </Box>
              </Box>
              <Box>
                {userSetNotification && (
                  <span style={{ color: "red", marginTop: "20px" }}>
                    {userSetNotification}
                  </span>
                )}
              </Box>
              <Box sx={{ mt: 3, marginBottom: "2px" }}>
                <Typography
                  sx={{
                    fontWeight: "600",
                    mb: 1,
                    color: (theme) => theme.palette.primary.main,
                    fontSize: { xs: "12px", md: "16px" },
                  }}
                >
                  Enter Your Password
                </Typography>
                <div
                  style={{
                    width: "100%",
                    borderRadius: "6px",
                    padding: "10px 15px",
                    border: "1px solid #007848",
                    outline: "none",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    required
                    value={password}
                    id={showPassword ? "showPassword" : "hiddePassword"}
                    onChange={(e) => setPassword(e.target.value)}
                    style={{ border: "none", outline: "none", width: "90%" }}
                  />
                  <div onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? (
                      <Tooltip title="Hide Password" placement="top-start">
                        <VisibilityIcon sx={{ color: "#007848" }} />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Show Password" placement="top-start">
                        <VisibilityOffIcon sx={{ color: "#007848" }} />
                      </Tooltip>
                    )}
                  </div>
                </div>
              </Box>
              <Box>
                {notification && (
                  <span style={{ color: "red", marginTop: "20px" }}>
                    {notification}
                  </span>
                )}
              </Box>

              <Box sx={{ mt: 3, display: "flex", alignItems: "center" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="success"
                      sx={{ color: (theme) => theme.palette.primary.main }}
                      onChange={(e) => {
                        setKipemeLog(e.target.value);
                      }}
                    />
                  }
                />
                <label
                  style={{
                    fontWeight: 500,
                    color: "#003A69",
                    fontSize: { xs: "12px", md: "14px" },
                    marginLeft: "-20px",
                    fontFamily: "'Readex Pro', sans-serif",
                  }}
                >
                  Keep me logged in
                </label>
              </Box>
              <Box sx={{ mt: 3 }}>
                <Button
                  // onClick={handleLogin}
                  type="submit"
                  sx={{
                    textTransform: "none",
                    width: "100%",
                    py: 1,
                    borderRadius: "15px",
                    fontSize: "16px",
                    fontWeight: 500,
                    background: (theme) => theme.palette.primary.main,
                    color: "#fff",
                    "&:hover": {
                      background: (theme) => theme.palette.primary.main,
                    },
                  }}
                >
                  {loading ? "Loging in ..." : "Log in"}
                </Button>
              </Box>

              <Box sx={{ mt: 3 }}>
                <Link to="/forgot-password">
                  <Typography
                    sx={{
                      textAlign: "center",
                      color: (theme) => theme.palette.secondary.main,
                      fontWeight: 600,
                      fontSize: { xs: "12px", md: "14px" },
                    }}
                  >
                    Forgot Password?
                  </Typography>
                </Link>
              </Box>
            </form>
          </Box>
        </Box>
        <Box
          sx={{
            ...displayOnDesktop,
            width: "50%",
            maxHeight: "630px",
            borderRadius: "10px",
          }}
        >
          <img
            style={{ width: "100%", height: "630px", borderRadius: "10px" }}
            src={loginImg}
            alt=""
          />
        </Box>
        <Box
          sx={{
            ...displayOnMobile,
            width: "100%",
            borderRadius: "10px",
          }}
        >
          <img
            style={{ width: "100%", borderRadius: "10px" }}
            src={responsiveLoginImg}
            alt=""
          />
        </Box>
      </Container>
    </Box>
  );
}

export default LoginPage;

/* <div className="loginpage">
  <div className="loginpage-main">
    <div className="loginpage-main-left">
      <div className="loginpage-main-left-cnt">
        <div className="loginpage-main-left-cnt-items">
          <div className="loginpage-main-left-cnt-items-text-1">Log in</div>
          <div className="loginpage-main-left-cnt-items-text-2">
            Log in with your credentials provided by admin
          </div>
          <div className="loginpage-main-left-cnt-items-text-3">
            Enter Your Username
          </div>
          <div className="loginpage-main-left-cnt-items-input">
            <input type="text" placeholder="name@domain.com" required
              value={userName}
              onChange={(e) => setUserName(e.target.value)} />
            <p className="errorMsg">{userNameError}</p>
          </div>
          <div className="loginpage-main-left-cnt-items-text-4">
            Enter Your Password
          </div>
          <div className="loginpage-main-left-cnt-items-input">
            <input type="password" placeholder="At least 8 charactor" required
              value={password}
              onChange={(e) => setPassword(e.target.value)} />
            <p className="errorMsg"> {passwordError}</p>
          </div>
          <div className="loginpage-main-left-cnt-items-text-5">
            <FormControlLabel
              control={<Checkbox color="success" />}
              label="Keep me logged in"
            />
          </div>
          <div className="loginpage-main-left-cnt-items-login-btn">
            <button onClick={handleLogin}>Log in</button>
          </div>
          <div className="loginpage-main-left-cnt-items-text-6">
            Forgot Password?
          </div>
        </div>
      </div>
    </div>
    <div className="loginpage-main-right">
      <div className="loginpage-main-right-cnt">
        <img src={loginImg} alt="loading" />
      </div>
    </div>
  </div>
</div> */
