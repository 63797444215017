import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { Box } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import { Navigate, useNavigate } from "react-router";
// import { functions } from "../../../../Firebase";

const EnterBodyAndTittel = (props) => {
  const { onClose, open, delfun, cateName = "test" } = props;
  const handleClose = () => {
    setInputId("");
    setBody("");
    onClose();
  };
  const [dueDate, setDueDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [loading, setLoading] = useState(false);

  const [inputId, setInputId] = useState("");

  const [body, setBody] = useState("");
  let navigater = useNavigate();
  const runGenerateCertificateTraining = () => {
    /// navigater("/admin/course-reports")

    if (body.length < 20) {
      alert("min 20 word are needed in Body ");
      return;
    }

    if (body.length > 250) {
      alert("max 250 word you can add in Body ");
      return;
    }

    let obj = {
      body: body,
      title: "",
    };
    delfun(obj);
    setInputId("");
    setBody("");
    onClose();
  };

  return (
    <Dialog
      fullWidth={false}
      maxWidth="300px"
      PaperProps={{
        style: {
          borderRadius: "20px",
          height: "310px",
          padding: "16px",
        },
      }}
      onClose={() => {
        if (loading) return;
        handleClose();
      }}
      open={open}
    >
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          textAlign: "left",
          color: "#11047A",
        }}
      >
        <p> Notification </p>
        <button
          onClick={() => {
            handleClose();
            if (loading) return;
          }}
          style={{
            width: "40px",
            height: "40px",
            background: "#f4f7fe",
            marginLeft: "auto",
            borderRadius: "5px",
            border: "none",
          }}
        >
          <svg
            width="11"
            height="11"
            viewBox="0 0 11 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.43 0.244723C10.1145 -0.0707873 9.60485 -0.0707873 9.28934 0.244723L5.33333 4.19264L1.37732 0.236633C1.06181 -0.0788775 0.552143 -0.0788775 0.236633 0.236633C-0.0788775 0.552143 -0.0788775 1.06181 0.236633 1.37732L4.19264 5.33333L0.236633 9.28934C-0.0788775 9.60485 -0.0788775 10.1145 0.236633 10.43C0.552143 10.7455 1.06181 10.7455 1.37732 10.43L5.33333 6.47402L9.28934 10.43C9.60485 10.7455 10.1145 10.7455 10.43 10.43C10.7455 10.1145 10.7455 9.60485 10.43 9.28934L6.47402 5.33333L10.43 1.37732C10.7375 1.0699 10.7375 0.552143 10.43 0.244723Z"
              fill="#707EAE"
            />
          </svg>
        </button>
      </DialogTitle>

      <Box
        sx={{
          padding: "16px 24px",
        }}
      >
        {/* <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        > */}
        {/* <div style={{ width: "60vw" }}>
            <level> Title </level>

            <br />
            <br />
            <textarea
              value={inputId}
              onChange={(e) => {
                setInputId(e.target.value);
              }}
              style={{
                width: "80%",
                height: "6vh",
                border: "1px solid #d7d7d7",
                borderRadius: "6px",
              }}
              placeholder="Enter Title "
            />
          </div> */}
        {/* </div> */}

        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div style={{ width: "60vw" }}>
            <level> Notes </level>

            <br />
            <br />
            <textarea
              value={body}
              onChange={(e) => {
                setBody(e.target.value);
              }}
              style={{
                width: "80%",
                height: "10vh",
                border: "1px solid #d7d7d7",
                borderRadius: "6px",
              }}
              placeholder="Enter Body "
            />
          </div>
        </div>
        <Button
          variant="contained"
          sx={{
            mt: "1em",
            color: "#fff",
            background: "#007c84",
            borderRadius: "70px",
            width: "20vw",
          }}
          disable={loading}
          onClick={runGenerateCertificateTraining}
        >
          {loading ? "Loading..." : "Submit"}
        </Button>
      </Box>
    </Dialog>
  );
};

export default EnterBodyAndTittel;
