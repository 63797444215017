import { Button, Container, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { httpsCallable } from "firebase/functions";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { getCookie } from "../../../Cookies";
import { functions } from "../../../Firebase";
import {
  CourseVideoIcons,
  CourseVideoWatchIcons,
  SearchIcons
} from "../../../utilities/Icons/Icons";
import "../../styles/CourseLibrary/CourseLibraryMain.css";
import "../../Translater/Translater";
import Navbar from "../Navbar/Navbar";
import classes from "./CourseLibrary.module.css";

function ViewAllCourses() {
  const navigate = useNavigate();
  const goToHome = () => navigate("/");
  const location = useLocation();
  const courseFromBack = location.state.courses;
  const courseLibrary = httpsCallable(functions, "courseLibrary");
  const { t } = useTranslation();
  const { clrs } = useSelector((state) => state.createClr);

  const uid = getCookie("UID");

  const [courses, setCourses] = useState([]);
  const [searchCoursess, setSearchCourses] = useState([]);
  const [loading, setLoading] = useState(false);

  async function fetchCourse() {
    const res = await courseLibrary({});
    setCourses(res.data);
    setSearchCourses(res.data);
    setLoading(false);
    // //console.log(res.data);
  }
  useEffect(() => {
    if (getCookie("UID") == null || getCookie("ROLE") != "user") {
      goToHome();
      return <></>;
    }
  }, []);

  //==========get data of course to know which course is allready enrolled==========//
  const fetch_user_courses = httpsCallable(functions, "fetch_user_courses");

  /////======= fetch user courses
  const [user_courses, setUserCourses] = useState([]);

  useEffect(() => {
    if (courseFromBack) {
      setCourses(courseFromBack);
      setSearchCourses(courseFromBack);
    } else {
      setLoading(true);
    }
    fetchCourse();
    fetch_user_courses({ select: 1 })
      .then((res) => {
        //console.log(res.data);
        // setUserCourses([...res.data]);
        const temp = [];
        res.data.forEach((course) => {
          temp.push(course.courseId);
        });
        setUserCourses([...temp]);
      })
      .catch((err) => {
        //console.log(err);
      });
  }, []);
  // //console.log(user_courses);

  //==============search functionality================//
  const searchCourse = (e) => {
    const search = e.target.value;

    const filteredCourses = courses.filter((course) => {
      return course.courseName?.toLowerCase().includes(search.toLowerCase());
    });
    setSearchCourses([...filteredCourses]);
  };

  ////===================endroll here========================//
  const userEnrollment = httpsCallable(functions, "userEnrollment");
  //check single course to direct enroll
  const enrollUser = (courseId) => {
    // alert("Enrolled");
    userEnrollment({ courseId: courseId, currentDate: new Date() })
      .then((res) => {
        //console.log(res.data);
      })
      .catch((err) => {
        //console.log(err);
      });
    navigate(`/user/course-library/single-course-overview/single-course-content/${courseId}`);
  };

  return (
    <Box>
      <Navbar page={t("CourseLibrary")} />
      <Box>
        <Container sx={{ my: 4 }}>
          <Box sx={{ background: "#fff", p: 2 }}>
            <Box className={classes.searchBox} sx={{ mb: 4 }}>
              <div
                style={{
                  width: "300px",
                  display: "flex",
                  alignItems: "center",
                  gap: "15px",
                  background: "linear-gradient(to right, #00768714, #0076870F, #00768705)",
                  borderRadius: "10px",
                  padding: "10px 15px",
                }}
              >
                <SearchIcons />
                <input
                  className={classes.input}
                  type="text"
                  placeholder={t("SearchCourse")}
                  onChange={searchCourse}
                />
              </div>
            </Box>
            <Grid container spacing={{ xs: 1, sm: 2, md: 3 }} sx={{ mt: 10 }}>
              {searchCoursess.map((item, index) => (
                <Grid item xs={4}>
                  <Link to="/user/course-library/single-course-overview" state={item} key={index}>
                    <Box
                      className="bgImage"
                      sx={
                        item.courseImageURL
                          ? { backgroundImage: `url(${item.courseImageURL})` }
                          : {}
                      }
                    >
                      <CourseVideoIcons />
                    </Box>
                  </Link>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 600,
                        color: "#007C84",
                        margin: "7px 0px",
                      }}
                    >
                      {item.courseName}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontFamily: "'Readex Pro', sans-serif",
                        color: "#007C84",
                      }}
                    >
                      {item.courseDescription}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mt: 1,
                    }}
                  >
                    <Button
                      sx={{
                        textTransform: "none",
                        color: "#fff",
                        padding: "5px 30px",
                        background: "linear-gradient(to right, #DB4E2F, #F83A3A)",
                        borderRadius: "5px",
                        fontWeight: 600,
                        fontSize: "14px",
                      }}
                      onClick={() => {
                        enrollUser(item.courseId);
                      }}
                    >
                      {user_courses.includes(item.courseId) ? t("continue") : t("Enroll")}
                    </Button>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        sx={{
                          mr: "5px",
                          fontSize: "14px",
                          fontWeight: 600,
                          color: "#007C84",
                          margin: "0px 7px",
                        }}
                      >
                        3Hrs
                      </Typography>
                      <Box>
                        <CourseVideoWatchIcons />
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
            {loading && (
              <Box
                container
                spacing={{ xs: 1, sm: 2, md: 3 }}
                sx={{ mt: 10, alignItems: "center" }}
              >
                <div className="admin-overdue-bottom-table-cnt-c ">
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td">
                    <div className="spinner-container">
                      <div
                        style={{
                          width: "70px",
                          height: "70px",
                          border: "10px solid #f3f3f3" /* Light grey */,
                          borderTop: "10px solid #007C84" /* Black */,
                          borderRadius: "50%",
                          animation: "spinner 1.5s linear infinite",
                          marginBottom: "200px",
                          marginTop: "120px",
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </Box>
            )}
          </Box>
        </Container>
      </Box>
    </Box>
  );
}

export default ViewAllCourses;
