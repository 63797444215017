import { Typography } from "@mui/material";
import { Box, typography } from "@mui/system";
import { httpsCallable } from "firebase/functions";
import React, { useContext, useEffect, useState } from "react";
import { functions } from "../../../../../Firebase";
import { ItemContext } from "../Context/ItemContext";
import CourseProgressAccordions from "../lessionicons/CourseProgressAccordions";
import FinalQuizzes from "./FinalQuizzes";
import { useSelector, useDispatch } from "react-redux";
import { getCookie } from "../../../../../Cookies";
import { IP } from "../../../../../config";
import axios from "axios";
const CourseContent = ({ lessons, quizzes, setShowTab }) => {
  const { progressState } = useSelector((state) => state.userProgress);
  // some datastructure to store lessons and topics
  // set itemsArray simultaniously

  const { sample, courseId, progressIndex } = useContext(ItemContext);
  const getNodeProgress = httpsCallable(functions, "getNodeProgress");
  const [progress, setProgress] = useState(null);
  useEffect(() => {
    setProgress(progressState);
    let CourseInfo = {
      method: "get",
      params: { courseId: courseId },
      url: `${IP}/userItemDetails/nodeProgress`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "",
    };
    axios(CourseInfo).then((result) => {
      setProgress(result.data);
    });
  }, [progressIndex]);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          fontFamily: typography.fontFamily,
        }}
      >
        <Box
          sx={{
            background: "#fff",
            height: "auto",
            padding: "20px 20px 100px 20px",
            position: "relative",
            boxShadow: " 4px 4px 10px #0076870d, 6px 6px 40px #0076871a",
          }}
        >
          {!sample && (
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ width: "80%", marginBottom: "10px" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography sx={{ fontWeight: 600, color: "#000" }}>
                    My Progress
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 600,
                      fontFamily: "'Readex Pro', sans-serif",
                      color: "#000",
                    }}
                  >
                    {progress
                      ? Math.floor(
                          (progress.completed / progress.total) * 100
                        ).toString() + "%"
                      : "0%"}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    height: "10px",
                    borderRadius: "10px",
                    overflow: "hidden",
                    background: "#EAF3F4",
                    mt: "5px",
                  }}
                >
                  <Box
                    sx={{
                      width: progress
                        ? Math.floor(
                            (progress.completed / progress.total) * 100
                          ).toString() + "%"
                        : "0%",
                      height: "100%",
                      background: (theme) => theme.palette.primary.btn,
                      borderRadius: "10px",
                    }}
                  ></Box>
                </Box>
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 600,
                    fontFamily: "'Readex Pro', sans-serif",
                    color: "#000",
                  }}
                >
                  {/* {Number(progress.completed)}/{totalItems} */}
                </Typography>
              </Box>
            </Box>
          )}
          <Box>
            {lessons && <CourseProgressAccordions lessons={lessons} />}

            {!sample &&
              quizzes &&
              quizzes.map((quizId, index) => (
                <FinalQuizzes key={index} quizId={quizId} />
              ))}
          </Box>
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              right: 0,
            }}
          >
            <Typography
              onClick={() => setShowTab(0)}
              sx={{
                padding: "15px 20px",
                fontFamily: "Lato",
                color: "#000",
                fontWeight: 700,
              }}
            >
              Hide
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CourseContent;
