import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { Box } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import { Navigate, useNavigate } from "react-router";
// import { functions } from "../../../../Firebase";

const GenerateReport = (props) => {
  const { onClose, open, reportId } = props;
  const handleClose = () => {
    onClose();
  };
  const [dueDate, setDueDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [loading, setLoading] = useState(false);

  let navigater = useNavigate();
  const runGenerateCertificateTraining = () => {
    navigater("/admin/course-reports");
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="xs"
      PaperProps={{
        style: {
          borderRadius: "20px",
          height: "250px",
          padding: "16px",
        },
      }}
      onClose={() => {
        if (loading) return;
        handleClose();
      }}
      open={open}
    >
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          textAlign: "left",
          color: "#11047A",
        }}
      >
        <p>Report Generating </p>
        <button
          onClick={() => {
            handleClose();

            if (loading) return;
          }}
          style={{
            width: "40px",
            height: "40px",
            background: "#f4f7fe",
            marginLeft: "auto",
            borderRadius: "5px",
            border: "none",
          }}
        >
          <svg
            width="11"
            height="11"
            viewBox="0 0 11 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.43 0.244723C10.1145 -0.0707873 9.60485 -0.0707873 9.28934 0.244723L5.33333 4.19264L1.37732 0.236633C1.06181 -0.0788775 0.552143 -0.0788775 0.236633 0.236633C-0.0788775 0.552143 -0.0788775 1.06181 0.236633 1.37732L4.19264 5.33333L0.236633 9.28934C-0.0788775 9.60485 -0.0788775 10.1145 0.236633 10.43C0.552143 10.7455 1.06181 10.7455 1.37732 10.43L5.33333 6.47402L9.28934 10.43C9.60485 10.7455 10.1145 10.7455 10.43 10.43C10.7455 10.1145 10.7455 9.60485 10.43 9.28934L6.47402 5.33333L10.43 1.37732C10.7375 1.0699 10.7375 0.552143 10.43 0.244723Z"
              fill="#707EAE"
            />
          </svg>
        </button>
      </DialogTitle>
      <Box
        sx={{
          padding: "16px 24px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div>
            Your report is being generated, your report IDs, {reportId} You will
            receive an email. it will take some time as per the volume of the
            report.{" "}
          </div>
        </div>

        <Button
          fullWidth
          variant="contained"
          sx={{
            mt: "1em",
            color: "#fff",
            background: "#11047A",
            borderRadius: "70px",
          }}
          disable={loading}
          onClick={runGenerateCertificateTraining}
        >
          {loading ? "Generating..." : "Go To Reports Page"}
        </Button>
      </Box>
    </Dialog>
  );
};

export default GenerateReport;

// import React, { useState } from "react";
// import Button from "@mui/material/Button";
// import TextField from "@mui/material/TextField";
// import DialogTitle from "@mui/material/DialogTitle";
// import Dialog from "@mui/material/Dialog";
// import { Box } from "@mui/material";
// import { httpsCallable } from "firebase/functions";
// import { Navigate, useNavigate } from "react-router";
// import { functions } from "../../../Firebase";
// import "jspdf-autotable";
// import jsPDF from "jspdf";
// import goss from "./goss.png";
// import moraikh from "./moraikh.png";
// import alghat from "./alghat.png";
// import ocasco from "./ocasco.png";
// import {
//   getDownloadURL,
//   getStorage,
//   ref,
//   uploadBytes,
//   uploadString,
// } from "firebase/storage";
// const storage = getStorage();

// const GenerateReport = (props) => {
//   const { onClose, open, reportId, type, data } = props;

//   const uploadReports = httpsCallable(functions, "uploadReports");
//   const handleClose = () => {
//     onClose();
//   };
//   const [reporttype, setreportType] = useState("");
//   const [reportlink, setReportLink] = useState("");
//   const [reportData, setReportData] = useState({});

//   const [loadings, setLoadings] = useState(false);
//   const [dueDate, setDueDate] = useState(
//     new Date().toISOString().split("T")[0]
//   );
//   const [loading, setLoading] = useState(false);

//   let navigater = useNavigate();

//   const runGenerateCertificateTrainingPdf = async (type, data) => {
//     // console.log(data);const
//     const name = type;

//     const pdfName =
//       name +
//       " Report" +
//       "   Date (dd/mm/yyyy):" +
//       new Date().toLocaleDateString();
//     const pdf = new jsPDF("p", "pt", "a2");

//     const columns = Object.keys(data[0]);
//     var rows = [];
//     //   console.log({ rows });
//     data.forEach((e, index) => {
//       const createDate = new Date(e.createdAt).toLocaleDateString();
//       const updatedDate = new Date(e.updatedAt).toLocaleDateString();
//       const status = e.status ? "Active" : "InActive";
//       var temp = Object.values(e);

//       rows.push(temp);
//     });
//     pdf.text(500, 30, pdfName);
//     // var img = new Image();
//     // img.src = img;
//     // console.log(img.src);
//     pdf.addImage(goss, "png", 50, 50, 300, 100);
//     pdf.addImage(moraikh, "png", 350, 50, 250, 100);
//     pdf.addImage(ocasco, "png", 600, 50, 250, 100);
//     pdf.addImage(alghat, "png", 850, 50, 250, 100);

//     // pdf.addImage(

//     //   400,
//     //   40
//     // );
//     pdf.autoTable(columns, rows, {
//       startY: 180,
//       theme: "grid",
//       styles: {
//         font: "times",
//         halign: "center",
//         cellPadding: 3.5,
//         lineWidth: 0.5,
//         lineColor: [0, 0, 0],
//         textColor: [0, 0, 0],
//       },
//       headStyles: {
//         textColor: [0, 0, 0],
//         fontStyle: "normal",
//         lineWidth: 0.5,
//         lineColor: [0, 0, 0],
//         fillColor: [166, 204, 247],
//       },
//       rowStyles: {
//         lineWidth: 10,
//         lineColor: [0, 0, 0],
//       },
//       tableLineColor: [0, 0, 0],
//     });
//     // console.log(pdf.output("datauristring"));
//     //let rses = pdf.save(name + Date.now());

//     return pdf.output("bloburl");
//     // console.log(rows);
//     // console.log(master);
//   };
//   const runGenerateCertificateTraining = async (type) => {
//     setReportLink("");
//     setreportType(type);
//     let file = await runGenerateCertificateTrainingPdf(reportId, data);
//     // let resdata = await blobToBase64(file);

//     // console.log(resdata);
//     // function blobToBase64(blob) {
//     //   return new Promise((resolve, _) => {
//     //     const reader = new FileReader();
//     //     reader.onloadend = () => resolve(reader.result);
//     //     reader.readAsDataURL(blob);
//     //   });
//     // }

//     // Abe Oye Ise kbhi galti se bhi nhi chhuna nhi to fir apna rayta khud saf krna thik he

//     // Never ever fucking touch the blow code unless and until you have optimized version

//     fetch(file)
//       .then((res) => {
//         console.log({ res });
//         return res.blob();
//       })
//       .then((resu) => {
//         // console.log(resu);
//         let dat = new FileReader();

//         dat.onload = async () => {
//           const metaData = {
//             contentType: "application/pdf",
//           };
//           const storageRef = ref(storage, "report/" + reportId + ".pdf");
//           uploadString(storageRef, dat.result, "data_url", metaData)
//             .then(async (snep) => {
//               //  console.log(snep);
//               const downUrl = await getDownloadURL(storageRef);

//               let httpsremove = downUrl.split("//")[1];
//               setLoadings(true);
//               uploadReports({
//                 data: data,
//                 reportType: type,
//                 id: reportId,
//                 downUrl: httpsremove,
//               })
//                 .then((res) => {
//                   setReportData(res.data);
//                   setLoadings(false);
//                   // console.log(res.data);
//                   setReportLink(res.data.link);
//                 })
//                 .catch((err) => {
//                   setLoadings(false);
//                 });
//             })
//             .catch((err) => {
//               console.log(err);
//             });
//         };
//         dat.readAsDataURL(resu);
//       });

//     // console.log(file);
//     // var filetes = new File(file, "name");
//     // console.log(filetes);
//   };

//   const downloadFileFun = () => {
//     let ht = "https://";
//     if (reporttype == "pdf") {
//       window.open("https://" + reportData.downloadPDF, "_blank");
//     }
//     if (reporttype == "excel") {
//       // console.log("https://" + reportData.downloadExcel);

//       window.open("https://" + reportData.downloadExcel, "_blank");
//     }
//     if (reporttype == "csv") {
//       window.open(reportData.link, "_blank");
//     }
//     if (reporttype == "all") {
//       // console.log("tst");
//       console.log(reportData);
//       for (let i = 0; i < 3; i++) {
//         if (i == 0) {
//           window.open("https://" + reportData.downloadPDF, "_blank");
//         }
//         if (i == 1) {
//           window.open("https://" + reportData.downloadExcel, "_blank");
//         }
//         if (i == 2) {
//           window.open(reportData.link, "_blank");
//         }
//       }
//     }
//   };
//   return (
//     <Dialog
//       fullWidth={true}
//       maxWidth="xs"
//       PaperProps={{
//         style: {
//           borderRadius: "20px",
//           height: "350px",
//           width: "500px",
//           padding: "16px",
//         },
//       }}
//       onClose={() => {
//         if (loading) return;
//         handleClose();
//       }}
//       open={open}
//     >
//       <DialogTitle
//         sx={{
//           display: "flex",
//           flexDirection: "row",
//           alignItems: "center",
//           textAlign: "left",
//           color: "#11047A",
//         }}
//       >
//         <p>Report Generate </p>
//         <button
//           onClick={() => {
//             onClose();
//             if (loading) return;
//           }}
//           style={{
//             width: "40px",
//             height: "40px",
//             background: "#f4f7fe",
//             marginLeft: "auto",
//             borderRadius: "5px",
//             border: "none",
//           }}
//         >
//           <svg
//             width="11"
//             height="11"
//             viewBox="0 0 11 11"
//             fill="none"
//             xmlns="http://www.w3.org/2000/svg"
//           >
//             <path
//               d="M10.43 0.244723C10.1145 -0.0707873 9.60485 -0.0707873 9.28934 0.244723L5.33333 4.19264L1.37732 0.236633C1.06181 -0.0788775 0.552143 -0.0788775 0.236633 0.236633C-0.0788775 0.552143 -0.0788775 1.06181 0.236633 1.37732L4.19264 5.33333L0.236633 9.28934C-0.0788775 9.60485 -0.0788775 10.1145 0.236633 10.43C0.552143 10.7455 1.06181 10.7455 1.37732 10.43L5.33333 6.47402L9.28934 10.43C9.60485 10.7455 10.1145 10.7455 10.43 10.43C10.7455 10.1145 10.7455 9.60485 10.43 9.28934L6.47402 5.33333L10.43 1.37732C10.7375 1.0699 10.7375 0.552143 10.43 0.244723Z"
//               fill="#707EAE"
//             />
//           </svg>
//         </button>
//       </DialogTitle>
//       <Box
//         sx={{
//           padding: "16px 24px",
//         }}
//       >
//         <div
//           style={{
//             display: "flex",
//             alignItems: "center",
//           }}
//         >
//           <div>
//             You will receive an email. it will take some time as per the volume
//             of the report. <br></br> Select a report type{" "}
//           </div>
//         </div>

//         <div style={{ marginTop: "20px" }}> Report Types</div>
//         <div style={{ display: "flex", gap: "20px" }}>
//           {" "}
//           <Button
//             fullWidth
//             variant="contained"
//             sx={{
//               mt: "1em",
//               color: "#fff",
//               background: "#11047A",
//               borderRadius: "70px",
//             }}
//             onClick={() => {
//               runGenerateCertificateTraining("csv");
//             }}
//           >
//             CSV
//           </Button>
//           <Button
//             fullWidth
//             variant="contained"
//             sx={{
//               mt: "1em",
//               color: "#fff",
//               background: "#11047A",
//               borderRadius: "70px",
//             }}
//             onClick={() => {
//               runGenerateCertificateTraining("excel");
//             }}
//           >
//             EXCEL
//           </Button>
//           <Button
//             fullWidth
//             variant="contained"
//             sx={{
//               mt: "1em",
//               color: "#fff",
//               background: "#11047A",
//               borderRadius: "70px",
//             }}
//             onClick={() => {
//               runGenerateCertificateTraining("pdf");
//             }}
//           >
//             PDF
//           </Button>
//           <Button
//             fullWidth
//             variant="contained"
//             sx={{
//               mt: "1em",
//               color: "#fff",
//               background: "#11047A",
//               borderRadius: "70px",
//             }}
//             onClick={() => {
//               runGenerateCertificateTraining("all");
//             }}
//           >
//             ALL
//           </Button>
//         </div>

//         {loadings ? (
//           <Button
//             fullWidth
//             variant="contained"
//             sx={{
//               mt: "1em",
//               color: "#fff",
//               background: reportlink ? "#11047A" : "#110444",
//               borderRadius: "70px",
//             }}
//             disable={!reportlink}
//             onClick={downloadFileFun}
//           >
//             Loading...
//           </Button>
//         ) : (
//           <Button
//             fullWidth
//             variant="contained"
//             sx={{
//               mt: "1em",
//               color: "#fff",
//               background: reportlink ? "#11047A" : "#110444",
//               borderRadius: "70px",
//             }}
//             disable={!reportlink}
//             onClick={downloadFileFun}
//           >
//             {reportlink ? "Download " : "select type"}
//           </Button>
//         )}
//       </Box>
//     </Dialog>
//   );
// };

// export default GenerateReport;
