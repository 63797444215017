import { Box } from "@mui/system";
import React, { useContext } from "react";
import { ItemContext } from "../Context/ItemContext";
import LessonTimeline from "./LessonTimeline";

const CourseProgressAccordions = ({ lessons }) => {
  const { sample } = useContext(ItemContext);
  const sampleLesson = lessons.filter((ele) => ele == sample);
  return (
    <>
      {sample ? (
        <Box>
          {sampleLesson.map((lessonId, index) => (
            <LessonTimeline key={index} first={index === 0} lessonId={lessonId} />
          ))}
        </Box>
      ) : (
        <Box>
          {lessons.map((lessonId, index) => (
            <LessonTimeline key={index} first={index === 0} lessonId={lessonId} />
          ))}
        </Box>
      )}
    </>
  );
};

export default CourseProgressAccordions;
