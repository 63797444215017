import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { makeStyles } from "@mui/styles";
import * as React from "react";
import { flexBetweenWithItemsCenter } from "../../../utilities/commonStyles/commonStyles";
import { MenuIcons } from "../../../utilities/Icons/Icons";
import ResponsiveMenu from "./ResponsiveMenu";

const useStyles = makeStyles({
  drawerPaper: {
    borderRadius: "0px 20px 20px 0",
  },
});

export default function ResponsiveSidebar({ color }) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 300 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <ResponsiveMenu />
    </Box>
  );

  return (
    <Box sx={{ backgroundColor: color === "green" ? "#25C027" : null }}>
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Box
            sx={{
              ...flexBetweenWithItemsCenter,
            }}
          >
            <Box onClick={toggleDrawer(anchor, true)}>
              <MenuIcons />
            </Box>
          </Box>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
            classes={{ paper: classes.drawerPaper }}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </Box>
  );
}
