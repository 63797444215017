import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import {
  AccordionDownArrowIcons,
  AccordionUpArrowIcons,
  ChildAccodionTitleIcons,
} from "../../../utilities/Icons/Icons";

import { httpsCallable } from "firebase/functions";
import { functions } from "../../../Firebase";
import { IP } from "../../../config";
import { getCookie } from "../../../Cookies";
import axios from "axios";

const ChildAccordian = ({ topicId }) => {
  const [childAccordion, setChildAccordion] = useState(null);
  const getTopicInfo = httpsCallable(functions, "getTopicInfo");

  const [topicInfo, setTopicInfo] = useState({
    topicId: "GoodIdealTopic_1654855923542",
    topicName: "Good Ideal Topic",
    topicDescription: "Good Ideal Topic",
    lessonAssociated: "IdealLesson1_1654855679480",
    created: {
      _seconds: 1654855927,
      _nanoseconds: 0,
    },
    extraInfo: {
      hasQuiz: true,
      sample: true,
      schedule: "immediate",
      start: 0,
      quizzes: [],
      items: [],
    },
  });

  useEffect(() => {
    let CourseInfo = {
      method: "get",
      params: { topicId: topicId },
      url: `${IP}/userCourseDetails/topic`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "",
    };
    axios(CourseInfo).then((result) => setTopicInfo(result.data));
  }, []);

  return (
    <Box sx={{ margin: "2px 0px 0px 6px" }}>
      <Box
        onClick={() => setChildAccordion(!childAccordion)}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", gap: "15px" }}>
          <Box>
            <ChildAccodionTitleIcons />
          </Box>
          <Typography
            sx={{
              color: "#000",
              fontSize: { xs: "12pz", md: "18px" },
              fontWeight: 500,
            }}
          >
            {topicInfo.topicName}
          </Typography>
        </Box>
        <Box>
          <button
            style={{
              background: "transparent",
              border: "none",
              outline: "none",
            }}
            onClick={() => setChildAccordion(!childAccordion)}
          >
            {childAccordion ? (
              <AccordionDownArrowIcons />
            ) : (
              <AccordionUpArrowIcons />
            )}
          </button>
        </Box>
      </Box>

      {childAccordion && (
        <Box sx={{ ml: "40px", pb: "25px" }}>
          <Box>
            <Typography
              sx={{ color: "#000", opacity: "0.5", fontSize: "14px" }}
            >
              {topicInfo.topicDescription}
            </Typography>

            <Box sx={{ mt: "10px", display: "flex", gap: "10px" }}>
              <Typography
                sx={{ fontSize: "10px", fontWeight: 500, color: "#000" }}
              >
                {topicInfo.extraInfo.items?.length} Items
              </Typography>
              <Typography
                sx={{ fontSize: "10px", fontWeight: 500, color: "#000" }}
              >
                {topicInfo.extraInfo.quizzes?.length} Quiz
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ChildAccordian;
