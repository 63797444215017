import { Box } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import React, { useContext, useEffect, useState } from "react";
import { functions } from "../../../../../Firebase";
import {
  FullLeftArrowIcons,
  FullRightArrowIcons,
} from "../../../../../utilities/Icons/Icons";
import { ItemContext } from "../Context/ItemContext";
import "./courseItem.css";
import HtmlItem from "./HtmlItem";
import Node from "./Node";
import { PdfContainer } from "./PdfContainer";
import QuizContainer from "./QuizContainer";
import VideoPlayer from "./VideoPlayer";
import { IP } from "../../../../../config";
import { getCookie } from "../../../../../Cookies";
import axios from "axios";

export const CourseItem = () => {
  const [itemInfo, setItemInfo] = useState(null);
  console.log("CourseItem");

  const getItemInfo = httpsCallable(functions, "getItemInfo");
  const {
    itemsArray,
    progressIndex,
    setProgressIndex,
    isLinear,
    courseStatus,
    certificateInfo,
  } = useContext(ItemContext);
  const [canNavigate, setCanNavigate] = useState(!isLinear);
  const mainItem = itemsArray[progressIndex];
  const checkForLast = () => {
    let finalIdx = 0;

    for (let i = itemsArray.length - 1; i >= 0; i--) {
      const val = itemsArray[i];
      if (val.type === "quiz") {
        finalIdx = i;
        break;
      } else if (val.type === "item") {
        finalIdx = i;
        break;
      } else if (val.type === "node" && val.nodeType === "topic") {
        finalIdx = i;
        break;
      }
    }
    return finalIdx;
  };

  //console.log(mainItem)
  useEffect(() => {
    // if(courseStatus){
    //   setProgressIndex((index) => index+itemsArray.length+1)
    // }
    if (mainItem?.type == "head") setProgressIndex((index) => index + 1);
    //console.log("Item Loading", mainItem?.id);
    setItemInfo(null);
    if (mainItem?.type === "item") {
      // getItemInfo({ itemId: mainItem.id })

      let CourseInfo = {
        method: "get",
        params: { itemId: mainItem.id },
        url: `${IP}/userCourseDetails/item`,

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: "",
      };
      axios(CourseInfo).then((result) => {
        setItemInfo(result.data);
        //console.log(result.data);
      });
    }
  }, [progressIndex]);

  const changeProgressIndex = (index) => {
    if (index > progressIndex && !canNavigate) {
      alert(
        index > progressIndex
          ? "complete the current item to goto next item"
          : "You have completed the course."
      );
      return;
    }
    if (index < 0) return;
    else if (index >= itemsArray.length) return;
    //console.log(index);
    setProgressIndex(index);
  };

  //console.log(progressIndex,itemsArray.length)
  return (
    <Box>
      {/* dont remove below comments */}

      {mainItem?.type === "item" && itemInfo?.itemType == "pdf" && (
        <PdfContainer
          setCanNavigate={setCanNavigate}
          itemId={mainItem.id}
          pdfURL={itemInfo.pdfURL}
        />
      )}

      {mainItem?.type === "item" && itemInfo?.itemType == "video" && (
        <VideoPlayer
          setCanNavigate={setCanNavigate}
          itemId={mainItem.id}
          videoURL={itemInfo.videoURL}
          allow="autoplay; encrypted-media"
          allowfullscreen
          title="video"
        />
      )}

      {mainItem?.type === "item" && itemInfo?.itemType == "html" && (
        <HtmlItem
          setCanNavigate={setCanNavigate}
          itemId={mainItem.id}
          url={itemInfo.htmlURL}
        />
      )}

      {/* {mainItem?.type === "quiz" && <QuizItem />} */}

      {mainItem?.type === "quiz" && <QuizContainer quizId={mainItem.id} />}

      {mainItem?.type == "node" && (
        <Node
          node={mainItem}
          courseStatus={courseStatus}
          certificateInfo={certificateInfo}
          setCanNavigate={setCanNavigate}
          itemsArray={itemsArray}
        />
      )}

      {mainItem?.type != "quiz" && progressIndex < checkForLast() && (
        <Box
          sx={{
            width: "100%",
            height: "160px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            background: "#fff",
          }}
        >
          <Box
            sx={{
              width: "100%",
              padding: "0px 25px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box onClick={() => changeProgressIndex(progressIndex - 1)}>
              <FullLeftArrowIcons />
            </Box>

            {progressIndex + 1 < itemsArray.length && (
              <Box onClick={() => changeProgressIndex(progressIndex + 1)}>
                <FullRightArrowIcons />
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};
