import { Button, Container, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getCookie } from "../../../Cookies";
import {
  responsiveReverseFlexDirection,
  responsiveWidth,
} from "../../../utilities/commonStyles/commonStyles";
import loginImg from "../../../utilities/images/algaith.png";
import classes from "../../styles/LoginpageStyle/LoginPage.module.css";
import loginLogo from "../img/logo/logo.png";
import { IP } from "../../../config";
import axios from "axios";

function ForgotPassword() {
  const navigate = useNavigate();
  const goToHome = () => navigate("/");
  const goToUserDashboard = () => navigate("/user/home");
  const goToAdminDashboard = () => navigate("/admin");
  const goToInstructorDashboard = () => navigate("/instructor");
  const [linkSent, setLinkSet] = useState(false);
  const [email, setEmail] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [userNameError, setUserNameError] = useState("");

  const [notification, setNotification] = useState("");
  const [loading, setLoading] = useState(false);

  ///========visibility password=======//

  const clearErrors = () => {
    setUserNameError("");
    setPasswordError("");
  };

  ///if user is logged in, redirect to user dashboard
  useEffect(() => {
    if (getCookie("ROLE") === "admin") {
      goToAdminDashboard();
      return;
    }
    if (getCookie("ROLE") === "instructor") {
      goToInstructorDashboard();
      return;
    }
    if (getCookie("ROLE") === "user") {
      goToUserDashboard();
      return;
    }
  }, []);

  const handleForgot = () => {
    clearErrors();
    if (email == "") return setNotification("Please enter your username");
    setLoading(true);
    // const auth = getAuth();

    // sendPasswordResetEmail(auth, email)
    let configProfile = {
      method: "post",
      // params: { userId: uid },
      url: `${IP}/auth/forgotPassword`,
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${user.idToken}`,
      },
      data: JSON.stringify({
        email: email,
      }),
    };

    axios(configProfile)
      .then((userCredential) => {
        alert("Reset Link Sent");
        setLoading(false);
        setLinkSet(true);
      })
      .catch((err) => {
        setLoading(false);
        // console.log("error", err.response.data?.error);
        if (err.code == "auth/user-not-found")
          return setNotification("Email not found");
        else if (err.code == "auth/too-many-requests")
          return setNotification("Too many requests. Please try again later");
        switch (err.code) {
          case "auth/invalid-username":
          case "auth/user-disabled":
          case "auth/user-not-found":
            setUserNameError(err.message);
            break;
          case "auth/wrong-password":
            setPasswordError(err.message);
            break;
        }
        return setNotification(err.response.data?.error);
      });
  };

  return (
    <Box sx={{ py: { xs: "20px", md: "0" } }}>
      <Container
        sx={{
          ...responsiveReverseFlexDirection,
          height: { md: "100vh" },
          gap: "50px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            ...responsiveWidth,
            border: "3px solid #007848",
            borderRadius: "10px",
            minHeight: { md: "610px" },
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <img className={classes.logo} src={loginLogo} alt="" />
          </Box>
          {!linkSent ? (
            <Box sx={{ padding: { xs: "25px", md: "50px" } }}>
              <Box>
                <Typography
                  sx={{
                    color: (theme) => theme.palette.primary.main,
                    fontWeight: 700,
                    marginTop: "-20px",
                    fontSize: { xs: "24px", md: "48px" },
                  }}
                >
                  Forgot Password
                </Typography>
                <Typography
                  sx={{
                    my: 2,
                    color: (theme) => theme.palette.primary.btn,
                    fontSize: { xs: "10px", md: "18px" },
                  }}
                >
                  Provide Email to send Password reset link
                </Typography>
              </Box>
              <form action="">
                <Box>
                  <Typography
                    sx={{
                      fontWeight: "600",
                      mb: 1,
                      color: (theme) => theme.palette.primary.main,
                      fontSize: { xs: "12px", md: "16px" },
                    }}
                  >
                    Enter Your Email
                  </Typography>
                  <div
                    style={{
                      width: "100%",
                      borderRadius: "6px",
                      padding: "10px 15px",
                      border: "1px solid #007848",
                      outline: "none",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="text"
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="name@domain.com"
                      style={{
                        width: "100%",
                        padding: "7px",
                        border: "none",
                        outline: "none",
                      }}
                    />
                  </div>
                </Box>
                <Box sx={{ color: "red", marginTop: "10px" }}>
                  {notification && <span>{notification}</span>}
                </Box>
                <Box sx={{ mt: 3 }}>
                  <Button
                    onClick={handleForgot}
                    sx={{
                      textTransform: "none",
                      width: "100%",
                      py: 1,
                      borderRadius: "15px",
                      fontSize: "16px",
                      fontWeight: 500,
                      background: (theme) => theme.palette.primary.main,
                      color: "#fff",
                      "&:hover": {
                        background: (theme) => theme.palette.primary.main,
                      },
                    }}
                  >
                    {loading ? "Sending ..." : "Send Reset Link"}
                  </Button>
                </Box>
                <Box sx={{ mt: 3 }}>
                  <Link to="/">
                    <Typography
                      sx={{
                        textAlign: "center",
                        color: (theme) => theme.palette.secondary.main,
                        fontWeight: 600,
                        fontSize: { xs: "12px", md: "14px" },
                      }}
                    >
                      Goto Login
                    </Typography>
                  </Link>
                </Box>
              </form>
            </Box>
          ) : (
            <Box sx={{ p: 7 }}>
              <Box>
                <Typography
                  variant="h3"
                  sx={{ color: "#007848", fontWeight: 700 }}
                >
                  Password Reset Link sent
                </Typography>
                <Typography sx={{ my: 2, color: "#007848" }}>
                  Please check your inbox and spam folder for the link.
                </Typography>
              </Box>
              <Box sx={{ mt: 3 }}>
                <Link to="/">
                  <Typography
                    style={{ color: "#007848", fontWeight: 600 }}
                    sx={{ textAlign: "center" }}
                  >
                    Goto Login
                  </Typography>
                </Link>
              </Box>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            ...responsiveWidth,
            borderRadius: "10px",
            minHeight: "610px",
            maxHeight: "610px",
          }}
        >
          <Box style={{ maxHeight: "610px" }}>
            <img
              width="100%"
              height="605px"
              style={{ borderRadius: "10px" }}
              src={loginImg}
              alt=""
            />{" "}
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default ForgotPassword;
