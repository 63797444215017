import { Button, Container, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { httpsCallable } from "firebase/functions";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { getCookie } from "../../../Cookies";
import { functions } from "../../../Firebase";
import {
  displayOnDesktop,
  displayOnMobile,
} from "../../../utilities/commonStyles/commonStyles";
import {
  CourseVideoIcons,
  CourseVideoWatchIcons,
  SearchIcons,
} from "../../../utilities/Icons/Icons";
import "../../styles/CourseLibrary/CourseLibraryMain.css";
import "../../Translater/Translater";
import Navbar from "../Navbar/Navbar";
import ResponsiveNavbar from "../Navbar/ResponsiveNavbar";
import classes from "./CourseLibrary.module.css";
import { IP } from "../../../config";
import axios from "axios";
import LoadingScreen from "../../../Admin/AdminComponents/Builder/loading";

function CourseLibraryMain() {
  const navigate = useNavigate();
  const goToHome = () => navigate("/");
  const courseLibrary = httpsCallable(functions, "courseLibrary");
  const { t } = useTranslation();
  const { clrs } = useSelector((state) => state.createClr);

  const uid = getCookie("UID");

  const [courses, setCourses] = useState([]);
  const [certificates, setCertificates] = useState(null);
  const [searchCoursess, setSearchCourses] = useState([]);
  const [filterdCourse, setFilterdCourse] = useState({
    label: "",
    courseName: "",
  });
  const [loading, setLoading] = useState(false);
  const [loadingButt, setLoadingButt] = useState(false);

  const [viewAll, setViewAll] = useState(false);

  const [courseListForSearch, setCourseListForSearch] = useState([]);
  var list = [];

  async function fetchCourses(type, courseName) {
    setLoading(true);
    setCourses([]);
    setSearchCourses([]);
    // const res = await courseLibrary({});
    let query = {};
    if (type) {
      query["all"] = true;
    }
    if (courseName) {
      query["courseName"] = courseName;
    }
    let courseLibrary = {
      method: "get",
      params: query,
      url: `${IP}/userCourse/courseLibrary`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "",
    };
    const res = await axios(courseLibrary);
    setCourses(res.data.data.filter((e) => e.archived != true));

    //console.log(res.data.filter((e)=>e.archived!=true).slice(0, 6));
    setSearchCourses(res.data.data.filter((e) => e.archived != true));
    setLoading(false);
  }

  const getALlCourseList = async () => {
    let courseLibrary = {
      method: "get",
      // params: {""},
      url: `${IP}/common/course/${"course"}`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "",
    };
    axios(courseLibrary).then((res) => {
      let arr = [];
      res.data.map((el) => {
        arr.push({
          label: el.courseName,
          courseName: el.courseName,
        });
      });
      setCourseListForSearch(arr);
    });
  };
  useEffect(() => {
    if (viewAll) {
      setSearchCourses(courses);
    }
  }, [viewAll]);

  console.log(courseListForSearch);
  useEffect(() => {
    if (getCookie("UID") == null || getCookie("ROLE") != "user") {
      goToHome();
      return <></>;
    }
    getALlCourseList();
    fetchCourses();
  }, []);

  //==========get data of course to know which course is allready enrolled==========//
  const fetch_user_courses = httpsCallable(functions, "fetch_user_courses");
  const fetch_certificates = httpsCallable(functions, "fetch_certificates");
  const getItemsArray = httpsCallable(functions, "getItemsArray");
  const resetItemProgress = httpsCallable(functions, "resetItemProgress");
  //resetItemProgress

  /////======= fetch user courses
  const [user_courses, setUserCourses] = useState([]);

  useEffect(() => {
    let certificates = {
      method: "get",
      // params: { select: 3 },
      url: `${IP}/userCourse/certificates`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "",
    };
    axios(certificates).then((res) => {
      if (res.data.error) {
        return;
      }
      setCertificates(res.data.filter((ele) => ele.trainingType == "Internal"));
    });
    let InternalTraining = {
      method: "get",
      params: { select: 1 },
      url: `${IP}/userCourse`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "",
    };
    setLoadingButt(true);
    axios(InternalTraining)
      .then((res) => {
        // //console.log(res.data);
        setLoadingButt(false);

        if (res.data.error) {
          return;
        }
        // setUserCourses([...res.data]);
        // const temp = [];
        // res.data.forEach((course) => {
        //   temp.push(course.courseId);
        // });

        setUserCourses(res.data);
      })
      .catch((err) => {
        //console.log(err);
        setLoadingButt(false);
      });
  }, []);
  // //console.log(user_courses);

  const searchCourse = (e, value) => {
    // console.log(value);
    if (!value) {
      fetchCourses();
      setFilterdCourse({
        label: "",
        courseName: "",
      });
      setViewAll(false);
      return;
    }
    setFilterdCourse(value);
    // const search = e.target.value;
    // const filteredCourses = courses.filter((course) => {
    //   return course.courseName?.toLowerCase().includes(search.toLowerCase());
    // });

    fetchCourses("", value?.courseName);
    // setSearchCourses(filteredCourses);
  };

  ////===================endroll here========================//
  const userEnrollment = httpsCallable(functions, "userEnrollment");
  //check single course to direct enroll
  const enrollUser = (courseId, access, status) => {
    if (status == "Closed") {
      alert("This Course is Closed For You, please contact your admin!");
      return;
    }
    // console.log(access);
    if (access != "free") {
      alert("This is a Closed Course, please contact your admin!");
      return;
    }
    // alert(status);
    if (status != "Enroll") {
      navigate(
        `/user/course-library/single-course-overview/single-course-content/${courseId}`
      );
      return;
    }
    // userEnrollment

    let InternalTraining = {
      method: "post",
      // params: { select: 1 },
      url: `${IP}/userEnrollment`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        courseId: courseId,
        currentDate: new Date(),
      }),
    };
    axios(InternalTraining)
      // userEnrollment({ courseId: courseId, currentDate: new Date() })
      .then((res) => {
        //console.log(res.data);
      })
      .catch((err) => {
        //console.log(err);
      });
    navigate(
      `/user/course-library/single-course-overview/single-course-content/${courseId}`
    );
  };

  const renewCourse = (courseId) => {
    // get items array and reset it then goto course

    let ItemsArray = {
      method: "get",
      params: { select: 1, courseId: courseId },
      url: `${IP}/userItemDetails`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        courseId: courseId,
      }),
    };
    axios(ItemsArray).then((res) => {
      //console.log(res.data);

      let ItemsArray = {
        method: "post",
        params: { select: 1 },
        url: `${IP}/userItemDetails`,

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: JSON.stringify({
          courseId: courseId,
          itemArray: res.data,
        }),
      };
      axios(ItemsArray).then((res) => {
        navigate(
          `/user/course-library/single-course-overview/single-course-content/${courseId}`
        );
      });
      // resetItemProgress({ courseId: courseId, itemArray: res.data });
    });
    // navigate(
    //   `/user/course-library/single-course-overview/single-course-content/${courseId}`
    // );
  };

  return (
    <Box>
      <Box sx={displayOnDesktop}>
        <Navbar page={t("CourseLibrary")} />
      </Box>
      <Box sx={displayOnMobile}>
        {/* <LoadingScreen open={true} /> */}
        <ResponsiveNavbar page={t("CourseLibrary")} />
      </Box>
      <Box>
        <Container sx={{ my: 4 }}>
          <Box className={classes.searchBox} sx={{ background: "#fff", p: 2 }}>
            <Box
              sx={{
                mb: 4,
                display: "flex",
                justifyContent: { md: "flex-end", xs: "center" },
              }}
            >
              <Box
                sx={{
                  width: "300px",
                  display: "flex",
                  alignItems: "center",
                  gap: "15px",
                  background:
                    "linear-gradient(to right, #00768714, #0076870F, #00768705)",
                  borderRadius: "10px",
                  padding: "10px 15px",
                }}
              >
                {/* <SearchIcons /> */}
                {/* <input
                  className={classes.input}
                  type="text"
                  placeholder={t("SearchCourse")}
                /> */}

                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  value={filterdCourse}
                  onChange={searchCourse}
                  options={courseListForSearch}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Search Course" />
                  )}
                />
              </Box>
            </Box>

            {loading && (
              <Box
                container
                spacing={{ xs: 1, sm: 2, md: 3 }}
                sx={{ mt: 10, alignItems: "center" }}
              >
                <div className="admin-overdue-bottom-table-cnt-c ">
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td">
                    <div className="spinner-container">
                      <div
                        style={{
                          width: "70px",
                          height: "70px",
                          border: "10px solid #f3f3f3" /* Light grey */,
                          borderTop: "10px solid #000" /* Black */,
                          borderRadius: "50%",
                          animation: "spinner 1.5s linear infinite",
                          marginBottom: "200px",
                          marginTop: "120px",
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </Box>
            )}
            <Grid container spacing={{ xs: 5, md: 3 }} sx={{ mt: "200px" }}>
              {searchCoursess.map((item, index) => {
                let status = (() => {
                  let courseRef = user_courses.find(
                    (ele) => ele.courseId == item.courseId
                  );
                  if (courseRef) {
                    if (courseRef.archived == true) return "Closed";
                  }

                  if (!courseRef) {
                    if (item.access != "free") return "Closed";
                    // if (
                    //   item.courseId ==
                    //   "ADNOCLearningfromIncidents2009to2023_1696246073050"
                    // ) {
                    //   alert(`enroll  ==${new Date().getTime()}`);
                    // }
                    return "Enroll";
                  }
                  let certificateRef = certificates?.find(
                    (ele) => ele.courseId == item.courseId
                  );
                  //console.log(certificateRef, certificates);
                  if (certificateRef) {
                    let isValid = Boolean(
                      Math.floor(new Date().getTime() / 1000) <
                        certificateRef.expiry._seconds
                    );
                    //console.log(isValid, certificateRef.certificateName);
                    // if (
                    //   item.courseId ==
                    //   "ADNOCLearningfromIncidents2009to2023_1696246073050"
                    // ) {
                    //   alert(`com  ==${new Date().getTime()}`);
                    // }
                    if (isValid) return "Completed";
                    if (
                      courseRef.progress.completed != courseRef.progress.total
                    )
                      return "Continue";
                    else return "Renew";
                  } else if (courseRef.progress.completed == "0")
                    return "Start";
                  if (courseRef) {
                    if (
                      new Date(courseRef?.dueDate?._seconds * 1000) < new Date()
                    )
                      return "overDue";
                  } else return "Continue";
                  return "Continue";
                })();
                //console.log(item.courseName, status);
                return (
                  <Grid key={index} item xs={12} md={4}>
                    <Link to="single-course-overview" state={item}>
                      <Box
                        className="bgImage"
                        sx={
                          item.courseImageURL
                            ? { backgroundImage: `url(${item.courseImageURL})` }
                            : {}
                        }
                      >
                        <CourseVideoIcons />
                      </Box>
                    </Link>
                    <Box>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: 600,
                          color: "#000",
                          margin: "7px 0px",
                        }}
                      >
                        {item.courseName}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontFamily: "'Readex Pro', sans-serif",
                          color: "#000",
                        }}
                      >
                        {item.courseDescription}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        mt: 1,
                      }}
                    >
                      <Button
                        disabled={loadingButt}
                        sx={{
                          textTransform: "none",
                          color: "#fff",
                          padding: "5px 30px",

                          background: (theme) => theme.palette.primary.btn,
                          borderRadius: "5px",
                          fontWeight: 600,
                          fontSize: "14px",
                          "&:hover": {
                            background: (theme) => theme.palette.primary.btn,
                          },
                        }}
                        onClick={() => {
                          if (status == "Renew") {
                            renewCourse(item.courseId);
                          } else {
                            // console.log(item);
                            enrollUser(item.courseId, item.access, status);
                          }
                        }}
                      >
                        {/* {user_courses.includes(item.courseId) ? t("continue") : t("Enroll")} */}

                        {loadingButt ? "Loading..." : t(status)}
                      </Button>
                      <Button
                        sx={{
                          textTransform: "none",
                          color: "#fff",
                          padding: "5px 30px",
                          background: (theme) => theme.palette.primary.btn,
                          borderRadius: "5px",
                          fontWeight: 600,
                          fontSize: "14px",
                          "&:hover": {
                            background: (theme) => theme.palette.primary.btn,
                          },
                        }}
                        onClick={() => {
                          navigate("single-course-overview", {
                            state: { ...item },
                          });
                        }}
                      >
                        {/* {user_courses.includes(item.courseId) ? t("continue") : t("Enroll")} */}
                        View
                      </Button>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          sx={{
                            mr: "5px",
                            fontSize: "14px",
                            fontWeight: 600,
                            color: "#007C84",
                            margin: "0px 7px",
                          }}
                        >
                          {item.courseDuration}Hr{" "}
                          {item.courseDurationInminute
                            ? item.courseDurationInminute
                            : 0}{" "}
                          Min
                        </Typography>
                        <Box>
                          <CourseVideoWatchIcons />
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>

            {!loading && !viewAll && (
              <Box sx={{ textAlign: "center", mt: "50px" }}>
                <Box
                  onClick={() => {
                    setViewAll(true);

                    fetchCourses(true);
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "#007C84",
                      fontWeight: 600,
                      textDecorationLine: "underline",
                    }}
                  >
                    {t("View All")}
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        </Container>
      </Box>
    </Box>
  );
}

export default CourseLibraryMain;

{
  /*

<div className="course-main">
        <Sidebar page={"courselibrary"} />
        <div className="course-main-cnt">
          <div className="course-main-cnt-search">
            <form>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.125 17.2411L13.405 12.5211C14.5393 11.1595 15.1049 9.41291 14.9842 7.64483C14.8635 5.87675 14.0658 4.22326 12.757 3.02834C11.4483 1.83341 9.72922 1.18906 7.95748 1.22932C6.18574 1.26958 4.49773 1.99134 3.2446 3.24448C1.99147 4.49761 1.2697 6.18562 1.22944 7.95736C1.18918 9.7291 1.83354 11.4482 3.02846 12.7569C4.22338 14.0657 5.87687 14.8634 7.64495 14.9841C9.41303 15.1047 11.1596 14.5391 12.5213 13.4049L17.2413 18.1249L18.125 17.2411ZM2.50001 8.12489C2.50001 7.01237 2.82991 5.92483 3.44799 4.99981C4.06608 4.07478 4.94458 3.35381 5.97242 2.92807C7.00025 2.50232 8.13125 2.39093 9.22239 2.60797C10.3135 2.82501 11.3158 3.36074 12.1025 4.14741C12.8892 4.93408 13.4249 5.93636 13.6419 7.02751C13.859 8.11865 13.7476 9.24965 13.3218 10.2775C12.8961 11.3053 12.1751 12.1838 11.2501 12.8019C10.3251 13.42 9.23753 13.7499 8.12501 13.7499C6.63368 13.7482 5.2039 13.1551 4.14936 12.1005C3.09483 11.046 2.50167 9.61622 2.50001 8.12489Z"
                  fill="#D7D7D7"
                />
              </svg>

              <input type="text" placeholder={t("SearchCourse")} />
            </form>
          </div>
          <div className="course-main-cnt-ceter">
            <div className="course-main-cnt-center-grid">
              {vidData.map((item, id) => {
                return (
                  <div className="course-main-cnt-center-grid-item" key={id}>
                    <div className="course-main-cnt-center-grid-item-vid">
                      {" "}
                      <img src={item.img} alt="loading" />
                    </div>
                    <div className="course-main-cnt-center-grid-item-desc">
                      <div className="course-main-cnt-center-grid-item-desc-item-one">
                        <div className="course-main-cnt-center-grid-item-desc-item-one-name">
                          {item.name}
                        </div>
                        <div className="course-main-cnt-center-grid-item-desc-item-one-progress">
                          <svg
                            width="15"
                            height="15"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.99999 0.399902C7.45392 0.399902 5.01212 1.41133 3.21177 3.21168C1.41142 5.01203 0.399994 7.45382 0.399994 9.9999C0.40006 11.2606 0.648436 12.5089 1.13094 13.6736C1.61345 14.8383 2.32063 15.8966 3.21212 16.788C4.10361 17.6794 5.16194 18.3865 6.32669 18.8688C7.49145 19.3512 8.7398 19.5995 10.0005 19.5994C11.2612 19.5993 12.5095 19.351 13.6742 18.8685C14.8389 18.386 15.8972 17.6788 16.7886 16.7873C17.68 15.8958 18.387 14.8375 18.8694 13.6727C19.3518 12.508 19.6001 11.2596 19.6 9.9989C19.6 4.6979 15.301 0.399902 9.99999 0.399902ZM9.99999 17.5989C9.00195 17.5989 8.01367 17.4023 7.0916 17.0204C6.16953 16.6385 5.33171 16.0786 4.62598 15.3729C3.92026 14.6672 3.36045 13.8294 2.97851 12.9073C2.59657 11.9852 2.39999 10.9969 2.39999 9.9989C2.39999 9.00086 2.59657 8.01258 2.97851 7.09051C3.36045 6.16843 3.92026 5.33062 4.62598 4.62489C5.33171 3.91917 6.16953 3.35935 7.0916 2.97742C8.01367 2.59548 9.00195 2.3989 9.99999 2.3989V9.9999L16.792 6.6039C17.323 7.65722 17.5997 8.82032 17.6 9.9999C17.5997 12.0154 16.7989 13.9482 15.3737 15.3733C13.9484 16.7983 12.0155 17.5989 9.99999 17.5989Z"
                              fill="#2C343E"
                            />
                          </svg>
                        </div>
                      </div>
                      <div className="course-main-cnt-center-grid-item-desc-item-two">
                        <div className="course-main-cnt-center-grid-item-desc-item-two-companyname">
                          {item.company}
                        </div>
                        <div className="course-main-cnt-center-grid-item-desc-item-two-hrs">
                          {item.hours}
                        </div>
                      </div>
                    </div>
                    <div className="course-main-cnt-center-grid-item-btn">
                      <Link to={`/user/courselibrary/singlecourse/${item.courseId}`}>
                        <button
                          style={{
                            backgroundColor: `${
                              clrs.EnrolBtnClr ? clrs.EnrolBtnClr : "#41B6E6"
                            }`,
                          }}
                        >
                          {t("View")}
                        </button>
                      </Link>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="course-main-cnt-viewall">
            <Link to={"/user/courselibrary/viewall"}>{t("viewAll")}</Link>
          </div>
        </div>
      </div>

*/
}
