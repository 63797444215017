// export const userScore = (data) => async (dispatch, getState) => {
//   dispatch({ type: "score", payload: data });
// };

export const CREATESCORE = "CREATESCORE";
// export const HORROR_MOVIE = 'HORROR_MOVIE';
// export const TOP_MOVIE = 'TOP_MOVIE'
// export const LATEST_MOVIE = 'LATEST_MOVIE'
// export const DISPLAYSINGLEMOVIE = 'DISPLAYSINGLEMOVIE'

export const userScore = (recommemdedMovie) => {
  return {
    type: CREATESCORE,
    payload: recommemdedMovie,
  };
};
