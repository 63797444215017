import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import React from "react";
import { NotificationAIcons } from "../../utilities/Icons/Icons";
import classes from "./notification.module.css";
import { auth, functions } from "../../Firebase";
import { httpsCallable } from "firebase/functions";
import { Markup } from "interweave";
import { IP } from "../../config";
import { getCookie } from "../../Cookies";
import axios from "axios";
const NotificationPopup = ({ onClose, notifications }) => {
  const markReadNotifications = httpsCallable(
    functions,
    "markReadNotifications"
  );

  const markAllAsRead = () => {
    let configCertificate = {
      method: "post",
      // params: { userId: uid },
      url: `${IP}/userProfile/userNotification`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({}),
    };
    axios(configCertificate).then((res) => {
      //console.log(res.data)
    });
  };
  return (
    <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <Container>
        <Box sx={{ width: "100%", background: "#fff" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              borderBottom: "1px solid rgba(0, 124, 132, 0.5)",
              p: "10px",
            }}
          >
            <Typography
              sx={{ fontSize: "22px", fontWeight: 600, color: "#007C84" }}
            >
              Notification
            </Typography>
            <Box
              sx={{ display: "flex", alignItems: "center", marginLeft: "auto" }}
              onClick={() => {
                markAllAsRead();
              }}
            >
              <div
                style={{
                  fontWeight: 500,
                  color: "#007C84",
                  fontSize: "16px",

                  cursor: "pointer",
                }}
              >
                Mark all as read
              </div>
            </Box>
            <Box
              sx={{ display: "flex", alignItems: "center", margin: "10px" }}
              onClick={() => {
                onClose();
              }}
            >
              <div
                style={{
                  fontWeight: 600,
                  color: "#007C84",
                  fontSize: "16px",

                  cursor: "pointer",
                }}
              >
                Close
              </div>
            </Box>
          </Box>
          <Box
            className={classes.example}
            sx={{ py: "30px", height: "70vh", overflowY: "scroll" }}
          >
            <Box sx={{ padding: "0px 20px", width: "85%" }}>
              {notifications?.map((item) => (
                <Box
                  sx={{
                    display: "flex",
                    padding: "20px 0px",
                    borderBottom: "1px solid rgba(0, 124, 132, 0.5)",
                  }}
                >
                  <Box sx={{ width: "5%" }}>
                    <Box
                      sx={{
                        width: "25px",
                        height: "25px",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        background:
                          "linear-gradient(90deg, #007848 0%, #004577 105.46%)",
                      }}
                    >
                      <NotificationAIcons />
                    </Box>
                  </Box>
                  <Box sx={{ width: "95%" }}>
                    {item?.read ? (
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: 500,
                          color: "#007C84",
                        }}
                      >
                        <Markup content={item.title} />
                      </Typography>
                    ) : (
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: 500,
                          color: "#3EC70B",
                        }}
                      >
                        <Markup content={item.title} />
                      </Typography>
                    )}
                    {/* <Typography sx={{ fontSize: "16px", fontWeight: 500, color: "#007C84" }}>
                      {item.message}
                    </Typography> */}
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default NotificationPopup;
