import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { Box, Typography } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../../Firebase";
import { useNavigate } from "react-router";
import { IP } from "../../../../config";
import { getCookie } from "../../../../Cookies";
import axios from "axios";

const UploadCertificate = (props) => {
  const navigate = useNavigate();
  const { onClose, open, enrollmentData } = props;
  const handleClose = () => {
    onClose();
  };
  const editGroupEnrollment = httpsCallable(functions, "editGroupEnrollment");
  const [loading, setLoading] = useState(false);
  // uploadCertificate

  const runUploadCertificate = (updateDueDate) => {
    setLoading(true);

    let configgroupEnrollment = {
      method: "patch",
      // params: { tempuid: data.id },
      url: `${IP}/groupEnrollment`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        ...enrollmentData,
        updateDueDate: updateDueDate,
      }),
    };
    axios(configgroupEnrollment).then(() => {
      setLoading(false);
      navigate("/admin/groupEnrollment");
    });
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      PaperProps={{
        style: {
          borderRadius: "20px",
          height: "300px",
          padding: "16px",
        },
      }}
      onClose={() => {
        if (loading) return;
        handleClose();
      }}
      open={open}
    >
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          textAlign: "left",
          color: "#11047A",
        }}
      >
        <p>Warning</p>
        <button
          onClick={() => {
            if (loading) return;
            handleClose();
          }}
          style={{
            width: "40px",
            height: "40px",
            background: "#f4f7fe",
            marginLeft: "auto",
            borderRadius: "5px",
            border: "none",
          }}
        >
          <svg
            width="11"
            height="11"
            viewBox="0 0 11 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.43 0.244723C10.1145 -0.0707873 9.60485 -0.0707873 9.28934 0.244723L5.33333 4.19264L1.37732 0.236633C1.06181 -0.0788775 0.552143 -0.0788775 0.236633 0.236633C-0.0788775 0.552143 -0.0788775 1.06181 0.236633 1.37732L4.19264 5.33333L0.236633 9.28934C-0.0788775 9.60485 -0.0788775 10.1145 0.236633 10.43C0.552143 10.7455 1.06181 10.7455 1.37732 10.43L5.33333 6.47402L9.28934 10.43C9.60485 10.7455 10.1145 10.7455 10.43 10.43C10.7455 10.1145 10.7455 9.60485 10.43 9.28934L6.47402 5.33333L10.43 1.37732C10.7375 1.0699 10.7375 0.552143 10.43 0.244723Z"
              fill="#707EAE"
            />
          </svg>
        </button>
      </DialogTitle>
      {loading ? (
        <Box
          sx={{
            padding: "16px 24px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Typography varient="h5">Processing...</Typography>
          </div>
        </Box>
      ) : (
        <Box
          sx={{
            padding: "16px 24px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">
              Do you want to Update dueDate for everyone ?
            </Typography>
          </div>
          <Box
            sx={{
              display: "flex",
              gap: "50px",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box>
              <Button
                variant="contained"
                sx={{
                  mt: "1em",
                  color: "#fff",
                  background: "#11047A",
                  borderRadius: "70px",
                }}
                disable={loading}
                onClick={() => runUploadCertificate(true)}
              >
                Yes
              </Button>
            </Box>
            <Box>
              <Button
                variant="contained"
                sx={{
                  mt: "1em",
                  color: "#fff",
                  background: "#11047A",
                  borderRadius: "70px",
                }}
                disable={loading}
                onClick={() => runUploadCertificate(false)}
              >
                No
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </Dialog>
  );
};

export default UploadCertificate;
