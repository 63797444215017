import React, { useContext, useEffect, useState } from "react";
import {
  TimelineItem,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
} from "@mui/lab";
import { Box, Typography } from "@mui/material";
import {
  ContentCourseChildAccordionQuizIcons,
  TickCompleted,
} from "../../../../../utilities/Icons/Icons";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../../../Firebase";
import { ItemContext } from "../Context/ItemContext";
import { IP } from "../../../../../config";
import { getCookie } from "../../../../../Cookies";
import axios from "axios";

const QuizTimeLine = ({ quizId, connect }) => {
  const [quizInfo, setQuizInfo] = useState(null);
  const getQuizInfo = httpsCallable(functions, "getQuizInfo");
  const getQuizProgress = httpsCallable(functions, "getQuizProgress");

  const { courseId, itemsArray, setProgressIndex, isLinear } =
    useContext(ItemContext);
  const access = !isLinear;
  const [completed, setCompleted] = useState(false);
  const setMainItem = (element) => element.id == quizId;
  useEffect(() => {
    // getQuizInfo({ quizId: quizId });

    let CourseInfo = {
      method: "get",
      params: { quizId: quizId },
      url: `${IP}/userCourseDetails/quiz`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "",
    };
    axios(CourseInfo).then((result) => setQuizInfo(result.data));
    // getQuizProgress({ courseId: courseId, quizId: quizId })

    let quizProgress = {
      method: "get",
      params: { courseId: courseId, quizId: quizId },
      url: `${IP}/userProgress/quiz`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "",
    };
    axios(quizProgress).then((result) => {
      if (result.data.error === "not present") {
        setCompleted(false);
      } else if (result.data.completed === true) {
        setCompleted(true);
      }
      //console.log(result);
    });
  }, []);
  return (
    <TimelineItem>
      <TimelineSeparator className={"separator_content_padding"}>
        <TimelineDot className={"timeline_dot"} />
        {connect && <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent>
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            pt: "6px",
          }}
        >
          <ContentCourseChildAccordionQuizIcons />
          <Typography
            sx={{
              fontSize: "12px",
              fontFamily: "Lato",
              color: "#007C84",
              fontWeight: 700,
              cursor: completed || access ? "pointer" : "not-allowed",
            }}
            onClick={() => {
              if (completed || access)
                setProgressIndex(itemsArray.findIndex(setMainItem));
            }}
          >
            {quizInfo?.quizName}
          </Typography>
          {completed && (
            <TickCompleted
              style={{ marginLeft: "auto", marginRight: "30px" }}
            />
          )}
        </Box>
      </TimelineContent>
    </TimelineItem>
  );
};
export default QuizTimeLine;
