import { httpsCallable } from "firebase/functions";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import Papa from "papaparse";
import "../../../App.css";
import { getCookie } from "../../../Cookies";
import { db, functions } from "../../../Firebase";
import Navbar from "../../Component/NavbarTop";
import Sidebar from "../../Component/Sidebar";
import { Timestamp } from "firebase/firestore";
import "../StyleAdminComponents/User.css";
import "./Users.css";
import BulkDialog from "./Components/BulkDialog";
import CourseListPopUp from "./corseListPop";
import ArchivePopUp from "./archivePopUp";
import { IP } from "../../../config";
import axios from "axios";
import { toast } from "react-toastify";

function Users() {
  const navigate = useNavigate();
  const goToAddNewUser = (state) =>
    navigate("/admin/addNewUser", { state: { ...state } });
  const goToEditUser = (state) =>
    navigate("/admin/userview", { state: { ...state } });
  const goToHome = () => navigate("/");
  const [valueSelect, setvalue] = useState("");
  const initState = {
    name: "",
    employeeId: "",
    companyId: "",
    departmentId: "",

    role: "",
    selected: "default",
  };
  const [currentUrl, setCurrentUrl] = useState("");
  const [currentDataUrl, setCurrentDataUrl] = useState({});
  const [courseList, setCourseList] = useState([]);
  const [openArchivePopup, setopenArchivePopup] = useState(false);
  const [openCoordinater, setopenCoordinater] = useState(false);

  const [archiveSelectedDataObj, setarchiveSelectDataObj] = useState({
    item: {},
    id: "",
  });
  const [query, setQuery] = useState(initState);
  useEffect(() => {
    if (getCookie("UID") == null || getCookie("ROLE") != "admin") {
      goToHome();
      return <></>;
    }
  }, []);
  const [showLoading, setLoading] = useState(true);
  const [showBulk, setShowBulk] = useState(false);
  const [userArray, setUserArray] = useState([]);
  const [details, setDetails] = useState([]);
  const getUserList = httpsCallable(functions, "getUserList");
  const archiveUser = httpsCallable(functions, "archiveUser");
  const unarchiveUser = httpsCallable(functions, "unarchiveUser");
  const inviteUserRemainder = httpsCallable(functions, "inviteUserRemainder");

  const getallTheCourseListOfInstructorAndAdmin = httpsCallable(
    functions,
    "getallTheCourseListOfInstructorAndAdmin"
  );
  const userDelete = httpsCallable(functions, "userDelete");
  const { clrs } = useSelector((state) => state.createClr);
  const [arrLength, setArrLength] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [userJobTitle, setUserJobTitle] = useState([]);
  const [userCompany, setUserCompany] = useState([]);
  const [companyObj, setCompanyObj] = useState({});
  const [departmentObj, setDepartmentObj] = useState({});

  const [userDepart, setUserDepart] = useState([]);
  const [stateTempForfetchUserList, setStateTempForfetchUserList] =
    useState(true);
  const [archivedUserList, setArchivedUserList] = useState([]);
  const [buttonTogalForarchived, setButtonTogalForarchived] = useState(true);
  const [extraArrForSaveTheUsers, setExtraArrForSaveTheUsers] = useState([]);
  const [tempLoading, setTemploading] = useState("test");
  const [selectedAll, setSelectedAll] = useState(false);

  const [newEmployeeArray, setNewEmployeeArray] = useState([]);
  const handleQueryChange = (e) => {
    setQuery({
      ...query,
      [e.target.name]: e.target.value,
    });
  };
  const setDefault = () => {
    setQuery(initState);
    // setDetails(userArray);
    // setArrLength(userArray.length);
    getReportData();
    return;
  };

  const handleStatusChange = (e) => {
    setQuery({
      ...query,
      role: e.target.value,
    });
    //console.log(e.target.name);
    //console.log(e.target.value);
    if (e.target.value == "default") {
      setDetails(userArray);
      setArrLength(userArray.length);
      return;
    }
    // if(role==)
    const temp = userArray.filter((item) => item.role == e.target.value);
    // //console.log(temp);
    setDetails(temp);
    setArrLength(temp.length);
    // //console.log(temp);
    setCurrentPage(0);
  };

  const getReportData = (
    employeeName,
    employeeId,
    companyId,
    departmentId,
    role,
    archive
  ) => {
    setLoading(true);

    let query = {};

    // console.log(archive);
    if (employeeId) {
      query["employeeId"] = employeeId;
    }
    if (employeeName) {
      query["name"] = employeeName;
    }
    if (companyId) {
      query["companyId"] = companyId;
    }
    if (departmentId) {
      query["departmentId"] = departmentId;
    }
    if (role) {
      query["role"] = role;
    }
    if (archive === undefined) {
      archive = !buttonTogalForarchived;
    }
    query["archiveType"] = archive;
    let configLesson = {
      method: "get",
      params: query,
      url: `${IP}/adminUserProfile/userList?limit=${25}`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataLesson",
    };
    axios(configLesson)
      .then((res) => {
        let data = res.data.data;
        setCurrentUrl(`${IP}/adminUserProfile/userList`);
        setCurrentDataUrl(res.data);
        setLoading(false);

        setArrLength(res.data.t_count);

        setUserArray(
          data?.map((item) => ({ ...item, selected: false }))
          // .filter((item) => item.archived != true)
        );
        setDetails(
          data?.map((item) => ({ ...item, selected: false }))
          // .filter((item) => item.archived != true)
        );
        setExtraArrForSaveTheUsers(
          data?.map((item) => ({ ...item, selected: false }))
          // .filter((item) => item.archived != true)
        );

        setArchivedUserList(
          data?.map((item) => ({ ...item, selected: false }))
          // .filter((item) => item.archived == true)
        );

        if (buttonTogalForarchived) {
          let tempdata = data?.map((item) => ({ ...item, selected: false }));
          // .filter((item) => item.archived != true);
          setArrLength(tempdata.length);
        } else {
          let tempdata = data?.map((item) => ({ ...item, selected: false }));
          // .filter((item) => item.archived == true);
          setArrLength(tempdata.length);
        }
        let tempobj = {};
        data?.map((item) => {
          let lower = item.jobTitle.toLowerCase();

          tempobj[lower] = "i_am_R";
        });

        let temparr = Object.keys(tempobj);
        setUserJobTitle(temparr);

        setButtonTogalForarchived(!archive);
        setCurrentPage(0);
        setPageSize(25);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const getCompanyList = () => {
    let configLesson = {
      method: "get",
      params: query,
      url: `${IP}/common/company`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataLesson",
    };
    axios(configLesson).then((res) => {
      setUserCompany(res.data);

      res.data.map((el) => {
        setCompanyObj((prev) => ({ ...prev, [el.companyId]: el.companyName }));
      });
    });
  };

  const getDepartmentList = () => {
    let configLesson = {
      method: "get",
      params: query,
      url: `${IP}/common/department`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataLesson",
    };
    axios(configLesson).then((res) => {
      setUserDepart(res.data);

      res.data.map((el) => {
        setDepartmentObj((prev) => ({ ...prev, [el.departmentId]: el.name }));
      });
    });
  };
  useEffect(() => {
    getReportData();
    getCompanyList();
    getDepartmentList();
  }, [stateTempForfetchUserList]);
  // //console.log(userArray);
  const clickhandler = (url) => {
    setLoading(true);
    setCurrentUrl(`${url}`);

    let dataex = JSON.stringify({});
    let configExInt = {
      method: "get",

      url: `${url}&limit=${pageSize}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataex,
    };
    axios(configExInt)
      .then((res) => {
        setCurrentUrl(`${url}`);
        setLoading(false);

        let data = res.data.data;

        setArrLength(res.data.t_count);
        setCurrentDataUrl(res.data);
        // setGetDataForASelectedCourse(data);

        setUserArray(
          data?.map((item) => ({ ...item, selected: false }))
          // .filter((item) => item.archived != true)
        );
        setDetails(
          data?.map((item) => ({ ...item, selected: false }))
          // .filter((item) => item.archived != true)
        );
      })
      .catch((err) => {
        setLoading(false);
        toast.warning("something went wrong");
      });
  };

  const handleChange = (event, item, id) => {
    var data = event.target.value;
    setvalue(event.target.value);
    if (data == "unarchive") {
      setTemploading(id);

      let configLesson = {
        method: "patch",
        // params: { uid: item.userId },
        url: `${IP}/adminUserProfile/userList`,

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: JSON.stringify({
          userId: item.userId,
        }),
      };
      axios(configLesson)
        .then((res) => {
          //  setStateTempForfetchUserList(!stateTempForfetchUserList)

          getReportData();
          setTemploading("test");
        })
        .catch((err) => {
          setTemploading("test");
        });
    }
    if (data == "archive") {
      setarchiveSelectDataObj({
        item: item,
        id: id,
      });
      setopenArchivePopup(true);
    }
    if (data == "edit") {
      goToAddNewUser(item);
    } else if (data == "view") {
      goToEditUser(item);
    } else if (data == "delete") {
      // setLoading(true);

      let configLesson = {
        method: "delete",
        params: { uid: item.userId },
        url: `${IP}/adminUserProfile`,

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: JSON.stringify({
          uid: item.userId,
        }),
      };
      axios(configLesson)
        .then((res) => {
          setLoading(false);
          getReportData();
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  };

  const showArchivedFunction = (type) => {
    if (type == "archived") {
      setDetails(archivedUserList);
      setUserArray(archivedUserList);
      setArrLength(archivedUserList.length);
      setCurrentPage(0);

      getReportData("", "", "", "", "", true);
    } else if (type == "user") {
      setDetails(extraArrForSaveTheUsers);
      setUserArray(extraArrForSaveTheUsers);
      setArrLength(extraArrForSaveTheUsers.length);
      setCurrentPage(0);
      getReportData("", "", "", "", "", false);
    }
    setButtonTogalForarchived(!buttonTogalForarchived);
  };

  const handelCheckBoxChech = (type, ind, item) => {
    if (buttonTogalForarchived) {
      if (type == "all") {
        if (!selectedAll) {
          let newARr = details.map((el) => ({
            ...el,
            selected: el.invitationAccept ? false : true,
          }));
          setDetails([]);
          setDetails(newARr);
        } else {
          let newARr = details.map((el) => ({ ...el, selected: false }));
          setDetails([]);
          setDetails(newARr);
        }
        setSelectedAll(!selectedAll);
      } else if (type == "one") {
        if (!details[ind].selected) {
          let newARr = [...details];
          newARr[ind].selected = true;
          ////console.log( newARr[ind].selected)
          setDetails([]);
          // //console.log(newARr)
          setDetails(newARr);
        } else {
          let newARr = [...details];
          newARr[ind].selected = false;
          ////console.log( newARr[ind].selected)
          setDetails([]);
          // //console.log(newARr)
          setDetails(newARr);
        }
      }

      if (type == "sub") {
        let count = 0;
        let userArr = [];
        for (let i = 0; i < details.length; i++) {
          if (details[i].selected && !details[i].invitationAccept) {
            count++;
            userArr.push({
              name: details[i].name,
              email: details[i].email,
              uid: details[i].userId,
            });
            // inviteUserRemainder();
          }
        }
        if (count == 0) {
          alert(`select the user invitationAccept pending`);
        } else {
          let configLesson = {
            method: "post",
            // params: { userArr:userArr },
            url: `${IP}/mail/invitation`,

            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getCookie("bearer")}`,
            },
            data: JSON.stringify({
              userArr: userArr,
            }),
          };
          axios(configLesson).then((res) => {
            alert(`invite send to ${count} user`);
          });
        }

        //inviteUserRemainder({name:item.name,email:item.email})
      }
    }
  };
  //console.log("ShowArchive")
  const translated = t("ShowArchive");
  //console.log(translated);

  const archiveUserPopUp = (type) => {
    console.log(archiveSelectedDataObj);

    console.log(type);
    if (type == "true") {
      setTemploading(archiveSelectedDataObj.id);

      let configLesson = {
        method: "get",
        params: { uid: archiveSelectedDataObj.item.userId },
        url: `${IP}/adminUserProfile/instructorAdminCourse`,

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: "dataLesson",
      };
      axios(configLesson)
        .then((resul) => {
          if (resul.data.length > 0) {
            setopenCoordinater(true);
            setCourseList(resul.data);
            setTemploading("test");
          } else {
            setopenCoordinater(false);
            setCourseList([]);

            let configLesson = {
              method: "patch",
              // params: { uid: archiveSelectedDataObj.item.userId },
              url: `${IP}/adminUserProfile/userDetails`,

              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${getCookie("bearer")}`,
              },
              data: JSON.stringify({
                userId: archiveSelectedDataObj.item.userId,
              }),
            };
            axios(configLesson)
              .then((res) => {
                //  setStateTempForfetchUserList(!stateTempForfetchUserList)
                let obj = archiveSelectedDataObj.item;
                let archivedData = { ...obj, archived: true };

                getReportData();
              })
              .catch((err) => {
                setTemploading("test");
              });
          }
        })
        .catch((er) => {});

      return;
    } else {
      setarchiveSelectDataObj({});
      setTemploading("test");
    }
  };

  const changePageLimit = (pageLimit) => {
    setLoading(true);

    // let URL = currentUrl;
    // if (archive === undefined) {
    // archive = buttonTogalForarchived;
    // }
    let query = {};
    query["archiveType"] = !buttonTogalForarchived;

    let URL = currentUrl.replace(/stName=[^&]+&/, "");
    URL = URL.replace(/count=[^&]+&/, "");
    URL = URL.replace(/archiveType=[^&]+&/, "");

    URL = URL.replace(/archiveType=[^&]+&/, "");

    URL = URL.replace(/stEmail=[^&]+&/, "");

    URL = URL.replace(/isNext=[^&]+&/, "");

    URL = URL.replace(/archiveType=[^&]+&/, "");

    console.log(URL);
    function checkArchiveType(link) {
      return link.includes("archiveType");
    }

    if (currentUrl.split("?")[1]) {
      URL = `${URL}&limit=${pageLimit}`;
    } else {
      URL = `${URL}?limit=${pageLimit}`;
    }
    let dataex = JSON.stringify({});
    let configExInt = {
      method: "get",
      params: checkArchiveType(URL) ? {} : query,
      url: URL,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataex,
    };
    axios(configExInt)
      .then((res) => {
        setLoading(false);

        let data = res.data.data;

        setArrLength(res.data.t_count);
        setCurrentDataUrl(res.data);
        // setGetDataForASelectedCourse(data);

        setLoading(false);

        setUserArray(
          data?.map((item) => ({ ...item, selected: false }))
          // .filter((item) => item.archived != true)
        );
        setDetails(
          data?.map((item) => ({ ...item, selected: false }))
          // .filter((item) => item.archived != true)
        );
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast.warning("something went wrong");
      });
  };

  // console.log(departmentObj);
  return (
    <div className="admin-users">
      <Navbar page={t("Users")} />
      <div className="admin-users-main">
        <CourseListPopUp
          open={openCoordinater}
          onClose={() => setopenCoordinater(false)}
          coorId={courseList}
        />

        <ArchivePopUp
          open={openArchivePopup}
          onClose={() => setopenArchivePopup(false)}
          coorId={"coordinatorId"}
          delfun={(topic) => {
            archiveUserPopUp(topic);
          }}
        />
        <Sidebar page={"user"} hidepage={false} />
        <div className="admin-overdue">
          <div
            style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr" }}
            className="admin-dashboard-main-mid"
          >
            <div className="admin-dashboard-main-mid-item">
              <div className="admin-dashboard-main-mid-item-txt">
                {t("UserName")}
              </div>
              <div style={{ position: "relative" }}>
                <input
                  style={{ width: "11vw" }}
                  value={query.name}
                  name="name"
                  type="text"
                  onChange={handleQueryChange}
                  placeholder={t("Enter Username")}
                />
                <div
                  className="admin-dashboard-main-top-last"
                  style={{
                    position: "absolute",
                    top: "-0.5vw",
                    right: "1.55vw",
                  }}
                >
                  <button
                    style={{
                      background: `${
                        clrs.CourseListBtn
                          ? clrs.CourseListBtn
                          : "hwb(224deg 78% 3%)"
                      }`,
                      height: "4.1vh",
                      borderRadius: "0px 6px 6px 0px",
                    }}
                    onClick={() => {
                      getReportData(
                        query.name,
                        query.employeeId,
                        query.companyId,
                        query.departmentId,
                        query.role
                      );
                    }}
                  >
                    <div style={{ marginRight: "-2.9vw" }}>{t("Search")}</div>
                  </button>
                </div>
              </div>
            </div>
            <div className="admin-dashboard-main-mid-item">
              <div className="admin-dashboard-main-mid-item-txt">
                {t("EmployeeNo")}
              </div>
              <div style={{ position: "relative" }}>
                <input
                  style={{ width: "11vw" }}
                  name="employeeId"
                  value={query.employeeId}
                  type="text"
                  onChange={handleQueryChange}
                  placeholder="Search By Employee No."
                />
                <div
                  className="admin-dashboard-main-top-last"
                  style={{
                    position: "absolute",
                    top: "-0.5vw",
                    right: "1.1vw",
                  }}
                >
                  <button
                    style={{
                      background: `${
                        clrs.CourseListBtn
                          ? clrs.CourseListBtn
                          : "hwb(224deg 78% 3%)"
                      }`,
                      height: "4.1vh",
                      borderRadius: "0px 6px 6px 0px",
                    }}
                    onClick={() => {
                      getReportData(
                        query.name,
                        query.employeeId,
                        query.companyId,
                        query.departmentId,
                        query.role
                      );
                    }}
                  >
                    <div style={{ marginRight: "-2.9vw" }}>{t("Search")}</div>
                  </button>
                </div>
              </div>
            </div>

            {/* <div className="admin-dashboard-main-mid-item">
              <div className="admin-dashboard-main-mid-item-txt">
                {t("JobTitle")}
              </div>
              <div style={{ position: "relative" }}>
                <select
                  style={{ width: "11vw" }}
                  name="jobTitle"
                  value={query.jobTitle}
                  type="text"
                  onChange={handleQueryChange}
                  placeholder="Search By Job Title"
                >
                  {userJobTitle?.map((item) => (
                    <option value={item}>{item.toUpperCase()}</option>
                  ))}
                </select>
                <div
                  className="admin-dashboard-main-top-last"
                  style={{
                    position: "absolute",
                    top: "-0.5vw",
                    right: "1.1vw",
                  }}
                >
                  <button
                    style={{
                      background: `${
                        clrs.CourseListBtn
                          ? clrs.CourseListBtn
                          : "hwb(224deg 78% 3%)"
                      }`,
                      height: "4.1vh",
                      borderRadius: "0px 6px 6px 0px",
                    }}
                    onClick={() => handleSearch("jobTitle")}
                  >
                    <div style={{ marginRight: "-2.9vw" }}>{t("Filter")}</div>
                  </button>
                </div>
              </div>
            </div> */}

            <div className="admin-dashboard-main-mid-item">
              <div className="admin-dashboard-main-mid-item-txt">
                {t("Company")}
              </div>
              <div style={{ position: "relative" }}>
                <select
                  style={{ width: "11vw" }}
                  name="companyId"
                  value={query.companyId}
                  type="text"
                  onChange={(e) => {
                    handleQueryChange(e);
                    getReportData(
                      query.name,
                      query.employeeId,
                      e.target.value,
                      query.departmentId,
                      query.role
                    );
                  }}
                  placeholder="Search By Company"
                >
                  <option value="">{t("Select Company")}</option>

                  {userCompany?.map((item) => (
                    <option value={item.companyId}>{item.companyName}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="admin-dashboard-main-mid-item">
              <div className="admin-dashboard-main-mid-item-txt">
                {t("Department")}
              </div>
              <div style={{ position: "relative" }}>
                <select
                  style={{ width: "11vw" }}
                  type="text"
                  value={query.departmentId}
                  name="departmentId"
                  onChange={(e) => {
                    handleQueryChange(e);

                    getReportData(
                      query.name,
                      query.employeeId,
                      query.companyId,

                      e.target.value,
                      query.role
                    );
                  }}
                  placeholder="Search By Department"
                >
                  <option value="">{t("Select Department")}</option>
                  {userDepart?.map((item) => (
                    <option value={item.departmentId}>{item.name}</option>
                  ))}
                </select>
              </div>
            </div>

            <div className="admin-dashboard-main-mid-item">
              <div className="admin-dashboard-main-mid-item-txt">
                {t("Role")}
              </div>
              <div>
                <select
                  style={{ width: "11vw" }}
                  value={query.role}
                  onChange={(e) => {
                    handleStatusChange(e);

                    getReportData(
                      query.name,
                      query.employeeId,
                      query.companyId,

                      query.departmentId,

                      e.target.value
                    );
                  }}
                  name="role"
                  id="SelectRole"
                >
                  <option value="">{t("SelectRole")}</option>
                  <option value="ADMIN">{t("Admin")}</option>
                  <option value="USER">{t("User")}</option>
                  <option value="INSTRUCTOR">{t("Instructor")}</option>
                </select>
              </div>
            </div>
            <div
              class="admin-dashboard-main-top-last"
              style={{ height: " 16vh", width: "10vh" }}
            >
              <button
                style={{
                  width: "10vw",
                  background: `${
                    clrs.CourseListBtn
                      ? clrs.CourseListBtn
                      : "hwb(224deg 78% 3%)"
                  }`,
                }}
                onClick={setDefault}
              >
                <div>
                  <svg
                    width="auto"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M14.5411 0.306653C14.4915 0.270984 14.4322 0.25 14.3711 0.25H1.96654C1.3371 0.25 0.481658 0.546574 0.872148 1.04025C1.11132 1.34261 1.3505 1.64497 1.58968 1.94732C2.90969 3.61597 4.22969 5.28461 5.54797 6.95471C5.60263 7.02401 5.65578 7.0522 5.74312 7.05203C7.07158 7.04905 8.40008 7.04905 9.72854 7.05206C9.81602 7.05226 9.86925 7.02387 9.92375 6.95477C10.8668 5.75927 11.8117 4.56525 12.7565 3.37123C12.9509 3.12564 13.1452 2.88004 13.3396 2.63444C13.4306 2.51932 13.5217 2.40421 13.6128 2.28911C13.9566 1.85476 14.3003 1.42041 14.6435 0.985631C14.7914 0.798352 14.8165 0.605055 14.6948 0.442077C14.6546 0.388249 14.5979 0.347451 14.5411 0.306653ZM9.39023 8.05537C9.39023 7.9714 9.32216 7.90332 9.23818 7.90332H6.21498C6.14052 7.90332 6.08016 7.96368 6.08016 8.03814C6.08016 8.56841 6.08014 9.09867 6.08012 9.62894C6.08006 11.1763 6.08 12.7236 6.08047 14.2709C6.08058 14.675 6.38111 14.8623 6.7375 14.6802C7.02361 14.534 7.30944 14.3872 7.59527 14.2404C8.09356 13.9845 8.59185 13.7286 9.09161 13.4758C9.30188 13.3694 9.39333 13.2126 9.39264 12.9707C9.38915 11.7332 9.38956 10.4956 9.38997 9.25803C9.3901 8.85715 9.39023 8.45626 9.39023 8.05537Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <div>{t("ClearFilters")}</div>
              </button>
            </div>

            <div
              class="admin-dashboard-main-top-last"
              style={{ height: " 16vh", width: "10vh" }}
            >
              {buttonTogalForarchived ? (
                <button
                  style={{
                    width: "10vw",
                    background: `${
                      clrs.CourseListBtn
                        ? clrs.CourseListBtn
                        : "hwb(224deg 78% 3%)"
                    }`,
                  }}
                  onClick={() => {
                    showArchivedFunction("archived");
                  }}
                >
                  <div></div>
                  <div style={{ marginLeft: "-2vw" }}>{t("ShowArchive")}</div>
                </button>
              ) : (
                <button
                  style={{
                    width: "10vw",
                    background: `${
                      clrs.CourseListBtn
                        ? clrs.CourseListBtn
                        : "hwb(224deg 78% 3%)"
                    }`,
                  }}
                  onClick={() => {
                    showArchivedFunction("user");
                  }}
                >
                  <div></div>
                  <div style={{ marginLeft: "-2vw" }}>{t("ShowUsers")}</div>
                </button>
              )}
            </div>
          </div>

          <div style={{ height: "auto" }} className="courselist-main-cnt-top">
            {/*     <div className="courselist-main-cnt-top-txt">Group List</div> */}
            <div
              style={{
                background: `${
                  clrs.CourseListBtn ? clrs.CourseListBtn : "hwb(224deg 78% 3%)"
                }`,
                marginTop: "6vw",
                marginLeft: "0vw",
              }}
              className="head"
            >
              {t("UserList")}
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <button
                onClick={() => {
                  handelCheckBoxChech("sub", "id", "dataj");
                }}
                style={{
                  background: `${
                    clrs.CourseListBtn
                      ? clrs.CourseListBtn
                      : "hwb(224deg 78% 3%)"
                  }`,
                  width: "13vw",
                  fontWeight: "500",
                  height: "8vh",
                  marginBottom: "1em",
                }}
              >
                {t("SendReminder")}
              </button>
              <button
                onClick={() => {
                  setShowBulk(true);
                  // //console.log(Papa.parse("a,b,c\n1,2,3"));
                }}
                style={{
                  background: `${
                    clrs.CourseListBtn
                      ? clrs.CourseListBtn
                      : "hwb(224deg 78% 3%)"
                  }`,
                  width: "13vw",
                  fontWeight: "500",
                  height: "8vh",
                  marginBottom: "1em",
                }}
              >
                {t("BulkCreateUser")}
              </button>

              <Link to={"/admin/addNewUser"} style={{ marginBottom: "1em" }}>
                <button
                  style={{
                    background: `${
                      clrs.CourseListBtn
                        ? clrs.CourseListBtn
                        : "hwb(224deg 78% 3%)"
                    }`,
                    width: "13vw",
                    fontWeight: "500",
                    height: "8vh",
                  }}
                >
                  {t("AddNewUser")}
                </button>
              </Link>
              <BulkDialog
                showBulk={showBulk}
                handleClose={() => {
                  setShowBulk(false);
                }}
              />
            </div>
          </div>
          <div className="admin-overdue-bottom">
            <div className="admin-overdue-bottom-table">
              <div
                className="admin-overdue-bottom-table-cnt-usr"
                style={{
                  gridTemplateColumns:
                    "0.5vw 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr",
                }}
              >
                <div
                  className="admin-overdue-bottom-table-head"
                  style={{
                    marginLeft: "-8px",
                  }}
                >
                  <input
                    style={{
                      height: "1vw",
                      width: "1vw",
                      position: "relative",
                      top: "5%",
                      left: "-10%",
                    }}
                    type="checkbox"
                    checked={selectedAll}
                    //  value={selectedAll}
                    onClick={(e) => {
                      handelCheckBoxChech("all", "no", e.target.checked);
                    }}
                  />
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("EmployeeNo")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("Name")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("Company")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("Department")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("JobTitle")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("JoiningDate")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("InviteAccept")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("Role")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("Action")}
                </div>
              </div>
              {showLoading && (
                <div className="admin-overdue-bottom-table-cnt-certi" key={0}>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td">
                    <div className="spinner-container">
                      <div className="loading-spinner"></div>
                    </div>
                  </div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div
                    className="admin-overdue-bottom-table-td"
                    style={{ color: "#004577" }}
                  ></div>
                </div>
              )}
              {details.length == 0 && !showLoading ? (
                <div className="admin-overdue-bottom-table-cnt-certi" key={0}>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td">
                    <div className="spinner-container">
                      <div> No User found</div>
                    </div>
                  </div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div
                    className="admin-overdue-bottom-table-td"
                    style={{ color: "#004577" }}
                  ></div>
                </div>
              ) : null}
              {details ? (
                details.map((item, id) => {
                  if (id < pageSize * currentPage) return;
                  if (id >= pageSize * (currentPage + 1)) return;
                  let joiningDate = Timestamp.fromMillis(
                    item?.joiningDate?._seconds * 1000
                  );
                  return (
                    <div
                      className="admin-overdue-bottom-table-cnt-usr"
                      key={id}
                      style={{
                        gridTemplateColumns:
                          "1vw 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr",
                      }}
                    >
                      <div className="admin-overdue-bottom-table-td">
                        <input
                          style={{
                            height: "1vw",
                            width: "1vw",
                            position: "relative",
                            top: "5%",
                            left: "-10%",
                          }}
                          type="checkbox"
                          checked={item.selected}
                          onChange={(e) => {
                            handelCheckBoxChech("one", id, e.target.checked);
                          }}
                        />
                      </div>
                      <div className="admin-overdue-bottom-table-td">
                        {" "}
                        {item?.employeeId}
                      </div>
                      <div className="admin-overdue-bottom-table-td">
                        {item?.name}
                      </div>
                      <div className="admin-overdue-bottom-table-td">
                        {companyObj[item?.companyId]}
                      </div>
                      <div className="admin-overdue-bottom-table-td">
                        {departmentObj[item?.departmentId]}
                      </div>
                      <div className="admin-overdue-bottom-table-td">
                        {item?.jobTitle}
                      </div>
                      <div className="admin-overdue-bottom-table-td">
                        {joiningDate.toDate().toDateString()}
                      </div>
                      <div className="admin-overdue-bottom-table-td">
                        {item?.invitationAccept ? "Accepted" : "Pending"}
                      </div>
                      <div
                        className="admin-overdue-bottom-table-td"
                        style={{ color: "#FD5353" }}
                      >
                        {item.role}
                      </div>

                      <div
                        className="admin-overdue-bottom-table-td"
                        style={{ color: "#004577" }}
                      >
                        <div>
                          {item.archived ? (
                            tempLoading == id ? (
                              <p>Loading...</p>
                            ) : (
                              <select
                                style={{
                                  border: "none",
                                  backgroundColor: "#F2F2F2",
                                  height: "4vh",
                                  width: "7vw",
                                  padding: "0 1vh",
                                  fontWeight: "700",
                                  color: "#717579",
                                  borderRadius: "6px",
                                }}
                                onChange={(event) =>
                                  handleChange(event, item, id)
                                }
                              >
                                <option /* value={details.groupName} */>
                                  {t("Action")}
                                </option>

                                <option
                                  value={
                                    "unarchive"
                                  } /* value={details.groupName} */
                                >
                                  {t("UnArchive")}
                                </option>
                              </select>
                            )
                          ) : tempLoading == id ? (
                            <p>Loading...</p>
                          ) : (
                            <select
                              style={{
                                border: "none",
                                backgroundColor: "#F2F2F2",
                                height: "4vh",
                                width: "7vw",
                                padding: "0 1vh",
                                fontWeight: "700",
                                color: "#717579",
                                borderRadius: "6px",
                              }}
                              onChange={(event) =>
                                handleChange(event, item, id)
                              }
                            >
                              <option /* value={details.groupName} */>
                                {t("Action")}
                              </option>
                              <option
                                /* value={details.groupName}
                              id={details.groupName} */
                                value={"edit"}
                              >
                                {t("Edit")}
                              </option>
                              <option
                                value={"view"} /* value={details.groupName} */
                              >
                                {t("View")}
                              </option>
                              <option
                                value={
                                  "archive"
                                } /* value={details.groupName} */
                              >
                                {t("Archive")}
                              </option>
                              <option
                                value={"delete"} /* value={details.groupName} */
                              >
                                {t("Delete")}
                              </option>
                            </select>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <p>No user </p>
              )}
            </div>
            <div className="admin-overdue-bottom-pagination">
              <div className="admin-overdue-bottom-pagination-cnt">
                <div className="admin-overdue-bottom-pagination-cnt-item">
                  <svg
                    onClick={() => {
                      if (currentDataUrl.prev) {
                        clickhandler(currentDataUrl.prev);
                      }
                    }}
                    width="auto"
                    height="22"
                    viewBox="0 0 14 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.0293 10.4584L12.1855 0.837334C12.6016 0.479912 13.2109 0.802178 13.2109 1.37933V20.6215C13.2109 21.1987 12.6016 21.5209 12.1855 21.1635L1.0293 11.5424C0.709961 11.267 0.709961 10.7338 1.0293 10.4584Z"
                      fill="#717579"
                    />
                  </svg>
                </div>
                <div className="admin-overdue-bottom-pagination-cnt-item-btn">
                  {currentDataUrl?.c_page} of {currentDataUrl?.t_page}
                </div>
                <div
                  style={{ marginRight: "19vw" }}
                  className="admin-overdue-bottom-pagination-cnt-item"
                >
                  <svg
                    onClick={() => {
                      if (currentDataUrl.next) {
                        clickhandler(currentDataUrl.next);
                      }
                    }}
                    width="auto"
                    height="20"
                    viewBox="0 0 13 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.1816 9.22727L1.02539 0.141497C0.609375 -0.196038 0 0.108297 0 0.653332V18.8249C0 19.3699 0.609375 19.6742 1.02539 19.3367L12.1816 10.2509C12.501 9.99087 12.501 9.48733 12.1816 9.22727Z"
                      fill="#717579"
                    />
                  </svg>
                </div>
                <div
                  style={{ display: "flex", marginRight: "-23vw" }}
                  className="admin-overdue-bottom-pagination-cnt-item"
                >
                  <label className="admin-row">{t("Show")}</label>
                  <select
                    style={{
                      width: "4vw",
                      margin: "0.5vw",
                      marginBottom: "1.5vw",
                      height: "1.5vw",
                    }}
                    value={pageSize}
                    onChange={(e) => {
                      // changePageLimit(e.target.value);

                      setPageSize(e.target.value);
                    }}
                  >
                    {/* <option value={10}>10</option> */}
                    <option value={25}>25</option>
                    {/* <option value={50}>50</option> */}
                  </select>
                  <label className="admin-row"> {t("PagePerItem")}</label>
                </div>
              </div>
            </div>
            <div>
              {/*    <Link to={"/admin/addNewUser"}>
              <button>add new user</button>
            </Link> */}
              {/*  <Link to={"/admin/userview"}>
              <button>userview</button>
            </Link> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Users;
