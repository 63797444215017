import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { getCookie } from "../../../Cookies";
import { quizdata, quizdataLession } from "../../components/CourseLibrary/SingleCourse/Quiz/Quizdata";
import { createClrs } from "../../Redux/Actions";
import { blue, defultGreen } from "../../Redux/Colors";
import "../../Translater/Translater";
function Settings() {
  const navigate = useNavigate();
  const goToHome = () => navigate("/");
  
  useEffect(()=>{
    if(getCookie("UID")==null || getCookie("ROLE")!="user"){
      goToHome();
      return (<></>);
    }
  })
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  const submithandler = (i) => {
    if (i === 1) {
      dispatch(createClrs(defultGreen));
      localStorage.setItem("clrs", JSON.stringify(defultGreen));
    } else if (i === 2) {
      dispatch(createClrs(blue));
      localStorage.setItem("clrs", JSON.stringify(blue));
    } else if (i === 3) {
      i18n.changeLanguage("ar");
      localStorage.setItem("lang", JSON.stringify("ar"));
    } else if (i === 4) {
      i18n.changeLanguage("en");
      localStorage.setItem("lang", JSON.stringify("en"));
    }
  };

  const generateQuiz = () => {
    localStorage.setItem("QuestionNum", JSON.stringify(quizdata));
    localStorage.setItem("quizdataLession", JSON.stringify(quizdataLession));
  };
  const deletQuiz = () => {
    localStorage.removeItem("QuestionNum");
    localStorage.removeItem("quizdataLession");
  };
  // useEffect(() => {
  //   //console.log(clrs);
  // }, [clrs]);
  return (
    <div style={{marginTop: "20px", textAlign: "center"}}>
      <button style={{textTransform: "capitalize", marginLeft: "5px", borderRadius: "20px", padding: "10px 20px", background: "green"}} onClick={() => submithandler(1)}>Green color</button>
      <button style={{textTransform: "capitalize", marginLeft: "5px", borderRadius: "20px", padding: "10px 20px", background: "blue"}} onClick={() => submithandler(2)}>dark blue color</button>
      <button style={{textTransform: "capitalize", marginLeft: "5px", borderRadius: "20px", padding: "10px 20px", background: "green"}} onClick={() => submithandler(3)}>change lang to ar</button>
      <button style={{textTransform: "capitalize", marginLeft: "5px", borderRadius: "20px", padding: "10px 20px", background: "blue"}} onClick={() => submithandler(4)}>change lang to en</button>
      <button style={{textTransform: "capitalize", marginLeft: "5px", borderRadius: "20px", padding: "10px 20px", background: "green"}} onClick={generateQuiz}>Generate Quiz</button>
      <button style={{textTransform: "capitalize", marginLeft: "5px", borderRadius: "20px", padding: "10px 20px", background: "blue"}} onClick={deletQuiz}>delte quiz</button>
    </div>
  );
}

export default Settings;
