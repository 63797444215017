import { Box, Button, Typography } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
import { deleteCookie, getCookie, setCookie } from "../../../Cookies";
import { functions } from "../../../Firebase";
import { flexBetweenWithItemsCenter } from "../../../utilities/commonStyles/commonStyles";
import {
  CertificatesIcons,
  CourseLibraryIcons,
  HomeIcons,
  LeftArrowIcons,
  TrainingIcons,
} from "../../../utilities/Icons/Icons";
import logo from "../img/logo/logo.png";
import classes from "./ResponsiveMenu.module.css";
import { IP } from "../../../config";
import axios from "axios";

const ResponsiveMenu = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isAdmin, setIsAdmin] = useState(false);
  const fetchUserProfile = httpsCallable(functions, "fetchUserProfile");

  // useEffect(() => {
  //   fetchUserProfile({}).then((res) => {
  //     if (res.data.role == "admin" || res.data.role == "instructor") {
  //       setIsAdmin(true);
  //     }
  //   });
  // }, []);
  useEffect(() => {
    let configProfile = {
      method: "get",
      // params: { userId: uid },
      url: `${IP}/userProfile`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataexp",
    };

    axios(configProfile).then((res) => {
      //console.log(res.data.role)
      let role = res.data.role.toLowerCase();
      if (role == "admin" || role == "instructor") {
        setIsAdmin(true);
      }
    });
  }, []);
  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
      }}
    >
      <Box className={classes.sidebar}>
        <Box sx={{ padding: "0px 16px" }}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <img
              src={logo}
              style={{ width: "200px", margin: "10px 0px" }}
              alt=""
            />
          </Box>
          <Box>
            <Typography
              sx={{
                color: "#007C84",
                fontWeight: 300,
                padding: "0px 15px",
                marginTop: "10px",
              }}
            >
              {t("DASHBOARD")}
            </Typography>
          </Box>
          <Box>
            <NavLink
              to="/user/home"
              className={({ isActive }) =>
                isActive ? classes.active : classes.inactive
              }
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  gap: "10px",
                }}
              >
                <Box>
                  <HomeIcons />
                </Box>

                <Box
                  sx={{
                    ...flexBetweenWithItemsCenter,
                    width: "100%",
                    gap: "12px",
                  }}
                >
                  <Typography sx={{ fontWeight: 600 }}>{t("Home")}</Typography>
                  <LeftArrowIcons />
                </Box>
              </Box>
            </NavLink>
            <NavLink
              to="/user/course-library"
              className={({ isActive }) =>
                isActive ? classes.active : classes.inactive
              }
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  gap: "10px",
                }}
              >
                <Box>
                  <CourseLibraryIcons />
                </Box>

                <Box
                  sx={{
                    ...flexBetweenWithItemsCenter,
                    width: "100%",
                    gap: "12px",
                  }}
                >
                  <Typography sx={{ fontWeight: 600 }}>
                    {t("CourseLibrary")}
                  </Typography>
                  <LeftArrowIcons />
                </Box>
              </Box>
            </NavLink>
            <NavLink
              to="/user/training"
              className={({ isActive }) =>
                isActive ? classes.active : classes.inactive
              }
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  gap: "10px",
                }}
              >
                <Box>
                  <TrainingIcons />
                </Box>

                <Box
                  sx={{
                    ...flexBetweenWithItemsCenter,
                    width: "100%",
                    gap: "12px",
                  }}
                >
                  <Typography sx={{ fontWeight: 600 }}>
                    {t("Training")}
                  </Typography>
                  <LeftArrowIcons />
                </Box>
              </Box>
            </NavLink>
            <NavLink
              to="/user/certificates"
              className={({ isActive }) =>
                isActive ? classes.active : classes.inactive
              }
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  gap: "10px",
                }}
              >
                <Box>
                  <CertificatesIcons />
                </Box>

                <Box
                  sx={{
                    ...flexBetweenWithItemsCenter,
                    width: "100%",
                    gap: "12px",
                  }}
                >
                  <Typography sx={{ fontWeight: 600 }}>
                    {t("Certificates")}
                  </Typography>
                  <LeftArrowIcons />
                </Box>
              </Box>
            </NavLink>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                gap: "10px",
                color: "#171a1a",
                fill: "#003a69",
                background: "#d9f0fa",
                padding: "15px 20px",
                borderRadius: "5px",
                marginTop: "20px",
              }}
              onClick={() => {
                deleteCookie("UID");
                window.location.href = "/";
              }}
              title="Logout"
            >
              <Box>
                <svg
                  width="27"
                  height="27"
                  viewBox="0 0 27 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.5 27C6.04395 27 0 20.9561 0 13.5C0 6.04395 6.04395 0 13.5 0C20.9561 0 27 6.04395 27 13.5C27 20.9561 20.9561 27 13.5 27ZM20.25 18.9L27 13.5L20.25 8.1V12.15H9.45V14.85H20.25V18.9Z"
                    fill="#003A69"
                  />
                </svg>
              </Box>
              <Box
                sx={{
                  ...flexBetweenWithItemsCenter,
                  width: "100%",
                  gap: "12px",
                }}
              >
                <Typography sx={{ fontWeight: 600 }}>{t("Logout")}</Typography>
                <LeftArrowIcons />
              </Box>
            </Box>
            {/* <NavLink
                  to="user-reports"
                  className={({ isActive }) =>
                    isActive ? classes.active : classes.inactive
                  }
                >
                  <Box
                    sx={{
                      width: "90%",
                      display: "flex",
                      alignItems: "center",
                      gap: "12px",
                    }}
                  >
                    <UserReportsIcons />
                    <Typography sx={{ fontWeight: 600 }}>
                      {t("UserReports")}
                    </Typography>
                  </Box>
                  <Box sx={{ width: "10%" }}>
                    <LeftArrowIcons />
                  </Box>
                </NavLink> */}
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "20px",
              position: "absolute",
              bottom: "50px",
            }}
          >
            {/* <NavLink to="/user/settings">
              <Box
                sx={{
                  background: "#F5FAFA",
                  width: "32px",
                  height: "32px",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <SettingsIcons />
              </Box>
            </NavLink> */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "15px",
                position: "absolute",
                // bottom: "50px",
                left: "16px",
              }}
            >
              {isAdmin && (
                <Box>
                  <Button
                    sx={{
                      background: (theme) => theme.palette.primary.main,
                      color: "#fff",
                      padding: "8px 50px",
                      borderRadius: "5px",
                      cursor: "pointer",
                      border: "none",
                      outline: "none",
                      fontSize: "12px",
                      fontWeight: 600,
                      "&:hover": {
                        background: (theme) => theme.palette.primary.main,
                      },
                    }}
                    onClick={() => {
                      setCookie("ROLE", "admin");
                      navigate("/admin");
                    }}
                  >
                    {t("Admin")}
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ResponsiveMenu;
