import { Timestamp } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { functions } from "../../../Firebase";
import Navbar from "../../Component/NavbarTop";
import Sidebar from "../../Component/Sidebar";
import "../StyleAdminComponents/GroupList.css";
import "./OverdueEnrollments.css";
import "./UserPassed.css";
import { data } from "./UserPassedData";
import GenerateReport from "./popUpForReports";
import { getCookie } from "../../../Cookies";
import axios from "axios";
import { IP } from "../../../config";
function UserFailed() {
  const navigate = useNavigate();
  const goToHome = () => navigate("/");
  const { clrs } = useSelector((state) => state.createClr);
  // const location = useLocation();
  // const userFailedInfo = location.state;
  const userFailedThisMonth = httpsCallable(functions, "userFailedThisMonth");
  const uploadReports = httpsCallable(functions, "uploadReports");
  const courseFailedNotificationCallable = httpsCallable(
    functions,
    "courseFailedNotificationCallable"
  );
  const [reportLoading, setReportLoading] = useState(true);
  const [openEnrollment, setOpenEnrollment] = useState(false);
  const [courseName, setCourseName] = useState([]);
  const [details, setDetails] = useState(null);

  const [userFailedArray, setUserFailedArray] = useState();
  const [arrLength, setArrLength] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectToDate, setSelectToDate] = useState("");

  const [newEmployeeArray, setNewEmployeeArray] = useState(null);
  const [showLoading, setLoading] = useState(true);
  const [selectDate, setSelectDate] = useState("");
  const [userName, setUserName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [selectDefault, setselectDefault] = useState("default");
  const [companyArr, setCompanyArr] = useState([]);
  const [companyObj, setCompanyObj] = useState({});

  const [department, setDepartment] = useState();
  const [departmentArr, setDepartmentArr] = useState([]);
  const [departmentObj, setDepartmentObj] = useState({});

  const initState = {
    courseName: "default",
    date: "",
  };
  const [query, setQuery] = useState(initState);
  const setDefault = () => {
    setQuery(initState);
    setDetails(userFailedArray);
    setArrLength(userFailedArray.length);
    return;
  };

  const handleStatusChange = (e) => {
    setQuery({
      ...query,
      [e.target.name]: e.target.value,
    });
    //console.log(e.target.name);
    //console.log(e.target.value);
    setselectDefault(e.target.value);
    if (e.target.value == "default") {
      setDetails(userFailedArray);
      setArrLength(userFailedArray.length);
      return;
    }
    const temp = userFailedArray.filter(
      (item) => item.info[e.target.name] == e.target.value
    );
    //console.log(temp);
    setDetails(temp);
    setArrLength(temp.length);
    //console.log(temp);
    setCurrentPage(0);
  };

  useEffect(() => {
    if (
      getCookie("UID") == null ||
      (getCookie("ROLE") != "admin" && getCookie("ROLE") != "instructor")
    ) {
      goToHome();
      return <></>;
    }
  }, []);

  const selectedDateTohumanDate = (type) => {
    // //console.log(result.data)
    setDetails(newEmployeeArray);

    let selestedDate = selectDate;
    const dateFromPicker = selestedDate;

    var myDate = selestedDate;
    myDate = myDate.split("-");
    // //console.log(myDate[0], myDate[1] - 1, myDate[2])
    var newDate = new Date(myDate[0], myDate[1] - 1, myDate[2]);

    // const dateParts = dateFromPicker.split("-");
    // //console.log(dateParts)
    // const ISODate = dateParts[2]  + dateParts[0]  + dateParts[1];
    // //console.log(ISODate*1000)
    // const birthDate = new Date(ISODate);
    // scheduledDateOfRelease: Timestamp.fromDate(newDate)
    let secondsData = Timestamp.fromDate(newDate).seconds;
    //console.log(secondsData);
    //  //console.log(employeeArray)
    if (type == "date") {
      if (selectToDate == "" || selectDate == "") {
        alert("select date range");
        return;
      }

      let configFailedThisMonth = {
        method: "get",
        params: { to: selectToDate, from: selectDate },
        url: `${IP}/dashboard/usersFailedThisMonth
        `,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: "dataexp",
      };
      axios(configFailedThisMonth).then((res) => {
        //console.log(res.data.data);
        setDetails(
          res.data.data.filter((ele) => ele.userData?.archived !== true)
        );
        setArrLength(
          res.data.data.filter((ele) => ele.userData?.archived !== true).length
        );
        setCurrentPage(0);
      });
      //  let newarr=   newEmployeeArray.filter((e)=>{

      // //   //console.log()
      // //  let dd= Timestamp.fromMillis(e.info.joiningDate?._seconds * 1000).toDate()

      //  let milisecon = e.info.dueDate._seconds*1000
      //  let year = new Date(milisecon).getFullYear()
      //  let months = new Date(milisecon).getMonth()
      //  let days = new Date(milisecon).getDate()
      //  let fulldate="";
      //  if (months < 9) {
      //      months = Number(months) + 1
      //      months = "0" + months
      //  }else{
      //      months = Number(months) + 1
      //  }
      //  if (days <=9) {

      //      days = "0" + days
      //  }else{
      //      days = Number(days)
      //  }
      //  fulldate = `${year}-${months}-${days}`
      //  // //console.log(fulldate,selestedDate)
      // if(fulldate>=selectDate&&fulldate<=selectToDate){
      // return e
      // }

      // })
      // setDetails(newarr)
      // setArrLength(newarr.length);
    }
    if (type == "name") {
      setDetails(newEmployeeArray);
      //console.log(newEmployeeArray);
      let userArr = newEmployeeArray.filter((e) => {
        let nn = e.userData.userName.toLowerCase();
        let selectname = userName.toLowerCase();
        return nn.startsWith(selectname);
      });
      setDetails(userArr);
      setArrLength(userArr.length);
    }
    if (type == "company") {
      setDetails(newEmployeeArray);
      //console.log(newEmployeeArray);
      let userArr = newEmployeeArray.filter((e) => {
        return e.info.company == companyName;
      });
      setDetails(userArr);
      setArrLength(userArr.length);
    }
    if (type == "clear") {
      setSelectDate("");
      setselectDefault("");
      setSelectToDate("");
      setUserName("");
      setCurrentPage(0);
      setDetails(newEmployeeArray);
    }
    // //console.log(newarr)
  };

  useEffect(() => {
    let configdepartment = {
      method: "get",
      // params: { userId: uid },
      url: `${IP}/common/department
      `,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataexp",
    };
    axios(configdepartment).then((res) => {
      setDepartmentArr(res.data);
      res.data.map((el) => {
        setDepartmentObj((prev) => ({ ...prev, [el.departmentId]: el.name }));
      });
    });

    let configcompany = {
      method: "get",
      // params: { userId: uid },
      url: `${IP}/common/company
      `,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataexp",
    };
    axios(configcompany).then((res) => {
      // console.log(res.data);
      setCompanyArr(res.data);
      res.data.map((el) => {
        setCompanyObj((prev) => ({
          ...prev,
          [el.companyId]: el.companyName,
        }));
      });
    });
    // setUserFailedArray(userFailedInfo);
    // setArrLength(userFailedInfo?.length);
    let configFailedThisMonth = {
      method: "get",
      // params: { to: selectToDate, from: selectDate },
      url: `${IP}/dashboard/usersFailedThisMonth
      `,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataexp",
    };
    axios(configFailedThisMonth).then((result) => {
      setUserFailedArray(
        result.data?.data.filter((ele) => ele.userData?.archived !== true)
      );
      setDetails(
        result.data?.data.filter((ele) => ele.userData?.archived !== true)
      );
      let tempArr = result.data?.data
        .map((element) => ({
          ...element,
          checked: false,
          userArchived: element.userData?.archived,
        }))
        .filter((ele) => ele.userArchived !== true);
      //console.log(tempArr)
      setNewEmployeeArray(tempArr);
      // //console.log(result.data)
      setArrLength(tempArr.length);
      setLoading(false);

      let courseObj = {};
      result.data.data.map((element) => {
        courseObj[element?.info.courseName] = element?.info.courseName;
      });
      setCourseName(Object.keys(courseObj));
    });
  }, []);

  const clickhandler = (i) => {
    if (i < 0) return;
    if (i + 1 > Math.ceil(arrLength / pageSize)) return;
    //TODO: limit upper
    setCurrentPage(i);
  };

  const sendReminderForSelectedUser = async () => {
    let promissArr = [];
    setReportLoading(false);
    for (let i = 0; i < newEmployeeArray.length; i++) {
      if (newEmployeeArray[i].checked == true) {
        let promi = {
          courseId: newEmployeeArray[i].info.courseId,
          courseName: newEmployeeArray[i].info.courseName,

          dueDate: newEmployeeArray[i].info.dueDate,
          uid: newEmployeeArray[i].userData.userId,
        };
        promissArr.push(promi);
      }
    }
    // let res = await Promise.all(promissArr);

    let configcompany = {
      method: "post",
      // params: { userId: uid },
      url: `${IP}/mail/sendMailToFailedInTheCourse`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        userArr: promissArr,
      }),
    };
    axios(configcompany).then((res) => {
      alert(`reminder send to ${promissArr.length}`);
    });
    if (promissArr.length > 0) {
      setReportLoading(true);
      alert(`reminder send to ${promissArr.length}`);
    } else {
      setReportLoading(true);
    }
  };

  const handelCheckBoxChech = (check, index, item) => {
    // setNewEmployeeArray((prev) => {
    //   let newArr = Array.from(prev);

    //   newArr[index].checked = check;
    //   return newArr;
    // });
    //console.log("rahul")
    let tempArr = [];
    newEmployeeArray.map((e, i) => {
      if (e.userData.userId == item.userData.userId && e.id == item.id) {
        //console.log(e.userData.userId,"hhfhfhfh",item.userData.userId)
        if (check) {
          tempArr.push({ ...e, checked: true });
        } else {
          tempArr.push({ ...e, checked: false });
        }
      } else {
        tempArr.push(e);
      }
    });
    //console.log(tempArr)
    setNewEmployeeArray(tempArr);
  };

  return (
    <div className="section" style={{ backgroundColor: "#e5e5e5" }}>
      <Navbar page={t("Dashboard")} />
      <div className="createcourse-main">
        <Sidebar page={"Dashboard"} hidepage={true} />
        <div className="admin-overdue">
          <div className="admin-overdue-top">
            <div className="admin-overdue-top-item">
              {t("UserFailedInThisMonth")}
            </div>
          </div>
          <div
            style={{
              marginLeft: "81.5vw",
              marginTop: "-4vw",
              marginBottom: "2vw",
              gap: "10px",
              display: "flex",
            }}
            className="admin-dashboard-main-top-filter"
          >
            {/* {
            reportLoading?
             <button
              style={{
                background: `${clrs.CourseListBtn ? clrs.CourseListBtn : "hwb(224deg 78% 3%)"}`,
              }}
              onClick={()=>{generateReportsFunction()}}
            >
              <div>{t("Generate Reports")}</div>
            </button>:<button
              style={{
                background: `${clrs.CourseListBtn ? clrs.CourseListBtn : "hwb(224deg 78% 3%)"}`,
              }}
              // onClick={()=>{generateReportsFunction()}}
            >
              <div>{t("Generating reports ")}</div>
            </button>
} */}
            {reportLoading ? (
              <button
                style={{
                  background: `${
                    clrs.CourseListBtn
                      ? clrs.CourseListBtn
                      : "hwb(224deg 78% 3%)"
                  }`,
                }}
                onClick={() => {
                  sendReminderForSelectedUser();
                }}
              >
                <div>{t("SendReminder")}</div>
              </button>
            ) : (
              <button
                style={{
                  background: `${
                    clrs.CourseListBtn
                      ? clrs.CourseListBtn
                      : "hwb(224deg 78% 3%)"
                  }`,
                }}
              >
                <div>{t("Sending Reminder")}</div>
              </button>
            )}
          </div>
          <div className="admin-overdue-mid">
            <div className="admin-overdue-mid-left">
              <div className="admin-overdue-mid-left-txt">
                {" "}
                {t("CourseName")}
              </div>
              <div className="admin-overdue-mid-left-input">
                <select
                  name="courseName"
                  value={selectDefault}
                  onChange={handleStatusChange}
                  id="company"
                >
                  <option
                    value="default"
                    selected={query.courseName == "default" ? true : false}
                  >
                    Select Course
                  </option>
                  {courseName.map((elem, index) => (
                    <option key={index} value={elem}>
                      {elem}
                    </option>
                  ))}{" "}
                </select>
              </div>
            </div>

            <div className="admin-overdue-mid-left">
              <div className="admin-overdue-mid-left-txt"> {t("Name")}</div>
              <div className="admin-overdue-mid-left-input">
                <input
                  type="text"
                  value={userName}
                  onChange={(e) => {
                    setUserName(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="admin-overdue-mid-right">
              {/* <div className="admin-overdue-mid-left-txt">{t("Date")}</div> */}
              <div className="admin-overdue-mid-left-input-right">
                {/* <input type="date" placeholder="Select Date" /> */}
                <div
                  className="admin-dashboard-main-mid-item-last-filter"
                  style={{ marginTop: "5vh", marginLeft: "0px" }}
                >
                  {" "}
                  <div className="admin-dashboard-main-top-last-filter">
                    <button
                      style={{
                        background: `${
                          clrs.CourseListBtn
                            ? clrs.CourseListBtn
                            : "hwb(224deg 78% 3%)"
                        }`,
                        width: "8vw",
                      }}
                      onClick={() => {
                        selectedDateTohumanDate("name");
                      }}
                    >
                      <div>
                        <svg
                          width="auto"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M14.5411 0.306653C14.4915 0.270984 14.4322 0.25 14.3711 0.25H1.96654C1.3371 0.25 0.481658 0.546574 0.872148 1.04025C1.11132 1.34261 1.3505 1.64497 1.58968 1.94732C2.90969 3.61597 4.22969 5.28461 5.54797 6.95471C5.60263 7.02401 5.65578 7.0522 5.74312 7.05203C7.07158 7.04905 8.40008 7.04905 9.72854 7.05206C9.81602 7.05226 9.86925 7.02387 9.92375 6.95477C10.8668 5.75927 11.8117 4.56525 12.7565 3.37123C12.9509 3.12564 13.1452 2.88004 13.3396 2.63444C13.4306 2.51932 13.5217 2.40421 13.6128 2.28911C13.9566 1.85476 14.3003 1.42041 14.6435 0.985631C14.7914 0.798352 14.8165 0.605055 14.6948 0.442077C14.6546 0.388249 14.5979 0.347451 14.5411 0.306653ZM9.39023 8.05537C9.39023 7.9714 9.32216 7.90332 9.23818 7.90332H6.21498C6.14052 7.90332 6.08016 7.96368 6.08016 8.03814C6.08016 8.56841 6.08014 9.09867 6.08012 9.62894C6.08006 11.1763 6.08 12.7236 6.08047 14.2709C6.08058 14.675 6.38111 14.8623 6.7375 14.6802C7.02361 14.534 7.30944 14.3872 7.59527 14.2404C8.09356 13.9845 8.59185 13.7286 9.09161 13.4758C9.30188 13.3694 9.39333 13.2126 9.39264 12.9707C9.38915 11.7332 9.38956 10.4956 9.38997 9.25803C9.3901 8.85715 9.39023 8.45626 9.39023 8.05537Z"
                            fill="white"
                          />
                        </svg>
                      </div>
                      <div>{t("Filter")}</div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="admin-overdue-mid">
            <div className="admin-overdue-mid-left">
              <div className="admin-overdue-mid-left-txt">
                {" "}
                {t("Due Date From")}
              </div>
              <div className="admin-overdue-mid-left-input">
                <input
                  type="date"
                  value={selectDate}
                  onChange={(e) => {
                    setSelectDate(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="admin-overdue-mid-left">
              <div className="admin-overdue-mid-left-txt">
                {" "}
                {t("Due Date To")}
              </div>
              <div className="admin-overdue-mid-left-input">
                <input
                  type="date"
                  value={selectToDate}
                  onChange={(e) => {
                    setSelectToDate(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="admin-overdue-mid-right">
              {/* <div className="admin-overdue-mid-left-txt">{t("Date")}</div> */}
              <div className="admin-overdue-mid-left-input-right">
                {/* <input type="date" placeholder="Select Date" /> */}
                <div
                  className="admin-dashboard-main-mid-item-last-filter"
                  style={{ marginTop: "5vh", marginLeft: "0px" }}
                >
                  {" "}
                  <div className="admin-dashboard-main-top-last-filter">
                    <button
                      style={{
                        background: `${
                          clrs.CourseListBtn
                            ? clrs.CourseListBtn
                            : "hwb(224deg 78% 3%)"
                        }`,
                        width: "8vw",
                      }}
                      onClick={() => {
                        selectedDateTohumanDate("date");
                      }}
                    >
                      <div>
                        <svg
                          width="auto"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M14.5411 0.306653C14.4915 0.270984 14.4322 0.25 14.3711 0.25H1.96654C1.3371 0.25 0.481658 0.546574 0.872148 1.04025C1.11132 1.34261 1.3505 1.64497 1.58968 1.94732C2.90969 3.61597 4.22969 5.28461 5.54797 6.95471C5.60263 7.02401 5.65578 7.0522 5.74312 7.05203C7.07158 7.04905 8.40008 7.04905 9.72854 7.05206C9.81602 7.05226 9.86925 7.02387 9.92375 6.95477C10.8668 5.75927 11.8117 4.56525 12.7565 3.37123C12.9509 3.12564 13.1452 2.88004 13.3396 2.63444C13.4306 2.51932 13.5217 2.40421 13.6128 2.28911C13.9566 1.85476 14.3003 1.42041 14.6435 0.985631C14.7914 0.798352 14.8165 0.605055 14.6948 0.442077C14.6546 0.388249 14.5979 0.347451 14.5411 0.306653ZM9.39023 8.05537C9.39023 7.9714 9.32216 7.90332 9.23818 7.90332H6.21498C6.14052 7.90332 6.08016 7.96368 6.08016 8.03814C6.08016 8.56841 6.08014 9.09867 6.08012 9.62894C6.08006 11.1763 6.08 12.7236 6.08047 14.2709C6.08058 14.675 6.38111 14.8623 6.7375 14.6802C7.02361 14.534 7.30944 14.3872 7.59527 14.2404C8.09356 13.9845 8.59185 13.7286 9.09161 13.4758C9.30188 13.3694 9.39333 13.2126 9.39264 12.9707C9.38915 11.7332 9.38956 10.4956 9.38997 9.25803C9.3901 8.85715 9.39023 8.45626 9.39023 8.05537Z"
                            fill="white"
                          />
                        </svg>
                      </div>
                      <div>{t("Filter")}</div>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="admin-overdue-mid-right">
              {/* <div className="admin-overdue-mid-left-txt">{t("Date")}</div> */}
              <div className="admin-overdue-mid-left-input-right">
                {/* <input type="date" placeholder="Select Date" /> */}
                <div
                  className="admin-dashboard-main-mid-item-last-filter"
                  style={{ marginTop: "5vh" }}
                >
                  {" "}
                  <div className="admin-dashboard-main-top-last-filter">
                    <button
                      style={{
                        background: `${
                          clrs.CourseListBtn
                            ? clrs.CourseListBtn
                            : "hwb(224deg 78% 3%)"
                        }`,
                        width: "8vw",
                      }}
                      onClick={() => {
                        selectedDateTohumanDate("clear");
                      }}
                    >
                      <div>
                        <svg
                          width="auto"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M14.5411 0.306653C14.4915 0.270984 14.4322 0.25 14.3711 0.25H1.96654C1.3371 0.25 0.481658 0.546574 0.872148 1.04025C1.11132 1.34261 1.3505 1.64497 1.58968 1.94732C2.90969 3.61597 4.22969 5.28461 5.54797 6.95471C5.60263 7.02401 5.65578 7.0522 5.74312 7.05203C7.07158 7.04905 8.40008 7.04905 9.72854 7.05206C9.81602 7.05226 9.86925 7.02387 9.92375 6.95477C10.8668 5.75927 11.8117 4.56525 12.7565 3.37123C12.9509 3.12564 13.1452 2.88004 13.3396 2.63444C13.4306 2.51932 13.5217 2.40421 13.6128 2.28911C13.9566 1.85476 14.3003 1.42041 14.6435 0.985631C14.7914 0.798352 14.8165 0.605055 14.6948 0.442077C14.6546 0.388249 14.5979 0.347451 14.5411 0.306653ZM9.39023 8.05537C9.39023 7.9714 9.32216 7.90332 9.23818 7.90332H6.21498C6.14052 7.90332 6.08016 7.96368 6.08016 8.03814C6.08016 8.56841 6.08014 9.09867 6.08012 9.62894C6.08006 11.1763 6.08 12.7236 6.08047 14.2709C6.08058 14.675 6.38111 14.8623 6.7375 14.6802C7.02361 14.534 7.30944 14.3872 7.59527 14.2404C8.09356 13.9845 8.59185 13.7286 9.09161 13.4758C9.30188 13.3694 9.39333 13.2126 9.39264 12.9707C9.38915 11.7332 9.38956 10.4956 9.38997 9.25803C9.3901 8.85715 9.39023 8.45626 9.39023 8.05537Z"
                            fill="white"
                          />
                        </svg>
                      </div>
                      <div>{t("Clear")}</div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <GenerateReport
            open={openEnrollment}
            onClose={() => setOpenEnrollment(false)}
          />
          <div className="admin-overdue-bottom">
            <div className="admin-overdue-bottom-table">
              <div className="admin-overdue-bottom-table-cnt-user">
                <div className="admin-overdue-bottom-table-head">
                  {t("SrNo")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("Name")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("CourseTitle")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("Company")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("Department")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("DueDate")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("Type")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("TrainingProvider")}
                </div>
              </div>
              {showLoading && (
                <div className="admin-overdue-bottom-table-cnt-certi" key={0}>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td">
                    <div className="spinner-container">
                      <div className="loading-spinner"></div>
                    </div>
                  </div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div
                    className="admin-overdue-bottom-table-td"
                    style={{ color: "#004577" }}
                  ></div>
                </div>
              )}
              {details?.map((item, id) => {
                if (id < pageSize * currentPage) return;
                if (id >= pageSize * (currentPage + 1)) return;
                let dueDate = Timestamp.fromMillis(
                  item.info?.dueDate?._seconds * 1000
                )
                  .toDate()
                  .toDateString();
                return (
                  <div className="admin-overdue-bottom-table-cnt-user" key={id}>
                    <div className="admin-overdue-bottom-table-td">
                      <input
                        style={{
                          height: "1vw",
                          width: "2vw",
                          marginRight: "2vw",
                          marginLeft: "-3vw",
                        }}
                        type="checkbox"
                        value={item.checked}
                        onClick={(e) => {
                          handelCheckBoxChech(e.target.checked, id, item);
                        }}
                      />
                      {id + 1}
                    </div>
                    <div className="admin-overdue-bottom-table-td">
                      {item.userData?.userName}
                    </div>
                    <div className="admin-overdue-bottom-table-td">
                      {item.info?.courseName}
                    </div>
                    <div className="admin-overdue-bottom-table-td">
                      {companyObj[item.info?.companyId]}
                    </div>
                    <div className="admin-overdue-bottom-table-td">
                      {departmentObj[item.info?.departmentId]}
                    </div>
                    <div className="admin-overdue-bottom-table-td">
                      {dueDate}
                    </div>
                    <div className="admin-overdue-bottom-table-td">
                      {item.info?.trainingType}
                    </div>
                    <div
                      className="admin-overdue-bottom-table-td"
                      style={{ color: "#007C84" }}
                    >
                      {item.info?.trainingProvider}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="admin-overdue-bottom-pagination">
              <div className="admin-overdue-bottom-pagination-cnt">
                <div className="admin-overdue-bottom-pagination-cnt-item">
                  <svg
                    onClick={() => clickhandler(currentPage - 1)}
                    width="auto"
                    height="22"
                    viewBox="0 0 14 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.0293 10.4584L12.1855 0.837334C12.6016 0.479912 13.2109 0.802178 13.2109 1.37933V20.6215C13.2109 21.1987 12.6016 21.5209 12.1855 21.1635L1.0293 11.5424C0.709961 11.267 0.709961 10.7338 1.0293 10.4584Z"
                      fill="#717579"
                    />
                  </svg>
                </div>
                <div className="admin-overdue-bottom-pagination-cnt-item-btn">
                  {currentPage + 1} of {Math.ceil(arrLength / pageSize)}
                </div>
                <div
                  style={{ marginRight: "19vw" }}
                  className="admin-overdue-bottom-pagination-cnt-item"
                >
                  <svg
                    onClick={() => clickhandler(currentPage + 1)}
                    width="auto"
                    height="20"
                    viewBox="0 0 13 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.1816 9.22727L1.02539 0.141497C0.609375 -0.196038 0 0.108297 0 0.653332V18.8249C0 19.3699 0.609375 19.6742 1.02539 19.3367L12.1816 10.2509C12.501 9.99087 12.501 9.48733 12.1816 9.22727Z"
                      fill="#717579"
                    />
                  </svg>
                </div>
                <div
                  style={{ display: "flex", marginRight: "-30vw" }}
                  className="admin-overdue-bottom-pagination-cnt-item"
                >
                  <label className="admin-row">{t("Show")}</label>
                  <select
                    style={{
                      width: "4vw",
                      margin: "0.5vw",
                      marginBottom: "1.5vw",
                      height: "1.5vw",
                    }}
                    value={pageSize}
                    onChange={(e) => setPageSize(e.target.value)}
                  >
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={15}>15</option>
                    <option value={20}>20</option>
                  </select>
                  <label className="admin-row"> {t("PagePerItem")}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{ height: "100%", width: "6vw", backgroundColor: "white" }}
        ></div>
        <div className="courselist-main-cnt-back">
          <div className="courselist-main-cnt-back-btn">
            <Link to={"/admin"}>
              <button
                style={{
                  background: `${
                    clrs.CourseListBtn
                      ? clrs.CourseListBtn
                      : "hwb(224deg 78% 3%)"
                  }`,
                }}
              >
                {t("Back")}
              </button>{" "}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserFailed;
