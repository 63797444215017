import React, { useContext, useEffect, useState } from "react";
import { Box, Button } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { userScore } from "../../../../../Admin/Redux/score/Action";
import { ReactComponent as EndQuizFail } from "./icons/endQuizFail.svg";
import { ReactComponent as EndQuizPass } from "./icons/endQuizPass.svg";
import { ItemContext } from "../Context/ItemContext";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../../../Firebase";
import QuizContainer from "./QuizContainer";
import { getCookie } from "../../../../../Cookies";
import { IP } from "../../../../../config";
import axios from "axios";
const QuizEndPage = ({
  quizInfo,
  score,
  questionSet,
  quizId,
  passingScore,
}) => {
  const dispatch = useDispatch();
  const {
    itemsArray,
    courseId,
    progressIndex,
    setProgressIndex,
    showTab,
    setShowTab,
  } = useContext(ItemContext);
  const { passScore } = quizInfo?.extraInfo;
  const lala = useSelector((state) => state.userScore);
  console.log("score in last page", lala);
  const precent = Math.ceil((score / questionSet.length) * 100);
  console.log("percent in last page", precent);

  const [pass, setPass] = useState(Boolean(precent >= passScore));
  const startTime = localStorage.getItem("startTime");
  const timeTaken = Math.floor((new Date().getTime() / 1000 - startTime) / 60);
  const getItemsArray = httpsCallable(functions, "getItemsArray");
  const setQuizProgress = httpsCallable(functions, "setQuizProgress");
  const resetItemProgress = httpsCallable(functions, "resetItemProgress");
  const quizFailedUserNotificationhttp = httpsCallable(
    functions,
    "quizFailedUserNotificationhttp"
  );
  const setUserPassingScore = httpsCallable(functions, "setUserPassingScore");
  const [flag, setFlag] = useState(false);
  const [testststs, setDagsjdj] = useState(true);
  const [certificateLink, setCertificateLink] = useState("");
  const [loading, setLoading] = useState(false);
  const [nextIndex, setNextIndex] = useState(null);
  const [error, setError] = useState(null);
  //console.log("quizinfo:", quizInfo);
  const nextNode = () => {
    for (let i = progressIndex; i < itemsArray.length; i++) {
      if (itemsArray[i].type == "node") return itemsArray[i];
    }
  };
  const mainItem = itemsArray[progressIndex];
  const callApiInOne = async () => {
    console.log("calling all api in one");
    let timesNow = new Date();
    // sendMailLessonCompleted
    let sendMailLessonCompleted = {
      method: "post",
      // params: { courseId: courseId, lessonId: node.id },
      url: `${IP}/mail/sendMailLessonCompleted`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        courseId: courseId,
        lessonName: mainItem.name,
        lessonId: mainItem.id,
      }),
    };
    await axios(sendMailLessonCompleted).then((res) => {});
    // .catch((e) => {
    //   setLoading(false);
    //   setError(e.error ? e.error : "something wrong happened");
    // });
    //  nodeProgress
    let CourseInfo = {
      method: "get",
      params: { courseId: courseId },
      url: `${IP}/userItemDetails/nodeProgress`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "",
    };
    axios(CourseInfo);
    //userProgress/Lessons
    let lessonProgress = {
      method: "patch",
      // params: { courseId: courseId, topicId: node.id },
      url: `${IP}/userProgress/lessons`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        courseId: courseId,
        lessonId: mainItem.id,
        lastVisited: timesNow.toString(),
      }),
    };
    axios(lessonProgress);
    // .then((res) => {})
    // .catch((e) => {
    //   setLoading(false);
    //   setError(e.error ? e.error : "something wrong happened");
    // });

    //userProgess
    let courseProgress = {
      method: "patch",
      // params: { courseId: courseId },
      url: `${IP}/userProgress`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        completionDate: timesNow.toString(),
        courseId: courseId,
        // passingScore: score?.passingScore ? score?.passingScore : 80,
        passingScore: passingScore,
        // passingScore: lala?.score?.passingScore,
        userScore: precent,
        // userScore: score?.userScore ? score?.userScore : 90,
      }),
    };
    axios(courseProgress).then((res) => {
      console.log("course Progress is called", lala.score);
    });
    // .then((res) => {})
    // .catch((e) => {
    //   setLoading(false);
    //   setError(e.error ? e.error : "something wrong happened");
    // });

    let certificate = {
      method: "post",
      // params: { courseId: courseId },
      url: `${IP}/certificate/certificate`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        courseId: courseId,
        completionDate: timesNow.toString(),
        passingScore: passingScore,
        // passingScore: lala?.score?.passingScore,
        userScore: precent,
        // passingScore: score?.passingScore ? score?.passingScore : 80,
        // userScore: score?.userScore ? score?.userScore : 90,
      }),
    };
    await axios(certificate).then((res) => {
      console.log("i am called certificate", lala.score);
      setLoading(false);
      // setProgressIndex((prev) => prev);
      // setProgressIndex((prev) => prev - 1);
      setShowTab(0);
      setCertificateLink(res.data.link);
    });
    // .catch((e) => {
    //   setLoading(false);
    //   setError(e.error ? e.error : "something wrong happened");
    // });
  };

  const checkForLast = () => {
    let finalIdx = 0;

    for (let i = itemsArray.length - 1; i >= 0; i--) {
      const val = itemsArray[i];
      if (val.type === "quiz") {
        finalIdx = i;
        break;
      } else if (val.type === "item") {
        finalIdx = i;
        break;
      } else if (val.type === "topic") {
        finalIdx = i;
        break;
      }
    }
    return finalIdx;
  };
  const itemsArrayCallback = (result) => {
    // //console.log("topic", result.data);
    // //console.log(itemsArray)
    let index = itemsArray.findIndex((ele) => ele.id == result.data[0].id);
    // //console.log(index)
    setNextIndex(index);

    // resetItemProgress({ courseId: courseId, itemArray: result.data })

    let ItemsArray = {
      method: "post",
      params: { select: 1 },
      url: `${IP}/userItemDetails`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        courseId: courseId,
        itemArray: result.data,
      }),
    };
    axios(ItemsArray).then((res) => console.log(""));
  };

  ////console.log(progressIndex,itemsArray,nextIndex)
  useEffect(() => {
    if (!pass) {
      // find the next node item and its type,
      //extract all the items under that topic or lesson or course
      //print it

      // for(let i=progressIndex;i>=0;i--){
      //   if(itemsArray[i]?.type!=="head"){
      //     continue
      //   }else{
      //     //console.log(i)
      //     break
      //   }
      // }

      let mail = {
        method: "post",
        // params: { topicId: node.id },
        url: `${IP}/mail/sendMailFailedinQuiz`,

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: JSON.stringify({
          courseId: courseId,
          courseName: itemsArray[itemsArray.length - 1]?.name,
          // uid: getCookie("UID"),
        }),
      };
      axios(mail);
      // .then((res) => {})
      // .catch((e) => {
      //   setLoading(false);
      //   setError(e.error ? e.error : "something wrong happened");
      // });
      // quizFailedUserNotificationhttp({
      //   courseId: courseId,
      //   courseName: itemsArray[itemsArray.length - 1]?.name,
      //   uid: getCookie("UID"),
      // });

      let node = nextNode(itemsArrayCallback);
      ////console.log(node);
      if (node.nodeType == "topic") {
        // getItemsArray({ topicId: node.id })

        let ItemsArray = {
          method: "get",
          params: { topicId: node.id },
          url: `${IP}/userItemDetails`,

          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("bearer")}`,
          },
          data: JSON.stringify({
            courseId: "courseId",
          }),
        };
        axios(ItemsArray).then(itemsArrayCallback);
        // .catch((e) => {
        //   setLoading(false);
        //   setError(e.error ? e.error : "something wrong happened");
        // });
      } else if (node.nodeType == "lesson") {
        // getItemsArray({ lessonId: node.id })

        let ItemsArray = {
          method: "get",
          params: { lessonId: node.id },
          url: `${IP}/userItemDetails`,

          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("bearer")}`,
          },
          data: JSON.stringify({
            courseId: "courseId",
          }),
        };
        axios(ItemsArray).then(itemsArrayCallback);
        // .catch((e) => {
        //   setLoading(false);
        //   setError(e.error ? e.error : "something wrong happened");
        // });
      } else if (node.nodeType == "course") {
        // getItemsArray({ courseId: node.id })

        let ItemsArray = {
          method: "get",
          params: { courseId: node.id },
          url: `${IP}/userItemDetails`,

          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("bearer")}`,
          },
          data: JSON.stringify({
            courseId: "courseId",
          }),
        };
        axios(ItemsArray).then(itemsArrayCallback);
        // .catch((e) => {
        //   setLoading(false);
        //   setError(e.error ? e.error : "something wrong happened");
        // });
      }
    } else {
      setNextIndex(progressIndex + 1);
      console.log(checkForLast());

      if (progressIndex >= checkForLast()) {
        console.log("call karde bhai");
        setLoading(true);
        callApiInOne();
      }
      let les = quizInfo.extraInfo.lessonAssociated
        ? quizInfo.extraInfo.lessonAssociated
        : "";
      let top = quizInfo.extraInfo.topicAssociated
        ? quizInfo.extraInfo.topicAssociated
        : "";

      if (les == "" && top == "") {
        console.log("passingScore endquiz", passScore);
        console.log("passingScore endquiz", precent);
        console.log("i am not calling again");
        dispatch(
          userScore({
            passingScore: passScore,
            userScore: precent,
          })
        );
        setUserPassingScore({
          courseId: courseId,
          quizId: quizId,
          passingScore: passScore,

          userScore: precent,
        });
      }

      let QuizProgress = {
        method: "post",
        // params: { courseId: courseId, topicId: node.id },
        url: `${IP}/userProgress/quiz`,

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: JSON.stringify({
          courseId: courseId,
          quizId: quizId,
          lastVisited: new Date().toString(),
        }),
      };
      axios(QuizProgress);
      // .catch((e) => {
      //   setLoading(false);
      //   console.log(e);
      //   setError(e.error ? e.error : "something wrong happened");
      // });

      // setQuizProgress({
      // });
    }
  }, [pass]);

  // useEffect(() => {}, [flag]);
  return (
    <>
      {/* loading */}

      {loading && (
        <Box
          container
          spacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{ mt: 10, alignItems: "center" }}
        >
          <div className="admin-overdue-bottom-table-cnt-c ">
            <div className="admin-overdue-bottom-table-td"></div>
            <div className="admin-overdue-bottom-table-td"></div>
            <div className="admin-overdue-bottom-table-td">
              <div className="spinner-container">
                <div
                  style={{
                    width: "70px",
                    height: "70px",
                    border: "10px solid #f3f3f3" /* Light grey */,
                    borderTop: "10px solid #007C84" /* Black */,
                    borderRadius: "50%",
                    animation: "spinner 1.5s linear infinite",
                    marginBottom: "200px",
                    marginTop: "120px",
                  }}
                ></div>
              </div>
            </div>
          </div>
        </Box>
      )}
      <p style={{ color: "red" }}>{error}</p>
      {testststs && !loading && error === null ? (
        <div className="quizItem-container">
          <div style={{ margin: "2em" }}>
            {pass ? <EndQuizPass /> : <EndQuizFail />}
          </div>
          <div>
            <div className="quiziEnd-heading">
              {pass ? "Congratulations!!" : "You Failed the Quiz!"}
            </div>
            <div className="quiziEnd-score">{precent}% Score</div>
            <div className="quiziEnd-heading">
              {pass
                ? "Quiz Completed Successfully."
                : "You Need to Revise the Material."}
            </div>
            <div className="quiziEnd-subheading">
              Quiz Completion Time
              <div className="quiziEnd-subheading-detail">
                {" "}
                {startTime ? timeTaken + " Minutes" : ""}
              </div>
            </div>
            <div className="quiziEnd-subheading">
              You attempted{" "}
              <div className="quiziEnd-subheading-detail">
                {questionSet.length} Questions
              </div>{" "}
              And From That {score} Answer Is Correct.
            </div>
          </div>

          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            {pass && !loading && !error ? (
              <Button
                onClick={() => {
                  // localStorage.clear();
                  localStorage.removeItem("answer");
                  localStorage.removeItem("QuestionNum");
                  localStorage.removeItem("startTime");
                  if (progressIndex >= checkForLast()) {
                    window.open(certificateLink);
                  } else if (nextIndex !== null) {
                    setProgressIndex(nextIndex);
                  }
                }}
                className="quizItem-button"
                sx={{ color: "#fff" }}
              >
                {progressIndex >= checkForLast()
                  ? "Download certificate"
                  : "Continue"}
              </Button>
            ) : (
              <>
                {!loading && (
                  <Button
                    onClick={() => {
                      // if(itemsArray?.length-1===progressIndex){
                      //   setFlag(false)
                      //   alert("i am from end quiz")
                      //   setProgressIndex(0);
                      // }else{
                      //setDagsjdj(false)

                      //  //console.log(itemsArray[progressIndex])
                      if (nextIndex !== null) {
                        setProgressIndex(nextIndex);
                      }
                    }}
                    className="quizItem-button"
                    sx={{ color: "#fff" }}
                  >
                    Retry
                  </Button>
                )}
              </>
            )}
          </div>
        </div>
      ) : (
        <>{!loading && <QuizContainer quizId={quizId} />}</>
      )}
    </>
  );
};

export default QuizEndPage;
