import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { Box } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../../Firebase";
import { IP } from "../../../../config";
import axios from "axios";
import { getCookie } from "../../../../Cookies";

const GenerateCertificate = (props) => {
  const { onClose, open, clickedUser, courseId, setGroupUsers, courseType } =
    props;
  const handleClose = () => {
    onClose();
  };
  const today = new Date().toISOString().split("T")[0];
  const [completeDate, setCompleteDate] = useState(today);
  const [expiryDate, setExpiryDate] = useState(today);
  // uploadCertificate
  const generateCertificateTraining = httpsCallable(
    functions,
    "generateCertificateTraining"
  );
  const [loading, setLoading] = useState(false);
  const runGenerateCertificateTraining = () => {
    if (!completeDate) {
      alert("Completed Date is not filled!");
      return;
    }
    let date1 = new Date(expiryDate),
      date2 = new Date(completeDate);
    if (date1 < date2) {
      alert("Expiry Date cannot be less than the completion Date");
      return;
    }
    // //console.log("api call");
    // if ()
    setGroupUsers((prev) => {
      let index = prev.findIndex((ele) => ele.userId == clickedUser.userId);
      let newArr = Array.from(prev);
      newArr[index].completionDate = {};
      newArr[index].completeDate = completeDate;
      return newArr;
    });
    setLoading(true);
    let configgroupEnrollment = {
      method: "post",
      // params: { tempuid: data.id },
      url: `${IP}/certificate/trainingCertificate`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        uid: clickedUser.userId,
        trainingType: courseType,
        trainingId: courseId,
        courseId: courseId,
        completionDate: completeDate,
        expiry: expiryDate,
      }),
    };
    axios(configgroupEnrollment).then(() => {
      setLoading(false);
      handleClose();
    });
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="xs"
      PaperProps={{
        style: {
          borderRadius: "20px",
          height: "250px",
          padding: "16px",
        },
      }}
      onClose={() => {
        if (loading) return;
        handleClose();
      }}
      open={open}
    >
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          textAlign: "left",
          color: "#11047A",
        }}
      >
        <p>Generate certificate for {clickedUser.userName} ?</p>
        <button
          onClick={() => {
            if (loading) return;
            handleClose();
          }}
          style={{
            width: "40px",
            height: "40px",
            background: "#f4f7fe",
            marginLeft: "auto",
            borderRadius: "5px",
            border: "none",
          }}
        >
          <svg
            width="11"
            height="11"
            viewBox="0 0 11 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.43 0.244723C10.1145 -0.0707873 9.60485 -0.0707873 9.28934 0.244723L5.33333 4.19264L1.37732 0.236633C1.06181 -0.0788775 0.552143 -0.0788775 0.236633 0.236633C-0.0788775 0.552143 -0.0788775 1.06181 0.236633 1.37732L4.19264 5.33333L0.236633 9.28934C-0.0788775 9.60485 -0.0788775 10.1145 0.236633 10.43C0.552143 10.7455 1.06181 10.7455 1.37732 10.43L5.33333 6.47402L9.28934 10.43C9.60485 10.7455 10.1145 10.7455 10.43 10.43C10.7455 10.1145 10.7455 9.60485 10.43 9.28934L6.47402 5.33333L10.43 1.37732C10.7375 1.0699 10.7375 0.552143 10.43 0.244723Z"
              fill="#707EAE"
            />
          </svg>
        </button>
      </DialogTitle>
      <Box
        sx={{
          padding: "16px 24px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div style={{ width: "10vw" }}>Completion Date *</div>

          <input
            type={"date"}
            value={completeDate}
            style={{
              margin: "0.5em",
            }}
            onChange={(e) => setCompleteDate(e.target.value)}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div style={{ width: "10vw" }}>Expiry Date </div>

          <input
            type={"date"}
            value={expiryDate}
            min={completeDate}
            W
            style={{
              margin: "0.5em",
            }}
            onChange={(e) => setExpiryDate(e.target.value)}
          />
        </div>

        <Button
          fullWidth
          variant="contained"
          sx={{
            mt: "1em",
            color: "#fff",
            background: "#11047A",
            borderRadius: "70px",
          }}
          disable={loading}
          onClick={runGenerateCertificateTraining}
        >
          {loading ? "Generating..." : "Generate ?"}
        </Button>
      </Box>
    </Dialog>
  );
};

export default GenerateCertificate;
