import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";

const prod = true;
let firebaseConfig;
if (prod) {
  firebaseConfig = {
    apiKey: "AIzaSyDbRApsCD6XH_xmsh7La-AFVn_hFNaEuWI",
    authDomain: "alghaith-account-production.firebaseapp.com",
    projectId: "alghaith-account-production",
    storageBucket: "alghaith-account-production.appspot.com",
    messagingSenderId: "376103631150",
    appId: "1:376103631150:web:d96fdcb1f181546d59a2bf",
    measurementId: "G-BLJBEZ2RV5",
  };
} else {
  firebaseConfig = {
    apiKey: "AIzaSyD8C14vLsOEm-W1l_eEOvZWLiNhjNbJYtg",
    authDomain: "alghaithdev.firebaseapp.com",
    projectId: "alghaithdev",
    storageBucket: "alghaithdev.appspot.com",
    messagingSenderId: "207429325486",
    appId: "1:207429325486:web:677696b467118d5ce3933f",
    measurementId: "G-QXVS5RQ5SK",
  };
}

// Use this to initialize the firebase App
const firebaseApp = firebase.initializeApp(firebaseConfig);

// Use these for db & auth
const db = firebaseApp.firestore();
const auth = firebase.auth();
// const messaging = firebase.messaging();
export const functions = getFunctions(firebaseApp, "asia-south1");
// connectFunctionsEmulator(functions, "localhost", 5000);
export { auth, db };
