import { Button, Container, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { httpsCallable } from "firebase/functions";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";
import { getCookie } from "../../../Cookies";
import { functions } from "../../../Firebase";
import {
  displayOnDesktop,
  displayOnMobile,
  responsiveFlexDirection,
} from "../../../utilities/commonStyles/commonStyles";
import Navbar from "../Navbar/Navbar";
import ResponsiveNavbar from "../Navbar/ResponsiveNavbar";
import ConfirmPopUp from "./popUpForConfirm";
import { IP } from "../../../config";
import axios from "axios";

const AddCertificates = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const editCertificate = location.state;
  const goToHome = () => navigate("/");
  const { t } = useTranslation();
  const addPreviousCertificate = httpsCallable(
    functions,
    "addPreviousCertificate"
  );
  const [certificateDetail, setCertificateDetail] = useState({
    // employeeName: "",
    // employeeId: "",
    // employeeEmail: "",
    certificateName: "",
    refernceNumber: "",
    trainingProvider: "",
    issuedOn: "",
    expiry: "",
  });
  const [certificateId, setCertificateId] = useState("");
  const [base64Certificate, setBase64Certificate] = useState({
    file: null,
    base64: "",
  });
  const [openArchivePopup, setopenArchivePopup] = useState(false);
  const handleInput = (e) => {
    const { name, value } = e.target;
    setCertificateDetail((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (!editCertificate) return;

    let issueDate = new Date(editCertificate.issuedOn._seconds * 1000)
      .toISOString()
      .split("T")[0];
    let expiry = new Date(editCertificate.expiry._seconds * 1000)
      .toISOString()
      .split("T")[0];
    setCertificateDetail({
      certificateName: editCertificate.certificateName,
      refernceNumber: editCertificate.refernceNumber,
      trainingProvider: editCertificate.trainingProvider,
      issuedOn: issueDate,
      expiry: expiry,
    });

    setCertificateId(editCertificate.certificateId);

    // setBase64Certificate({file:{name:"Previous certificate"},base64:""})
  }, []);

  useEffect(() => {
    if (getCookie("UID") == null || getCookie("ROLE") != "user") {
      goToHome();
      return <></>;
    }
  }, []);
  const handleFileInput = (e) => {
    const { files, name } = e.target;
    const reader = new FileReader();
    reader.onloadend = () => {
      setBase64Certificate({
        file: files[0],
        base64: reader.result,
      });
    };
    reader.readAsDataURL(files[0]);
  };

  const [disabled, setDisabled] = useState(false);
  const addCertificates = () => {
    //errs:=>
    if (
      certificateDetail.certificateName == "" ||
      // certificateDetail.refernceNumber == "" ||
      certificateDetail.trainingProvider == "" ||
      certificateDetail.issuedOn == ""
    ) {
      alert("Please enter all the fields");
      return;
    }
    if (base64Certificate.base64 == "" || base64Certificate.base64 == null) {
      alert("Please upload the certificate");
      return;
    }
    if (
      new Date(certificateDetail.issuedOn).getTime() >
      new Date(certificateDetail.expiry).getTime()
    ) {
      alert("Expiry date should be greater than issued date");
      return;
    }
    // if(new Date(certificateDetail.expiry) < new Date()){
    //   alert("Expiry date should be greater than current date");
    //   return;
    // }
    if (new Date(certificateDetail.issuedOn) > new Date()) {
      alert("Issued date should Not be greater than current date");
      return;
    }
    if (certificateDetail.expiry == "") {
      setopenArchivePopup(true);
    } else {
      setDisabled(true);

      let configLesson = {
        method: "post",
        // params: { select: 0, uid: uid },
        url: `${IP}/userCourse/courseCertificate`,

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: JSON.stringify({
          certificateName: certificateDetail.certificateName,
          certificateId: certificateId,
          // employeeId: certificateDetail.employeeId,
          // employeeName: certificateDetail.employeeName,
          // employeeEmail: certificateDetail.employeeEmail,
          expiry: certificateDetail.expiry,
          issuedOn: certificateDetail.issuedOn,
          renewed: false,
          trainingProvider: certificateDetail.trainingProvider,
          trainingType: "Previous",
          refernceNumber: certificateDetail.refernceNumber,
          base64: base64Certificate.base64,
        }),
      };
      axios(configLesson)
        .then(() => {
          navigate("/user/home");
          setDisabled(false);
        })
        .catch((err) => {
          setDisabled(false);
        });
    }
  };

  const archiveUserPopUp = (type) => {
    if (type == "true") {
      setDisabled(true);
      let configLesson = {
        method: "post",
        // params: { select: 0, uid: uid },
        url: `${IP}/userCourse/courseCertificate`,

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: JSON.stringify({
          certificateName: certificateDetail.certificateName,
          certificateId: certificateId,
          // employeeId: certificateDetail.employeeId,
          // employeeName: certificateDetail.employeeName,
          // employeeEmail: certificateDetail.employeeEmail,
          expiry: certificateDetail.expiry,
          issuedOn: certificateDetail.issuedOn,
          renewed: false,
          trainingProvider: certificateDetail.trainingProvider,
          trainingType: "Previous",
          refernceNumber: certificateDetail.refernceNumber,
          base64: base64Certificate.base64,
        }),
      };
      axios(configLesson)
        .then(() => {
          navigate("/user/home");
          setDisabled(false);
        })
        .catch((err) => {
          setDisabled(false);
        });
    }
  };
  return (
    <Box>
      <Box sx={displayOnDesktop}>
        <ConfirmPopUp
          open={openArchivePopup}
          onClose={() => setopenArchivePopup(false)}
          coorId={"coordinatorId"}
          delfun={(topic) => {
            archiveUserPopUp(topic);
          }}
        />
        <Navbar page={t("Add Previous Certificates")} />
      </Box>
      <Box sx={displayOnMobile}>
        <ResponsiveNavbar page={t("Add Previous Certificates")} />
      </Box>
      <Box>
        <Container sx={{ py: 4, background: "#F5FAFA" }}>
          <Box sx={{ width: "100%", background: "#fff", p: 2 }}>
            <form action="">
              <Box
                sx={{
                  ...responsiveFlexDirection,
                  display: "flex",
                  alignItems: "flex-end",
                }}
              >
                <Box sx={{ width: { xs: "100%", md: "40%" } }}>
                  {/* <Box>
                  <Typography sx={{ fontWeight: "700", mb: 1, color: "#000" }}>
                    Employee Name
                  </Typography>
                  <input
                    type="text"
                    required
                    name="employeeName"
                    value={certificateDetail.employeeName}
                    onChange={handleInput}
                    style={{
                      width: "100%",
                      borderRadius: "6px",
                      padding: "6px 15px",
                      border: "1px solid #007C84",
                      outline: "none",
                    }}
                  />
                </Box> */}
                  {/* <Box sx={{ mt: 1 }}>
                  <Typography sx={{ fontWeight: "700", mb: 1, color: "#000" }}>
                    Employee Email
                  </Typography>
                  <input
                    type="text"
                    name="employeeEmail"
                    required
                    value={certificateDetail.employeeEmail}
                    onChange={handleInput}
                    style={{
                      width: "100%",
                      borderRadius: "6px",
                      padding: "6px 15px",
                      border: "1px solid #007C84",
                      outline: "none",
                    }}
                  />
                </Box>
                <Box sx={{ mt: 1 }}>
                  <Typography sx={{ fontWeight: "700", mb: 1, color: "#000" }}>
                    Employee ID
                  </Typography>
                  <input
                    type="text"
                    name="employeeId"
                    required
                    value={certificateDetail.employeeId}
                    onChange={handleInput}
                    style={{
                      width: "100%",
                      borderRadius: "6px",
                      padding: "6px 15px",
                      border: "1px solid #007C84",
                      outline: "none",
                    }}
                  />
                </Box>
              */}
                  <Box sx={{ mt: 1 }}>
                    <Typography
                      sx={{ fontWeight: "700", mb: 1, color: "#000" }}
                    >
                      Certificate Title *
                    </Typography>
                    <input
                      type="text"
                      name="certificateName"
                      required
                      value={certificateDetail.certificateName}
                      onChange={(e) => {
                        handleInput(e);
                        if (editCertificate) {
                          return;
                        }
                        setCertificateId(
                          e.target.value.replaceAll(" ", "") +
                            "_" +
                            Number(new Date().getTime()).toString()
                        );
                      }}
                      style={{
                        width: "100%",
                        borderRadius: "6px",
                        padding: "6px 15px",
                        border: "1px solid #007C84",
                        outline: "none",
                      }}
                    />
                  </Box>
                  <Box sx={{ mt: 1 }}>
                    <Typography
                      sx={{ fontWeight: "700", mb: 1, color: "#000" }}
                    >
                      Reference Number
                    </Typography>
                    <input
                      type="text"
                      name="refernceNumber"
                      required
                      value={certificateDetail.refernceNumber}
                      onChange={handleInput}
                      style={{
                        width: "100%",
                        borderRadius: "6px",
                        padding: "6px 15px",
                        border: "1px solid #007C84",
                        outline: "none",
                      }}
                    />
                  </Box>
                  <Box sx={{ mt: 1 }}>
                    <Typography
                      sx={{ fontWeight: "700", mb: 1, color: "#000" }}
                    >
                      Course Provider *
                    </Typography>
                    <input
                      type="text"
                      name="trainingProvider"
                      required
                      value={certificateDetail.trainingProvider}
                      onChange={handleInput}
                      style={{
                        width: "100%",
                        borderRadius: "6px",
                        padding: "6px 15px",
                        border: "1px solid #007C84",
                        outline: "none",
                      }}
                    />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "20px",
                      mt: 1,
                    }}
                  >
                    <Box sx={{ width: "50%" }}>
                      <Typography
                        sx={{ fontWeight: 600, mb: 1, color: "#000" }}
                      >
                        Date Of Issue *
                      </Typography>
                      <input
                        type="date"
                        name="issuedOn"
                        required
                        value={certificateDetail.issuedOn}
                        onChange={handleInput}
                        style={{
                          width: "100%",
                          borderRadius: "6px",
                          padding: "6px 15px",
                          border: "1px solid #007C84",
                          outline: "none",
                          color: "#000",
                          fontWeight: 500,
                        }}
                      />
                    </Box>
                    <Box sx={{ width: "50%" }}>
                      <Typography
                        sx={{ fontWeight: 600, mb: 1, color: "#000" }}
                      >
                        Expiry Date
                      </Typography>
                      <input
                        type="date"
                        name="expiry"
                        required
                        value={certificateDetail.expiry}
                        onChange={handleInput}
                        min={certificateDetail.issuedOn}
                        style={{
                          width: "100%",
                          borderRadius: "6px",
                          padding: "6px 15px",
                          border: "1px solid #007C84",
                          outline: "none",
                          color: "#000",
                          fontWeight: 500,
                        }}
                      />
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "20px",
                      mt: 2,
                    }}
                  >
                    <Typography
                      sx={{
                        width: "50%",
                        fontWeight: 600,
                        mb: 1,
                        color: "#000",
                      }}
                    >
                      Upload Certificate *
                    </Typography>
                    <Box
                      sx={{
                        width: "50%",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button
                        component="label"
                        sx={{
                          textTransform: "none",
                          width: "100px",
                          color: "#fff",
                          background: (theme) => theme.palette.primary.main,
                          "&:hover": {
                            background: (theme) => theme.palette.primary.main,
                          },
                        }}
                      >
                        {"Upload"}
                        <input type="file" hidden onChange={handleFileInput} />
                      </Button>
                    </Box>
                  </Box>
                  {base64Certificate.file && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "20px",
                        mt: 2,
                      }}
                    >
                      <Typography
                        sx={{
                          width: "50%",
                          fontWeight: 600,
                          mb: 1,
                          color: "#000",
                        }}
                      >
                        1. {base64Certificate.file?.name}
                      </Typography>
                      <Box
                        sx={{
                          width: "50%",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          component="label"
                          sx={{
                            textTransform: "none",
                            background:
                              "linear-gradient(to right, #007848, #004577)",
                            width: "100px",
                            color: "#fff",
                            fontSize: "8px",
                            fontWeight: 700,
                            fontFamily: "'Readex Pro', sans-serif",
                          }}
                          onClick={() =>
                            setBase64Certificate({ file: null, base64: "" })
                          }
                        >
                          {"Delete"}
                        </Button>
                      </Box>
                    </Box>
                  )}
                </Box>

                <Box
                  sx={{
                    width: { xs: "100%", md: "60%" },
                    display: "flex",
                    justifyContent: { xs: "center", md: "flex-end" },
                    mt: { xs: "20px", md: 0 },
                  }}
                >
                  <Button
                    sx={{
                      textTransform: "none",
                      width: "200px",
                      py: 1,
                      borderRadius: "5px",
                      color: "#FFF",
                      background: (theme) => theme.palette.primary.main,
                      "&:hover": {
                        background: (theme) => theme.palette.primary.main,
                      },
                    }}
                    onClick={addCertificates}
                    disabled={disabled}
                  >
                    {disabled ? "loading" : "Submit"}
                  </Button>
                </Box>
              </Box>
            </form>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default AddCertificates;
