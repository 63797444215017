import { Box } from "@mui/system";
import React from "react";
import CustomTimeline from "./CustomTimeline";
const CustomAccordions = ({ courseId, lessons = [] }) => {
  //console.log("LessonIds", lessons);
  return (
    <>
      <Box>
        {lessons &&
          lessons.map((lessonId, index) => (
            <CustomTimeline key={index} courseId={courseId} lessonId={lessonId} />
          ))}
      </Box>
    </>
  );
};

export default CustomAccordions;
