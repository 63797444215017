import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import {
  AccordionDownArrowIcons,
  AccordionUpArrowIcons,
  TimelineHeaderIcons,
} from "../../../utilities/Icons/Icons";
import ChildAccordian from "./ChildAccordian";
import "./CustomTimeline.css";

import { httpsCallable } from "firebase/functions";
import { Link } from "react-router-dom";
import { functions } from "../../../Firebase";
import { TimelineItemQuiz } from "./TimelineItemQuiz";
import axios from "axios";
import { IP } from "../../../config";
import { getCookie } from "../../../Cookies";

const CustomTimeline = ({ courseId, lessonId }) => {
  const getLessonInfo = httpsCallable(functions, "getLessonInfo");
  const getQuizInfo = httpsCallable(functions, "getQuizInfo");
  const [lessonInfo, setLessonInfo] = useState(null);
  useEffect(() => {
    let CourseInfo = {
      method: "get",
      params: { lessonId: lessonId },
      url: `${IP}/userCourseDetails/lesson`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "",
    };
    axios(CourseInfo).then((lessonInfo) => {
      setLessonInfo(lessonInfo.data);
      //console.log(lessonInfo.data.extraInfo);
    });
  }, []);
  const [accordion, setAccordion] = useState(null);

  return (
    <Box
      sx={{
        padding: "0px 15px",
        border: "1px solid #003A69",
        borderRadius: "5px",
        mb: "20px",
      }}
    >
      <Timeline className={"timeline"}>
        <Box>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot className={"timeline_dot_header"}>
                <TimelineHeaderIcons />
              </TimelineDot>
              {accordion && lessonInfo?.extraInfo.topics && (
                <TimelineConnector />
              )}
            </TimelineSeparator>
            <TimelineContent>
              <Box
                onClick={() => setAccordion(!accordion)}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingTop: "7px",
                }}
              >
                <Box sx={{ display: "flex" }}>
                  <Typography
                    sx={{
                      color: "#000",
                      fontWeight: 500,
                      fontSize: { xs: "12px", md: "18px" },
                      marginRight: "10px",
                    }}
                  >
                    {lessonInfo?.lessonName}
                  </Typography>
                  {lessonInfo?.extraInfo.sample && (
                    <Link
                      to={`single-course-content/${courseId}`}
                      state={{ sampleLessonId: lessonId }}
                    >
                      <Typography
                        sx={{
                          color: "#000",
                          fontWeight: 500,
                          fontSize: "18px",
                        }}
                      >
                        ( View Sample )
                      </Typography>
                    </Link>
                  )}
                </Box>
                <Box>
                  <button
                    style={{
                      background: "transparent",
                      border: "none",
                      outline: "none",
                    }}
                    onClick={() => setAccordion(!accordion)}
                  >
                    {accordion ? (
                      <AccordionDownArrowIcons />
                    ) : (
                      <AccordionUpArrowIcons />
                    )}
                  </button>
                </Box>
              </Box>
              {accordion && (
                <Box sx={{ mb: "25px" }}>
                  <Box>
                    <Typography
                      sx={{
                        color: "#000",
                        opacity: "0.5",
                        fontSize: "16px",
                      }}
                    >
                      {lessonInfo?.lessonDescription}
                    </Typography>
                    <Typography
                      sx={{
                        mt: "10px",
                        fontSize: "10px",
                        fontWeight: 500,
                        color: "#000",
                      }}
                    >
                      {lessonInfo?.extraInfo.items
                        ? lessonInfo?.extraInfo.items.length
                        : 0}{" "}
                      Items
                    </Typography>
                  </Box>
                </Box>
              )}
            </TimelineContent>
          </TimelineItem>
          {accordion && (
            <Box>
              {lessonInfo?.extraInfo.topics?.map((item, index, arr) => (
                <TimelineItem key={index} sx={{ marginBottom: "5px" }}>
                  <TimelineSeparator className={"separator_padding"}>
                    <TimelineDot className={"timeline_dot"} />
                    {(index != arr.length - 1 ||
                      lessonInfo?.extraInfo.quizzes) && <TimelineConnector />}
                  </TimelineSeparator>
                  <TimelineContent>
                    <ChildAccordian topicId={item} />
                  </TimelineContent>
                </TimelineItem>
              ))}

              {lessonInfo?.extraInfo.quizzes &&
                lessonInfo?.extraInfo.quizzes.map((quizId, index, arr) => (
                  <TimelineItemQuiz
                    quizId={quizId}
                    isLast={index == arr.length - 1}
                  />
                ))}
            </Box>
          )}
        </Box>
      </Timeline>
    </Box>
  );
};

export default CustomTimeline;
