import { Box, Button } from "@mui/material";
import React from "react";
import { ReactComponent as Calander } from "./icons/calander.svg";
import { ReactComponent as Clock } from "./icons/clock.svg";

const QuizStartPage = ({ questionSet, setMode, quizInfo }) => {
  //console.log(questionSet);
  return (
    <Box className="quizItem-container" sx={{ backgroundColor: "#FFF" }}>
      <div className="quizItem-instructions">{quizInfo?.quizName}</div>
      <div className="quizItem-overview">
        <div className="quizItem-overview-item">
          <Calander />
          <p>{questionSet?.length} Questions</p>
        </div>
        <div className="quizItem-verticalLine"></div>
        <div className="quizItem-overview-item">
          <Clock />
          <p>{quizInfo?.extraInfo.timeLimit} Minutes</p>
          <p>Total Duration of the quiz</p>
        </div>
      </div>
      <hr className="quizItem-hr" />

      <ul className="quizItem-list">
        <li className="quizItem-item">
          {quizInfo?.extraInfo.passScore} % Passing Score Will Requires & If You
          Failed You Need To Retake This Course.
        </li>
        {/* <li className="quizItem-item">Only 2 Retries Allowed.</li>
        <li className="quizItem-item">All Question Required To Be Answer.</li>
        <li className="quizItem-item">You Have Only 15 Minutes Time.</li> */}
        <li className="quizItem-item">
          Select Correct Option And Click On "Submit" Button.
        </li>
      </ul>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        {questionSet?.length > 0 ? (
          <Button
            onClick={() => {
              setMode(1);
              localStorage.removeItem("answer");
              localStorage.removeItem("QuestionNum");
              localStorage.removeItem("startTime");
            }}
            className="quizItem-button"
            sx={{ color: "#fff" }}
            disabled={questionSet == null}
          >
            Start Quiz
          </Button>
        ) : (
          <div style={{ color: "#004577" }}>
            Questions Loading Please Wait...
          </div>
        )}
      </div>
    </Box>
  );
};

export default QuizStartPage;
