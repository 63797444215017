import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Button, Container, Typography } from "@mui/material";
import { Box } from "@mui/system";
import {
  confirmPasswordReset,
  getAuth,
  onAuthStateChanged,
  verifyPasswordResetCode,
} from "firebase/auth";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  responsiveReverseFlexDirection,
  responsiveWidth,
} from "../../../utilities/commonStyles/commonStyles";
import loginImg from "../../../utilities/images/algaith.png";
import classes from "../../styles/LoginpageStyle/LoginPage.module.css";
import loginLogo from "../img/logo/logo.png";
import { IP } from "../../../config";
import axios from "axios";
import { deleteCookie } from "../../../Cookies";

function SetPassword() {
  const navigate = useNavigate();
  const goToHome = () => navigate("/");
  const goToUserDashboard = () => navigate("/user/home");
  const goToAdminDashboard = () => navigate("/admin");
  const goToInstructorDashboard = () => navigate("/instructor");
  const [clicked, setClicked] = useState(false);
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  ///=======visibility icon=======//
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const clearInputs = () => {
    setConfirmPasswordError("");
    setPassword("");
  };

  const clearErrors = () => {
    setConfirmPasswordError("");
    setPasswordError("");
  };

  //===============reset password===============//

  const setUserPassword = async () => {
    //console.log("setUserPassword");
    if (clicked) {
      return;
    }
    setClicked(true);
    clearErrors();
    const auth = getAuth();
    if (password.length < 8) {
      setClicked(false);
      setPasswordError("Password must be atleast 8 characters");
      return;
    }
    if (password !== password2) {
      setClicked(false);
      setConfirmPasswordError("Password does not match");
      return;
    }
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    let uid = params.email;
    let mode = params.mode;
    if (!uid) {
      setConfirmPasswordError("Invalid Request");
      setClicked(false);
      return;
    }
    // can make is so that if a continue url is given we can redirect to the continue url
    //console.log(value);
    try {
      // const email = await verifyPasswordResetCode(auth, value);
      //console.log(email);
      // await confirmPasswordReset(auth, value, password);
      let configProfile = {
        method: "post",
        // params: { userId: uid },
        url: `${IP}/auth/setPassword`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.idToken}`,
        },
        data: JSON.stringify({
          newPassword: password,
          confermpass: password,
          mode: mode,
          email: uid,
        }),
      };

      axios(configProfile)
        .then((res) => {
          deleteCookie();
          goToHome();

          // if (error.code == "auth/internal-error") {
          //   setConfirmPasswordError("Internal Error Occured");
          // } else if (error.code == "auth/invalid-action-code") {
          //   setConfirmPasswordError("Password reset link invalid/expired");
          // } else {
          //   setConfirmPasswordError(error.code);
          // }
          // setClicked(false);
        })
        .catch((er) => {
          console.log(er);
        });
    } catch (error) {
      // console.log(error);
      console.log(error.code);
      if (error.code == "auth/internal-error") {
        setConfirmPasswordError("Internal Error Occured");
      } else if (error.code == "auth/invalid-action-code") {
        setConfirmPasswordError("Password reset link invalid/expired");
      } else {
        setConfirmPasswordError(error.code);
      }
      setClicked(false);
      //console.log(error.code);
      // setError(error.code);
    }
  };

  const authListener = () => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        clearInputs();
        const uid = user.uid;
        // //console.log(uid);
        setUser(user);
      } else {
        setUser("");
      }
    });
  };

  useEffect(() => {
    authListener();
  }, []);

  return (
    <Box sx={{ py: { xs: "20px", md: "0" } }}>
      <Container
        sx={{
          ...responsiveReverseFlexDirection,
          height: { md: "100vh" },
          gap: "50px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            ...responsiveWidth,
            border: "3px solid #007848",
            borderRadius: "10px",
            minHeight: { md: "610px" },
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <img className={classes.logo} src={loginLogo} alt="" />
          </Box>
          <Box sx={{ p: 7 }}>
            <Box>
              <Typography
                sx={{
                  color: (theme) => theme.palette.primary.main,
                  fontWeight: 700,
                  marginTop: "-20px",
                  fontSize: { xs: "24px", md: "48px" },
                }}
              >
                Set a password
              </Typography>
              <Typography
                sx={{
                  my: 2,
                  color: (theme) => theme.palette.primary.btn,
                  fontSize: { xs: "10px", md: "18px" },
                }}
              >
                Hi, Please set up a password for your account.
              </Typography>
            </Box>
            <form
              action=""
              onSubmit={(e) => {
                e.preventDefault();
                setUserPassword();
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontWeight: "600",
                    mb: 1,
                    color: (theme) => theme.palette.primary.main,
                    fontSize: { xs: "12px", md: "16px" },
                  }}
                >
                  New Password
                </Typography>
                {/* first password */}
                <div
                  style={{
                    width: "100%",
                    borderRadius: "6px",
                    padding: "10px 15px",
                    border: "1px solid #007848",
                    outline: "none",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <input
                    type={showPassword1 ? "text" : "password"}
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                    style={{ border: "none", outline: "none", width: "90%" }}
                  />
                  <div onClick={() => setShowPassword1(!showPassword1)}>
                    {showPassword1 ? (
                      <VisibilityIcon sx={{ color: "#007848" }} />
                    ) : (
                      <VisibilityOffIcon sx={{ color: "#007848" }} />
                    )}
                  </div>
                </div>
              </Box>
              {passwordError.length > 0 && (
                <Typography style={{ color: "red" }}>
                  {passwordError}
                </Typography>
              )}
              <Box sx={{ mt: 3 }}>
                <Typography
                  sx={{
                    fontWeight: "600",
                    mb: 1,
                    color: (theme) => theme.palette.primary.main,
                    fontSize: { xs: "12px", md: "16px" },
                  }}
                >
                  Confirm New Password
                </Typography>
                {/* confirm password */}
                <div
                  style={{
                    width: "100%",
                    borderRadius: "6px",
                    padding: "10px 15px",
                    border: "1px solid #007848",
                    outline: "none",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <input
                    type={showPassword2 ? "text" : "password"}
                    placeholder="Password"
                    required
                    value={password2}
                    onChange={(e) => setPassword2(e.target.value)}
                    style={{ border: "none", outline: "none", width: "90%" }}
                  />
                  <div onClick={() => setShowPassword2(!showPassword2)}>
                    {showPassword2 ? (
                      <VisibilityIcon sx={{ color: "#007848" }} />
                    ) : (
                      <VisibilityOffIcon sx={{ color: "#007848" }} />
                    )}
                  </div>
                </div>
              </Box>
              {confirmPasswordError.length > 0 && (
                <Typography style={{ color: "red" }}>
                  {confirmPasswordError}
                </Typography>
              )}
              <br />
              {/* submit button */}
              <Box sx={{ mt: 3 }}>
                <Button
                  // onClick={setUserPassword}
                  type="submit"
                  sx={{
                    textTransform: "none",
                    width: "100%",
                    py: 1,
                    borderRadius: "15px",
                    fontSize: "16px",
                    fontWeight: 500,
                    background: (theme) => theme.palette.primary.main,
                    color: "#fff",
                    "&:hover": {
                      background: (theme) => theme.palette.primary.main,
                    },
                  }}
                >
                  {clicked ? "Processing..." : "Continue"}
                </Button>
              </Box>
            </form>
          </Box>
        </Box>

        <Box
          sx={{
            ...responsiveWidth,
            borderRadius: "10px",
            minHeight: "610px",
            maxHeight: "610px",
          }}
        >
          <Box sx={{ maxHeight: "610px" }}>
            <img
              width="100%"
              height="605px"
              style={{ borderRadius: "10px" }}
              src={loginImg}
              alt=""
            />{" "}
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default SetPassword;
