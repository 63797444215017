import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { TRANSLATIONS_AR } from "./ArTranslate";
import { TRANSLATIONS_EN } from "./EnTranslate";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: TRANSLATIONS_EN,
    },
    ar: {
      translation: TRANSLATIONS_AR,
    },
  },
});

const lang = localStorage.getItem("lang")
  ? JSON.parse(localStorage.getItem("lang"))
  : localStorage.setItem("lang", JSON.stringify("en"));

i18n.changeLanguage(lang);
