import React, { useEffect, useState } from "react";

import { db, functions } from "../../../Firebase";
import { httpsCallable } from "firebase/functions";
import { getStorage } from "firebase/storage";
import { Timestamp } from "firebase/firestore";

import "./createQuiz.css";
import { IP } from "../../../config";
import { getCookie } from "../../../Cookies";
import axios from "axios";

const PictureMCQ = ({
  quizId,
  questionData,
  editQuestion,
  setfetchDataFromApi,
  fetchDataFromApi,
  flagForSeconstEdit,
}) => {
  const storage = getStorage();

  const createQuestion = httpsCallable(functions, "createQuestion");
  const addQuestionSetOrder = httpsCallable(functions, "addQuestionSetOrder");

  const [questionName, setQuestionName] = useState("");
  const [questionId, setQuestionId] = useState("");

  const initialList = [];
  const [list, setList] = useState(initialList);

  const [picMCQ, setPicMCQ] = useState({
    url: "",
    base64: "",
    option1: "",
    option2: "",
    option3: "",
    option4: "",
    answer: 0,
  });

  function onPicMCQChange(e) {
    const { value, name, files } = e.target;
    setPicMCQ((prev) => ({
      ...prev,
      [name]: value,
    }));
  }
  function setPicMCQFile(e) {
    const { files } = e.target;
    const url = URL.createObjectURL(files[0]);
    const reader = new FileReader();
    reader.onloadend = () => {
      if (reader.result.length > 1000000) {
        alert("Image Too large, compress and try again");
        e.target.value = "";
        return;
      }
      setPicMCQ((prev) => ({ ...prev, url: url, base64: reader.result }));
      //console.log("setBase64Image done", reader.result);
    };
    reader.readAsDataURL(files[0]);

    // setPicMCQ((prev) => ({ ...prev,  }));
  }

  const [fillUpsAns, setFillUpsAns] = useState("");

  const [disableImage, setDisableImage] = useState(false);

  useEffect(() => {
    if (!editQuestion) return;
    // console.log(questionData)
    setqus(questionData.data?.question);
    setQuestionName(questionData?.name);
    setQuestionId(questionData?.id);
    setqusInstruction(questionData.data?.questionInstruction);

    let arr = {};

    arr["url"] = questionData.data?.base64;
    for (let i = 0; i < questionData.data.options.length; i++) {
      if (questionData.data.options[i].iscorrect) {
        arr["answer"] = `${i}`;
        // setPicMCQ((prev) => ({ ...prev, url: url, base64: reader.result }));
      }
      // url: "",
      // base64: "",
      // option1: "",
      // option2: "",
      // option3: "",
      // option4: "",
      // answer: 0,
      if (i == 0) {
        arr["option1"] = questionData.data.options[i].option;
      }
      if (i == 1) {
        arr["option2"] = questionData.data.options[i].option;
      }
      if (i == 2) {
        arr["option3"] = questionData.data.options[i].option;
      }
      if (i == 3) {
        arr["option4"] = questionData.data.options[i].option;
      }

      if (i == 3) {
        setPicMCQ(arr);
      }
    }
  }, [flagForSeconstEdit]);

  const [checkErrorInForm, setCheckErrorInForm] = useState({
    questionName: "",
    qus: "",
    qusInstruction: "",
    url: "",
    base64: "",
    option1: "",
    option2: "",
    option3: "",
    option4: "",
    answer: 0,
  });
  const formCheckFunction = () => {
    if (questionName === "") {
      setCheckErrorInForm((prev) => ({ ...prev, questionName: "error" }));
      return "question Name";
    }

    if (qus === "") {
      setCheckErrorInForm((prev) => ({ ...prev, qus: "error" }));
      return "question ";
    }
    if (qusInstruction === "") {
      setCheckErrorInForm((prev) => ({ ...prev, qusInstruction: "error" }));
      return "questionc ";
    }
    if (picMCQ.url === "") {
      setCheckErrorInForm((prev) => ({ ...prev, url: "error" }));
      return "questionc ";
    }
    if (picMCQ.option1 === "") {
      setCheckErrorInForm((prev) => ({ ...prev, option1: "error" }));
      return "questionc ";
    }
    if (picMCQ.option2 === "") {
      setCheckErrorInForm((prev) => ({ ...prev, option2: "error" }));
      return "questionc ";
    }
    if (picMCQ.option3 === "") {
      setCheckErrorInForm((prev) => ({ ...prev, option3: "error" }));
      return "questionc ";
    }
    if (picMCQ.option4 === "") {
      setCheckErrorInForm((prev) => ({ ...prev, option4: "error" }));
      return "questionc ";
    }
    return "alliswell";
  };

  const [qusMode, setqusMode] = useState("");
  const [qustype, setqustype] = useState("Picture MCQ");
  const [qus, setqus] = useState("");
  const [qusInstruction, setqusInstruction] = useState("");
  const [PointorScore, setPointorScore] = useState("");
  const [qus5, setqus5] = useState({
    option1: "",
    option2: "",
    option3: "",
    option4: "",
  });
  const [qus5Answer, setqus5Answer] = useState("Option 1");
  const [qus2, setqus2] = useState({ word: "", correctImage: "" });
  const [formCheck, setFormCheck] = useState("");
  function submit(e) {
    e.preventDefault();
  }

  async function saveOrder(items) {
    var orderList = [];
    for (let i = 0; i < items.length; i++) {
      orderList.push(items[i].id);
    }
    let configCreateExtraInfo = {
      method: "patch",
      // params: { questionSetId: quizId },
      url: `${IP}/courseBuilder/questionSet`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        questionSetId: quizId,
        questions: orderList,
        edit: true,
      }),
    };
    await axios(configCreateExtraInfo);
  }

  const storedDataOnFirebase = async () => {
    let check = formCheckFunction();

    let nsn = questionName.replaceAll(" ", "");

    let dat = /\//i.test(nsn);

    if (!dat) {
    } else {
      alert("you can not use any (/) characters");
      return;
    }
    if (check != "alliswell") {
      setFormCheck("check");
      return;
    }

    setFormCheck("");
    let questionTypeEnum;
    let managequestionData;

    if (qustype == "Picture MCQ") {
      let optionsFromUser = [
        {
          iscorrect: Boolean(picMCQ.answer === "0"),
          option: picMCQ.option1,
        },
        {
          iscorrect: Boolean(picMCQ.answer === "1"),
          option: picMCQ.option2,
        },
        {
          iscorrect: Boolean(picMCQ.answer === "2"),
          option: picMCQ.option3,
        },
        {
          iscorrect: Boolean(picMCQ.answer === "3"),
          option: picMCQ.option4,
        },
      ];
      let questionDatafromUser = {
        options: optionsFromUser,
        base64: picMCQ.base64 ? picMCQ.base64 : picMCQ.url,
        question: qus,
        questionInstruction: qusInstruction,
      };
      managequestionData = questionDatafromUser;
      // mcq with one pic
    }
    const docData = {
      questionName: questionName,
      questionId: questionId,
      associatedQuiz: quizId,
      questionSet: quizId,
      data: managequestionData,
      questionType: qustype,
      created: Timestamp.now(),
    };
    //console.log(docData);

    let configCreateExtraInfo = {
      method: "post",
      // params: { questionSetId: quizId },
      url: `${IP}/courseBuilder/question`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        questionId: questionId,
        questionName: questionName,
        questionType: qustype,
        created: new Date().toString(),
        data: managequestionData,
        quizId: quizId,
      }),
    };
    axios(configCreateExtraInfo).then((res) => {
      alert("data saved successfully");
      setfetchDataFromApi(fetchDataFromApi + 1);
    });

    document.getElementById(quizId + "question-form").reset();
    document.getElementById(quizId + "questionName").value = "";
    toggleQuestionForm();
  };

  const toggleQuestionForm = (e) => {
    resetData();
    document.getElementById(quizId + "questionForm").style.display =
      document.getElementById(quizId + "questionForm").style.display == "none"
        ? "block"
        : "none";
  };
  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(list);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setList(items);
    saveOrder(items);
  }

  function edit(lesson) {
    toggleQuestionForm();
  }

  function resetData() {
    setQuestionName("");
    setQuestionId("");
    setqustype("Picture MCQ");
    setqus("");
    setqusInstruction("");

    setDisableImage(false);

    setPicMCQ({
      url: "",
      base64: "",
      option1: "",
      option2: "",
      option3: "",
      option4: "",
      answer: 0,
    });
  }

  return (
    <section className="quizForm-container">
      <form id={quizId + "question-form"} onSubmit={submit}>
        <div className="quizForm-mainForm-infoRow">
          <p className="quizForm-mainForm-label">Question Name :</p>
          <input
            type="text"
            className="quizForm-mainForm-inputBar"
            value={questionName}
            style={{
              border: `${
                checkErrorInForm.questionName == "error"
                  ? "2px solid red"
                  : "1px solid #d7d7d7"
              }`,
            }}
            id={quizId + "questionName"}
            onChange={(e) => {
              setQuestionName(e.target.value);
              setCheckErrorInForm((prev) => ({ ...prev, questionName: "" }));

              let value = e.target.value.replaceAll(" ", "");
              let time = new Date().getTime();
              // setQuestionId(value + "_" + time);
              if (!editQuestion) {
                setQuestionId(value + "_" + time);
              }
            }}
          />
          {checkErrorInForm.questionName == "error" && (
            <span
              style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
            >
              Enter Question Name
            </span>
          )}
        </div>

        <div className="quizForm-mainForm-infoRow">
          <label className="quizForm-mainForm-label">Question : </label>
          <textarea
            className="quizForm-mainForm-textarea"
            value={qus}
            style={{
              border: `${
                checkErrorInForm.qus == "error"
                  ? "2px solid red"
                  : "1px solid #d7d7d7"
              }`,
            }}
            onChange={(event) => {
              setqus(event.target.value);
              setCheckErrorInForm((prev) => ({ ...prev, qus: "" }));
            }}
          ></textarea>
          {checkErrorInForm.qus == "error" && (
            <span
              style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
            >
              Enter Question
            </span>
          )}
        </div>
        <div className="quizForm-mainForm-infoRow">
          <label className="quizForm-mainForm-label">Instruction : </label>
          <textarea
            className="quizForm-mainForm-textarea"
            value={qusInstruction}
            style={{
              border: `${
                checkErrorInForm.qusInstruction == "error"
                  ? "2px solid red"
                  : "1px solid #d7d7d7"
              }`,
            }}
            onChange={(event) => {
              setqusInstruction(event.target.value);
              setCheckErrorInForm((prev) => ({ ...prev, qusInstruction: "" }));
            }}
          ></textarea>
          {checkErrorInForm.qusInstruction == "error" && (
            <span
              style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
            >
              Enter Question Instruction
            </span>
          )}
        </div>

        <div className="quizForm-mainForm-infoRow">
          <label className="quizForm-mainForm-label">
            Choose the correct Option :
          </label>
          <select
            className="quizForm-mainForm-select"
            value={picMCQ.answer}
            name={"answer"}
            onChange={onPicMCQChange}
          >
            <option value="0">Option 1</option>
            <option value="1">Option 2</option>
            <option value="2">Option 3</option>
            <option value="3">Option 4</option>
          </select>
        </div>
        <div className="quizForm-mainForm-infoRow">
          <label className="quizForm-mainForm-label">Select Image</label>
          <input
            type={"file"}
            accept="image/png, image/gif, image/jpeg"
            style={{
              border: `${
                checkErrorInForm.url == "error"
                  ? "2px solid red"
                  : "1px solid #d7d7d7"
              }`,
            }}
            onChange={(e) => {
              setPicMCQFile(e);
              setCheckErrorInForm((prev) => ({ ...prev, url: "" }));
            }}
          />
          {checkErrorInForm.url == "error" && (
            <span
              style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
            >
              Select Pic
            </span>
          )}
          {picMCQ.url && (
            <img
              src={picMCQ.url}
              style={{
                width: "250px",
                height: "200px",
                borderRadius: "5px",
              }}
            />
          )}
        </div>

        <div className="quizForm-mainForm-infoRow">
          <label className="quizForm-mainForm-label">Option 1 : </label>
          <input
            className="quizForm-mainForm-inputBar"
            name="option1"
            value={picMCQ.option1}
            style={{
              border: `${
                checkErrorInForm.option1 == "error"
                  ? "2px solid red"
                  : "1px solid #d7d7d7"
              }`,
            }}
            placeholder="Enter Option"
            onChange={(e) => {
              onPicMCQChange(e);
              setCheckErrorInForm((prev) => ({ ...prev, option1: "" }));
            }}
          />
          {checkErrorInForm.option1 == "error" && (
            <span
              style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
            >
              Enter option1
            </span>
          )}
        </div>
        <div className="quizForm-mainForm-infoRow">
          <label className="quizForm-mainForm-label">Option 2 : </label>
          <input
            className="quizForm-mainForm-inputBar"
            name="option2"
            value={picMCQ.option2}
            style={{
              border: `${
                checkErrorInForm.option2 == "error"
                  ? "2px solid red"
                  : "1px solid #d7d7d7"
              }`,
            }}
            placeholder="Enter Option"
            onChange={(e) => {
              onPicMCQChange(e);
              setCheckErrorInForm((prev) => ({ ...prev, option2: "" }));
            }}
          />
          {checkErrorInForm.option2 == "error" && (
            <span
              style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
            >
              Enter option2
            </span>
          )}
        </div>
        <div className="quizForm-mainForm-infoRow">
          <label className="quizForm-mainForm-label">Option 3 : </label>
          <input
            className="quizForm-mainForm-inputBar"
            name="option3"
            value={picMCQ.option3}
            style={{
              border: `${
                checkErrorInForm.option3 == "error"
                  ? "2px solid red"
                  : "1px solid #d7d7d7"
              }`,
            }}
            placeholder="Enter Option"
            onChange={(e) => {
              onPicMCQChange(e);
              setCheckErrorInForm((prev) => ({ ...prev, option3: "" }));
            }}
          />
          {checkErrorInForm.option3 == "error" && (
            <span
              style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
            >
              Enter option3
            </span>
          )}
        </div>
        <div className="quizForm-mainForm-infoRow">
          <label className="quizForm-mainForm-label">Option 4 : </label>
          <input
            className="quizForm-mainForm-inputBar"
            name="option4"
            value={picMCQ.option4}
            style={{
              border: `${
                checkErrorInForm.option4 == "error"
                  ? "2px solid red"
                  : "1px solid #d7d7d7"
              }`,
            }}
            placeholder="Enter Option"
            onChange={(e) => {
              onPicMCQChange(e);
              setCheckErrorInForm((prev) => ({ ...prev, option4: "" }));
            }}
          />
          {checkErrorInForm.option4 == "error" && (
            <span
              style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
            >
              Enter option4
            </span>
          )}
        </div>
      </form>
      {formCheck && <div> Fill all the Required fields.</div>}
      <div className="quizForm-mainForm-infoRow">
        <button
          className="quizFrom-button"
          style={{ marginLeft: "auto" }}
          onClick={storedDataOnFirebase}
        >
          Submit
        </button>
      </div>
    </section>
  );
};

export default PictureMCQ;
