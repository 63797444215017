import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { httpsCallable } from "firebase/functions";
import React, { useContext, useEffect, useState } from "react";
import { functions } from "../../../../../Firebase";
import {
  AccordionDownArrowIcons,
  AccordionUpArrowIcons,
  ContentCourseChildAccordionQuizIcons,
  TickCompleted,
} from "../../../../../utilities/Icons/Icons";
import ItemsTimeLine from "./ItemsTimeLine";
import QuizTimeLine from "./QuizTimeLine";
import { ItemContext } from "../Context/ItemContext";
import { IP } from "../../../../../config";
import { getCookie } from "../../../../../Cookies";
import axios from "axios";

const TopicTimeLine = ({ topicId }) => {
  const [accordion, setAccordion] = useState(null);
  const [topicInfo, setTopicInfo] = useState(null);
  const [completed, setCompleted] = useState(false);
  const { courseId } = useContext(ItemContext);

  const getTopicProgress = httpsCallable(functions, "getTopicProgress");
  const getTopicInfo = httpsCallable(functions, "getTopicInfo");
  useEffect(() => {
    // getTopicInfo({ topicId: topicId })

    let CourseInfo = {
      method: "get",
      params: { topicId: topicId },
      url: `${IP}/userCourseDetails/topic`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "",
    };
    axios(CourseInfo).then((result) => setTopicInfo(result.data));
    // getTopicProgress({ courseId: courseId, topicId: topicId })

    let quizProgress = {
      method: "get",
      params: { courseId: courseId, topicId: topicId },
      url: `${IP}/userProgress/topic`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "",
    };
    axios(quizProgress).then((result) => {
      if (result.data.error === "not present") {
        setCompleted(false);
      } else if (result.data.completed === true) {
        setCompleted(true);
      }
    });
  }, []);

  return (
    <Box>
      <Timeline className={"timeline"}>
        <Box>
          <TimelineItem className={"timeline_firstItem"}>
            <TimelineSeparator>
              <TimelineDot className={"timeline_dot_header"}>
                <svg
                  width="18"
                  height="17"
                  viewBox="0 0 18 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.58255 0.142795H14.7649C16.1741 0.142795 17.1552 1.12599 17.1553 2.53795C17.1556 5.13475 17.1556 7.73156 17.1553 10.3284C17.1553 11.7397 16.1736 12.723 14.7642 12.7231C10.6367 12.7234 6.50922 12.7234 2.38166 12.7231C0.986991 12.7231 0.000405845 11.7339 0.000262606 10.3366C-8.75352e-05 7.73383 -8.75352e-05 5.13104 0.000262606 2.52825C0.000262606 1.13286 0.988614 0.143177 2.38233 0.14289C4.44909 0.142508 6.51582 0.142477 8.58255 0.142795ZM5.72253 3.00219C5.04396 3.00141 4.3804 3.20188 3.81577 3.57823C3.25113 3.95458 2.81077 4.48992 2.55039 5.11655C2.29001 5.74317 2.22129 6.43294 2.35293 7.09862C2.48456 7.76429 2.81064 8.37599 3.28993 8.85634C3.76922 9.3367 4.38018 9.66414 5.04557 9.79726C5.71095 9.93038 6.40087 9.8632 7.02807 9.60421C7.65527 9.34522 8.19159 8.90606 8.5692 8.34226C8.94681 7.77846 9.14875 7.11535 9.14949 6.43678C9.14902 5.52733 8.78806 4.65514 8.14569 4.01135C7.50332 3.36756 6.63194 3.00466 5.72249 3.00219H5.72253ZM14.8596 3.58598H10.3077V4.70803H14.8596V3.58598ZM14.864 5.87232H10.3067V6.99031H14.8644L14.864 5.87232ZM14.8598 8.15747H10.3046V9.27951H14.8602L14.8598 8.15747ZM4.58255 16.1427V15.0176H6.85285V13.8796H10.2906V15.0054H12.567V16.1427H4.58255ZM7.61751 7.70754C7.86972 7.33183 8.0048 6.88974 8.00566 6.43722C8.00521 5.83188 7.76542 5.25128 7.3386 4.82202C6.91177 4.39277 6.33253 4.1497 5.7272 4.14582C5.27469 4.14412 4.83184 4.2767 4.4547 4.52678C4.07757 4.77685 3.78309 5.13319 3.60854 5.55069C3.434 5.96819 3.38723 6.42808 3.47415 6.87217C3.56107 7.31626 3.77778 7.72459 4.09685 8.04547C4.41592 8.36635 4.82301 8.58537 5.2666 8.6748C5.71019 8.76424 6.17034 8.72007 6.58882 8.54789C7.0073 8.37571 7.3653 8.08326 7.61751 7.70754ZM4.87316 4.80119L7.32203 6.43302L4.87316 8.06509V4.80119Z"
                    fill="#007C84"
                  />
                </svg>
              </TimelineDot>
              {accordion && <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent>
              <Box
                onClick={() => setAccordion(!accordion)}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingTop: "2px",
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontSize: "10px",
                      fontFamily: "Lato",
                      color: "#007C84",
                      fontWeight: 700,
                    }}
                  >
                    {topicInfo?.topicName}
                  </Typography>
                </Box>
                {completed && (
                  <TickCompleted
                    style={{ marginLeft: "auto", marginRight: "30px" }}
                  />
                )}
                <Box>
                  <button
                    style={{
                      background: "transparent",
                      border: "none",
                      outline: "none",
                      padding: "0px",
                    }}
                    onClick={() => setAccordion(!accordion)}
                  >
                    {accordion ? (
                      <AccordionDownArrowIcons />
                    ) : (
                      <AccordionUpArrowIcons />
                    )}
                  </button>
                </Box>
              </Box>
            </TimelineContent>
          </TimelineItem>
          {accordion && (
            <Box>
              {topicInfo?.extraInfo.items?.map((itemId, index, arr) => (
                <ItemsTimeLine
                  key={index}
                  itemId={itemId}
                  connect={
                    topicInfo.extraInfo.quizzes || index != arr.length - 1
                  }
                />
              ))}

              {/* quizItem */}
              {topicInfo?.extraInfo.quizzes?.map((quizId, index, arr) => (
                <QuizTimeLine
                  key={index}
                  quizId={quizId}
                  connect={index != arr.length - 1}
                />
              ))}
            </Box>
          )}
        </Box>
      </Timeline>
    </Box>
  );
};

/* export const CustomTimelineSeparator = () => (
    <TimelineSeparator className={'separator_content_padding'}>
        <TimelineDot className={'timeline_dot'} />
        <TimelineConnector />
    </TimelineSeparator>
) */

export default TopicTimeLine;
