import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import { Box, Typography } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import React, { useContext, useEffect, useState } from "react";
import { functions } from "../../../../../Firebase";
import {
  ContentCourseWebIcons,
  ContentCoursePdfIcons,
  ContentCourseVideoIcons,
  TickCompleted,
} from "../../../../../utilities/Icons/Icons";
import { ItemContext } from "../Context/ItemContext";
import { IP } from "../../../../../config";
import { getCookie } from "../../../../../Cookies";
import axios from "axios";

const ItemsTimeLine = ({ first, itemId, connect }) => {
  const getItemInfo = httpsCallable(functions, "getItemInfo");
  const getItemProgress = httpsCallable(functions, "getItemProgress");
  const [itemInfo, setItemInfo] = useState(null);
  const [completed, setCompleted] = useState(false);
  const { courseId, itemsArray, progressIndex, setProgressIndex, isLinear } =
    useContext(ItemContext);
  const access = first || !isLinear;
  useEffect(() => {
    // setItemInfo(null);
    // getItemInfo({ itemId: itemId })

    let CourseInfo = {
      method: "get",
      params: { itemId: itemId },
      url: `${IP}/userCourseDetails/item`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "",
    };
    axios(CourseInfo).then((result) => setItemInfo(result.data));
  }, []);

  useEffect(() => {
    if (completed) return; // reduce api calls
    // getItemProgress({ courseId: courseId, itemId: itemId })
    //

    let itemProgress = {
      method: "get",
      params: { courseId: courseId, itemId: itemId },
      url: `${IP}/userProgress/items`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "",
    };
    axios(itemProgress).then((result) => {
      if (result.data.error === "not present") {
        setCompleted(false);
      } else if (result.data.completed === true) {
        setCompleted(true);
      }
      //console.log(result);
    });
  }, [progressIndex]);

  const setMainItem = (element) => element.id == itemId;

  return (
    <TimelineItem>
      <TimelineSeparator className={"separator_content_padding"}>
        <TimelineDot className={"timeline_dot"} />
        {connect && <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent>
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            pt: "2px",
          }}
        >
          {itemInfo?.itemType == "html" && <ContentCourseWebIcons />}
          {itemInfo?.itemType == "pdf" && <ContentCoursePdfIcons />}
          {itemInfo?.itemType == "video" && <ContentCourseVideoIcons />}
          <Typography
            sx={{
              fontSize: "12px",
              fontFamily: "Lato",
              color: "#007C84",
              fontWeight: 700,
              cursor: completed || access ? "pointer" : "not-allowed",
            }}
            onClick={() => {
              if (completed || access)
                setProgressIndex(itemsArray.findIndex(setMainItem));
            }}
          >
            {itemInfo?.itemName}
          </Typography>
          {completed && (
            <TickCompleted
              style={{ marginLeft: "auto", marginRight: "30px" }}
            />
          )}
        </Box>
      </TimelineContent>
    </TimelineItem>
  );
};

export default ItemsTimeLine;
