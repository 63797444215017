import React from "react";
import { useState, setState } from "react";
import { auth, db, functions } from "../../../Firebase";
import "../StyleAdminComponents/GroupList.css";
import { Link, useParams } from "react-router-dom";
import { getCookie } from "../../../Cookies";
import { useLocation, useNavigate } from "react-router";
import { useEffect } from "react";

import Navbar from "../../Component/NavbarTop";
import Sidebar from "../../Component/Sidebar";
import { t } from "i18next";
import { useSelector } from "react-redux";
import GroupMembers from "./Components/GroupMembers";
import ListOfAllUsers from "./Components/ListOfAllUser";
import { httpsCallable } from "firebase/functions";
import { IP } from "../../../config";
import axios from "axios";

function GroupEdits() {
  const navigate = useNavigate();
  const goToHome = () => navigate("/");
  const getUserList = httpsCallable(functions, "getUserList");
  const editGroup = httpsCallable(functions, "editGroup");
  const updateGroupMembers = httpsCallable(functions, "updateGroupMembers");

  const { clrs } = useSelector((state) => state.createClr);

  useEffect(() => {
    if (
      getCookie("UID") == null ||
      (getCookie("ROLE") != "admin" && getCookie("ROLE") != "instructor")
    ) {
      goToHome();
      return <></>;
    }
  }, []);

  const data = useLocation();

  let editGroupList = data.state;
  //console.log(data.state);
  const [groupDetails, setGroupDetails] = useState({
    groupName: "",
    groupDesc: "",
  });
  const [userList, setUserList] = useState();
  const [groupMembers, setGroupMembers] = useState([]);
  const [groupId, setGroupId] = useState("");
  // const [isChecked, setIsChecked] = useState(false);
  // const [newSetuserList, setNewSetuserList] = useState([]);
  // const [newDataGroupMembers, setNewDataGroupMembers] = useState([]);
  // const [flagState, setFlagState] = useState(true);
  const [editgroup, setEditGroup] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [dataList, setDataList] = useState([]);
  // const { id } = useParams();

  const saveGroup = () => {
    setButtonLoading(true);
    let memberList = groupMembers.map((ele) => ele.userId);
    // //console.log("rahul"+memberList)

    if (editGroupList) {
      console.log(editGroupList.groupId);

      let configLesson = {
        method: "patch",
        // params: { group: true },
        url: `${IP}/group/updateMember`,

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: JSON.stringify({
          groupName: groupDetails.groupName,
          groupDescription: groupDetails.groupDesc,
          groupId: editGroupList.groupId,
          newMembers: memberList,
        }),
      };
      axios(configLesson).then((res) => {
        // //console.log(res.data);
        setButtonLoading(false);
        navigate("/admin/groups", { state: { formGroup: true } });
      });

      setGroupMembers([]);
    } else {
      if (memberList.length == 0) {
        alert("minimum one member should have in group");
        setButtonLoading(false);
        return;
      }
      if (groupId == "" || groupDetails.groupName == "") {
        alert("groupName required");
        setButtonLoading(false);
        return;
      }
      if (groupDetails.groupDesc == "") {
        alert("group Description Required");
        setButtonLoading(false);
        return;
      }

      let configgroupEnrollment = {
        method: "patch",
        // params: { tempuid: data.id },
        url: `${IP}/group`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: JSON.stringify({
          groupName: groupDetails.groupName,
          groupDescription: groupDetails.groupDesc,
          createdOn: new Date(),
          // createdBy: auth.currentUser?.uid,
          groupId: groupId,
          groupMembers: memberList,
        }),
      };
      axios(configgroupEnrollment)
        .then((res) => {
          // //console.log(res)
          setButtonLoading(false);
          navigate("/admin/groups", { state: { formGroup: true } });

          let configgroupEnrollment = {
            method: "post",
            // params: { tempuid: data.id },
            url: `${IP}/mail/sendMailGroupCreated`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getCookie("bearer")}`,
            },
            data: JSON.stringify({
              groupName: groupDetails.groupName,
            }),
          };
          axios(configgroupEnrollment);
        })
        .catch((err) => {
          setButtonLoading(false);

          alert("Some INTERNAL Error ");
        });
    }
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    if (name == "groupName") {
      setGroupId(
        value.replaceAll(" ", "") + "_" + new Date().getTime().toString()
      );
    }
    setGroupDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleRemove = async (e) => {
    e.preventDefault();

    //console.log(groupMembers);

    let tempaarr = groupMembers.filter((ele) => ele.checked == true);
    tempaarr.forEach((element, id) => {
      tempaarr[id] = { ...tempaarr[id], checked: false, nu: id };
    });

    //console.log(tempaarr);

    //  //console.log(tempaarr)
    //   let newarrdata=[...tempaarr]

    // //   //console.log(tempaarr)
    // for(let k=0;k<newarrdata.length;k++){

    //   Object.assign(newarrdata[k], { checked:false })

    // }
    //  //console.log(newarrdata)
    setUserList(tempaarr.concat(userList));
    setDataList(tempaarr.concat(dataList));
    setGroupMembers((prev) => {
      return prev.filter((ele) => ele.checked == false);
    });
  };

  const handleAdd = (e) => {
    e.preventDefault();
    // //console.log(groupMembers)
    // //console.log(userList)

    let objtemp = {};
    userList?.map((element) => {
      if (element.checked == true) {
        objtemp[element.userId] = element;
      }
    });

    groupMembers?.map((element) => {
      objtemp[element.userId] = element;
      return;
    });
    let temparr = Object.values(objtemp);
    setGroupMembers(
      temparr?.map(
        (e) => ({ ...e, checked: false })
        // userList
        //   ?.filter((element) => element.checked == true)
        //   .map((element) => ({ ...element, checked: false }))
      )
    );

    setUserList(userList?.filter((element) => element.checked != true));
    setDataList(dataList?.filter((element) => element.checked != true));
    //console.log("I am from newUser List", userList);
  };

  // useEffect(() => {
  //   if(!editGroupList)
  //   getUserList({}).then((result) => {

  //     if (result.error) return;
  //     setUserList(result.data?.map((res) => ({ ...res, checked: false })));
  //      //console.log(result.data)
  //   });
  // }, []);
  // //console.log("rahul",newSetuserList)
  //  //console.log(dataarr)

  useEffect(() => {
    if (editGroupList) {
      setEditGroup(true);
      let dataarr = [];
      let configLesson = {
        method: "get",
        params: { group: true },
        url: `${IP}/adminUserProfile/userList?limit=${10}`,

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: "dataLesson",
      };
      axios(configLesson).then((result) => {
        if (result.error) return;
        let resData = result.data.data;
        let newresDataFilter = [];
        // setNewSetuserList(result.data);
        for (let j = 0; j < result.data.data.length; j++) {
          let moved = false;
          for (let i = 0; i < editGroupList?.groupMembers?.length; i++) {
            if (editGroupList.groupMembers[i] == result.data.data[j].userId) {
              moved = true;
              resData[j] = { ...result.data.data[j], checked: false };

              dataarr.push(result.data.data[j]);
            }
          }
          if (!moved) {
            newresDataFilter.push({
              ...result.data.data[j],
              checked: false,
              nu: j,
            });
          }
        }

        console.log(newresDataFilter);
        setUserList(newresDataFilter);
        setDataList(newresDataFilter);

        setGroupDetails({
          groupName: editGroupList.groupName,
          groupDesc: editGroupList.groupDescription,
        });

        setGroupMembers(dataarr);
      });
    } else {
      let configLesson = {
        method: "get",
        params: { group: true },
        url: `${IP}/adminUserProfile/userList?limit=${10}`,

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: "dataLesson",
      };
      axios(configLesson).then((result) => {
        if (result.error) return;
        //console.log(result.data)
        let data = result.data.data;
        setUserList(
          data
            ?.map((res) => ({ ...res, checked: false }))
            .filter((ele) => !ele.archived)
        );
        setDataList(
          data
            ?.map((res, id) => ({ ...res, checked: false, nu: id }))
            .filter((ele) => !ele.archived)
        );
        //  //console.log(result.data)
      });
    }
  }, []);

  ////console.log(dataarr)

  return (
    <div className="section" style={{ backgroundColor: "#e5e5e5" }}>
      <Navbar page={t("Groups")} />
      <div className="createcourse-main">
        <Sidebar page={"groups"} hidepage={true} />
        <div className="admin-overdue">
          <div className="admin-overdue-top">
            <div className="admin-overdue-top-item">
              {t("AddNewGroupEditGroup")}
            </div>
          </div>
          <div
            className="admin-overdue-mid-grp "
            style={{ backgroundColor: "#E5E5E5", margin: "0 0vw" }}
          >
            <div className="group-input-row">
              <div
                className="admin-dashboard-main-mid-item-txt"
                style={{ width: "20vw" }}
              >
                {t("GroupName")}
              </div>

              <input
                className="group-input-row-head"
                type="text"
                style={{ color: "#000" }}
                value={groupDetails.groupName}
                name="groupName"
                onChange={handleInput}
              />
            </div>
            <div className="group-input-row">
              <div
                className="admin-dashboard-main-mid-item-txt "
                style={{ width: "20vw" }}
              >
                {t("GroupDescription")}
              </div>

              <textarea
                className="group-input-row-head"
                value={groupDetails.groupDesc}
                name="groupDesc"
                onChange={handleInput}
              />
            </div>
          </div>
          <div className="admin-overdue">
            <div style={{ height: "8vw" }} className="courselist-main-cnt-top">
              {/*     <div className="courselist-main-cnt-top-txt">Group List</div> */}
              <div
                style={{
                  background: `${
                    clrs.CourseListBtn
                      ? clrs.CourseListBtn
                      : "hwb(224deg 78% 3%)"
                  }`,
                  marginTop: "4.9vw",
                  marginLeft: "0vw",
                  width: "22vw",
                  height: "3vw",
                  paddingTop: "0.5vw",
                }}
                className="head"
              >
                {t("ListOfGroupMember")}
              </div>
              <div>
                <button
                  style={{
                    background: `${
                      clrs.CourseListBtn
                        ? clrs.CourseListBtn
                        : "hwb(224deg 78% 3%)"
                    }`,
                    width: "11vw",
                    height: "6vh",
                    fontWeight: "500",
                    fontSize: "1.5vw",
                    marginTop: "3vw",
                  }}
                  onClick={handleRemove}
                >
                  {t("Remove")}
                </button>
              </div>
            </div>
            <GroupMembers
              groupMembers={groupMembers}
              setGroupMembers={setGroupMembers}
              editGroupList={editgroup}
            />
          </div>

          {/* <div className="admin-dashboard-main-mid">
            <div className="admin-dashboard-main-mid-item">
              <div className="admin-dashboard-main-mid-item-txt">{t("CompanyName")}</div>
              <div>
                <select name="company" id="company">
                  <option value="demo">Select Company</option>\
                </select>
              </div>
            </div>
            <div className="admin-dashboard-main-mid-item">
              <div className="admin-dashboard-main-mid-item-txt">{t("Department")}</div>
              <div>
                <select name="SelectGroup" id="SelectGroup">
                  <option value="demo">Select Group</option>\
                </select>
              </div>
            </div>
            <div className="admin-dashboard-main-mid-item">
              <div className="admin-dashboard-main-mid-item-txt">{t("EmailID")}</div>
              <div>
                <select name="SelectCourseName" id="SelectCourseName">
                  <option value="demo">Select Course Name</option>\
                </select>
              </div>
            </div>
            <div className="admin-dashboard-main-mid-item">
              <div className="admin-dashboard-main-mid-item-txt">{t("JoiningDate")}</div>
              <div>
                <input type="date" />
              </div>
            </div>
            <div className="admin-dashboard-main-mid-item-last">
              <div className="admin-dashboard-main-top-last" style={{ paddingTop: "1.5vw" }}>
                <button
                  style={{
                    background: `${clrs.CourseListBtn ? clrs.CourseListBtn : "hwb(224deg 78% 3%)"}`,
                    height: "4vh",
                    marginTop: "0.7vw",
                  }}
                >
                  <div>
                    <svg
                      width="auto"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M14.5411 0.306653C14.4915 0.270984 14.4322 0.25 14.3711 0.25H1.96654C1.3371 0.25 0.481658 0.546574 0.872148 1.04025C1.11132 1.34261 1.3505 1.64497 1.58968 1.94732C2.90969 3.61597 4.22969 5.28461 5.54797 6.95471C5.60263 7.02401 5.65578 7.0522 5.74312 7.05203C7.07158 7.04905 8.40008 7.04905 9.72854 7.05206C9.81602 7.05226 9.86925 7.02387 9.92375 6.95477C10.8668 5.75927 11.8117 4.56525 12.7565 3.37123C12.9509 3.12564 13.1452 2.88004 13.3396 2.63444C13.4306 2.51932 13.5217 2.40421 13.6128 2.28911C13.9566 1.85476 14.3003 1.42041 14.6435 0.985631C14.7914 0.798352 14.8165 0.605055 14.6948 0.442077C14.6546 0.388249 14.5979 0.347451 14.5411 0.306653ZM9.39023 8.05537C9.39023 7.9714 9.32216 7.90332 9.23818 7.90332H6.21498C6.14052 7.90332 6.08016 7.96368 6.08016 8.03814C6.08016 8.56841 6.08014 9.09867 6.08012 9.62894C6.08006 11.1763 6.08 12.7236 6.08047 14.2709C6.08058 14.675 6.38111 14.8623 6.7375 14.6802C7.02361 14.534 7.30944 14.3872 7.59527 14.2404C8.09356 13.9845 8.59185 13.7286 9.09161 13.4758C9.30188 13.3694 9.39333 13.2126 9.39264 12.9707C9.38915 11.7332 9.38956 10.4956 9.38997 9.25803C9.3901 8.85715 9.39023 8.45626 9.39023 8.05537Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <div>{t("Filter")}</div>
                </button>
              </div>
            </div>
          </div> */}
          <div className="admin-overdue">
            <div style={{ height: "8vw" }} className="courselist-main-cnt-top">
              {/*     <div className="courselist-main-cnt-top-txt">Group List</div> */}
              <div
                style={{
                  background: `${
                    clrs.CourseListBtn
                      ? clrs.CourseListBtn
                      : "hwb(224deg 78% 3%)"
                  }`,
                  marginTop: "4.9vw",
                  marginLeft: "0vw",
                  width: "14vw",
                  height: "3vw",
                  paddingTop: "0.5vw",
                }}
                className="head"
              >
                {t("ListOfAllUser")}
              </div>
              <div>
                <button
                  style={{
                    background: `${
                      clrs.CourseListBtn
                        ? clrs.CourseListBtn
                        : "hwb(224deg 78% 3%)"
                    }`,
                    width: "12vw",
                    height: "6vh",
                    fontWeight: "500",
                    fontSize: "1.5vw",
                    marginTop: "3vw",
                  }}
                  onClick={handleAdd}
                >
                  {t("AddInGroup")}
                </button>
              </div>
            </div>
            <ListOfAllUsers
              userList={userList}
              setUserList={setUserList}
              dataList={dataList}
            />
          </div>
        </div>
        <div
          style={{ height: "100%", width: "6vw", backgroundColor: "white" }}
        ></div>

        <div
          className="courselist-main-cnt-back-btn"
          style={{ margin: "2vw", display: "grid", placeItems: "center" }}
        >
          {buttonLoading ? (
            <button
              style={{
                background: `${
                  clrs.CourseListBtn ? clrs.CourseListBtn : "hwb(224deg 78% 3%)"
                }`,
                color: "white",
                border: "none",
                height: "4vh",
                width: "10vw",
                borderRadius: "6px",
              }}
            >
              {t("Loading...")}
            </button>
          ) : (
            <button
              onClick={saveGroup}
              style={{
                background: `${
                  clrs.CourseListBtn ? clrs.CourseListBtn : "hwb(224deg 78% 3%)"
                }`,
                color: "white",
                border: "none",
                height: "4vh",
                width: "10vw",
                borderRadius: "6px",
              }}
            >
              {t("SaveUpdate")}
            </button>
          )}
        </div>

        <div
          style={{ height: "100%", width: "6vw", backgroundColor: "white" }}
        ></div>
        <div className="courselist-main-cnt-back">
          <div className="courselist-main-cnt-back-btn">
            <Link to={"/admin/groups"}>
              <button
                style={{
                  background: `${
                    clrs.CourseListBtn
                      ? clrs.CourseListBtn
                      : "hwb(224deg 78% 3%)"
                  }`,
                }}
              >
                {t("Back")}
              </button>{" "}
            </Link>
          </div>
        </div>
      </div>
    </div>
    // <>
  );
}

export default GroupEdits;
