import Checkbox from "@mui/material/Checkbox";
import React, { createRef, useContext, useEffect, useState } from "react";
import { CustomAudioPlayer } from "./CustomAudioPlayer";
import { ItemContext } from "../Context/ItemContext";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../../../Firebase";
import { IP } from "../../../../../config";
import { getCookie } from "../../../../../Cookies";
import axios from "axios";
const HtmlItem = ({ url, setCanNavigate, itemId }) => {
  const [completed, setCompleted] = useState(false);
  const { courseId, isLinear } = useContext(ItemContext);
  const getItemProgress = httpsCallable(functions, "getItemProgress");
  const setHTMLProgress = httpsCallable(functions, "setHTMLProgress");

  useEffect(() => {
    // getItemProgress({ courseId: courseId, itemId: itemId })
    let itemProgress = {
      method: "get",
      params: { courseId: courseId, itemId: itemId },
      url: `${IP}/userProgress/items`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "",
    };
    axios(itemProgress).then((result) => {
      if (result.data.error === "not present") {
        setCompleted(false);
      } else if (result.data.completed === true) {
        setCompleted(true);
      }
      //console.log(result);
    });
    return () => {};
  }, []);

  useEffect(() => {
    //console.log(completed);
    if (completed) {
      let itemProgress = {
        method: "post",
        // params: { courseId: courseId, itemId: itemId },
        url: `${IP}/userCourseContent/html`,

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: JSON.stringify({
          courseId: courseId,
          itemId: itemId,
          lastVisited: new Date().toString(),
        }),
      };
      axios(itemProgress);
      // setHTMLProgress({

      // });
    }
    setCanNavigate(completed || !isLinear);
  }, [completed]);

  return (
    <div className="htmlItem-container">
      <iframe src={url} className="htmlItem-iframe">
        Something
      </iframe>
      <div className="markCompleted">
        <Checkbox
          aria-label="Mark Completed"
          onChange={(e) => setCompleted(e.target.checked)}
          checked={completed}
          sx={{
            color: "#007c84",
            "&.Mui-checked": {
              color: "#007C84",
            },
          }}
        />
        Mark Completed
      </div>
      <CustomAudioPlayer
        url={
          "https://firebasestorage.googleapis.com/v0/b/alghaithdev.appspot.com/o/download?alt=media&token=7ac7a6ad-17a7-4d0b-b85a-a7033c067203"
        }
      />
    </div>
  );
};
export default HtmlItem;
