import { Box, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { DragIcons } from "../../../../../../utilities/Icons/Icons";
function Rearange(props) {
  const { question, instruction, qno, qtot, setActiveSubmit } = props;

  const [options, setOptions] = useState(question.options);
  const checkAnswer = (arr) => {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].position != i + 1) {
        //console.log(i);
        return false;
      }
    }
    return true;
  };
  const handleOnDragEnd = (result) => {
    const { destination, source } = result;
    let newArr = Array.from(options);
    if (destination == null) return;
    else if (destination.droppableId === source.droppableId) {
      let item = newArr[source.index];
      newArr.splice(source.index, 1);
      newArr.splice(destination.index, 0, item);
      setOptions(newArr);
    }
    setActiveSubmit(true);
    //console.log(checkAnswer(newArr));
    //console.log(newArr);
    let ans = {
      type: "rearrange",
      qno: qno,
      correct: checkAnswer(newArr),
    };
    localStorage.setItem("answer", JSON.stringify(ans));
  };
  return (
    <Box sx={{ padding: "30px 30px 30px 15px" }}>
      <Stack spacing={2}>
        <Box>
          <Typography
            sx={{
              color: "#000",
              fontWeight: 600,
              fontSize: { xs: "12px", md: "16px" },
            }}
          >
            Question {qno} of {qtot}
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={{
              color: "#000",
              fontWeight: 600,
              fontSize: { xs: "12px", md: "16px" },
            }}
          >
            Question : {question.question}
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={{
              color: "#fff",
              background: "#003A69",
              fontWeight: 700,
              padding: { xs: "10px 15px", md: "10px 40px" },
              borderRadius: "5px",
              fontSize: { xs: "12px", md: "16px" },
            }}
          >
            Instruction: {instruction}
          </Typography>
        </Box>
      </Stack>
      <Box sx={{ my: "30px" }}>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="options">
            {(provided) => (
              <Box ref={provided.innerRef} {...provided.droppableProps}>
                {options &&
                  options.map((item, id) => {
                    return (
                      <DragItem
                        txt={item.text}
                        id={"" + id}
                        index={id}
                        key={"key" + id}
                      />
                    );
                  })}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
    </Box>
  );
}

const DragItem = (props) => {
  const { txt, id, index } = props;
  return (
    <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <Box sx={{ width: { xs: "90%", md: "60%" } }}>
        <Draggable draggableId={id} index={index}>
          {(provided) => (
            <Box
              sx={{
                background: "#003A69",
                display: "flex",
                alignItems: "center",
                mb: "15px",
                borderRadius: "5px",
                padding: { xs: "5px 10px", md: "8px 16px" },
              }}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <Box>
                <DragIcons />
              </Box>
              <Typography
                sx={{
                  color: "#fff",
                  fontWeight: 700,
                  fontFamily: "'Lato', sans-serif",
                  mt: "-3px",
                  fontSize: { xs: "12px", md: "16px" },
                }}
              >
                {txt}
              </Typography>
            </Box>
          )}
        </Draggable>
      </Box>
    </Box>
  );
};

export default Rearange;

{
  /* <div className="mcq">
      <div className="mcq-main">
        <div className="mcq-main-question-no">
          Questin {qno} of {qtot}
        </div>
        <div className=" mcq-main-questin">Questin : {question.question}</div>
        <div className="mcq-main-inst">{instruction}</div>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="options">
            {(provided) => (
              <div
                ref={provided.innerRef}
                className="mcq-main-options"
                {...provided.droppableProps}
              >
                {options &&
                  options.map((item, id) => {
                    return (
                      <DragItem
                        txt={item}
                        id={"" + id}
                        index={id}
                        key={"key" + id}
                      />
                    );
                  })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div> */
}
