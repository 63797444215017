import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./AdminSetting.css";
import Sidebar from "../../Component/Sidebar";
import NavbarTop from "../../Component/NavbarTop";
import { t } from "i18next";
import { useSelector } from "react-redux";

function AdminSettings() {
  
  const { clrs } = useSelector((state) => state.createClr);
  return (
    <>
    
    <section className="dashboard">
      <NavbarTop page={t("Settings")} />
      {/* <Navbar /> */}

      <div style={{ height: "41vw" }} className="dashboard-main">
        <Sidebar page={"Dashboard"} hidepage={false} />
        <div className="admin-dashboard-main srollbar_div">

        <div className="admin-dashboard-main-bottom">
        <div style={{ width: "24vw" }} className="admin-dashboard-main-bottom-item">
              <div className="admin-dashboard-main-bottom-item-left">
                <div className="admin-dashboard-main-bottom-item-left-top">
                  {t("Certificate Settings")}
                </div>
                <div className="admin-dashboard-main-bottom-item-left-mid">
                  {t("Add and edit certificate templates")}
                </div>
                <div className="admin-dashboard-main-bottom-item-left-btm">
                  <Link to={"/admin/settings/available-certificates"} >
                    <button
                      style={{
                        background: `${
                          clrs.CourseListBtn ? clrs.CourseListBtn : "hwb(224deg 78% 3%)"
                        }`,
                      }}
                    >
                      <div>{t("Open")}</div>
                    </button>
                  </Link>
                </div>
              </div>
              {/* <div className="admin-dashboard-main-bottom-item-right">
                <div
                  className="admin-dashboard-main-bottom-item-right-cnt"
                  style={{
                    background: `${clrs.CourseListBtn ? clrs.CourseListBtn : "hwb(224deg 78% 3%)"}`,
                  }}
                >
                </div>
              </div> */}
            </div>

        <div style={{ width: "24vw" }} className="admin-dashboard-main-bottom-item">
              <div className="admin-dashboard-main-bottom-item-left">
                <div className="admin-dashboard-main-bottom-item-left-top">
                  {t("Email Settings")}
                </div>
                <div className="admin-dashboard-main-bottom-item-left-mid">
                  {t("Add and edit Email Template")}
                </div>
                <div className="admin-dashboard-main-bottom-item-left-btm">
                  <Link to={"/admin/settings/email"} >
                    <button
                      style={{
                        background: `${
                          clrs.CourseListBtn ? clrs.CourseListBtn : "hwb(224deg 78% 3%)"
                        }`,
                      }}
                    >
                      <div>{t("Open")}</div>
                    </button>
                  </Link>
                </div>
              </div>
            </div>

           <div style={{ width: "24vw" }} className="admin-dashboard-main-bottom-item">
              <div className="admin-dashboard-main-bottom-item-left">
                <div className="admin-dashboard-main-bottom-item-left-top">
                  {t("Company and Department Settings")}
                </div>
                <div className="admin-dashboard-main-bottom-item-left-mid">
                  {t("Add and edit Company and Department")}
                </div>
                <div className="admin-dashboard-main-bottom-item-left-btm">
                  <Link to={"/admin/settings/company-department"} >
                    <button
                      style={{
                        background: `${
                          clrs.CourseListBtn ? clrs.CourseListBtn : "hwb(224deg 78% 3%)"
                        }`,
                      }}
                    >
                      <div>{t("Open")}</div>
                    </button>
                  </Link>
                </div>
              </div>
            </div>



        </div>

       

          </div>
          </div>
          </section>


      
    </>
  );
}

export default AdminSettings;
