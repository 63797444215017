import React, { useEffect, useState } from "react";
import { functions } from "../../../Firebase";
import { httpsCallable } from "firebase/functions";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import {
  AccordionDownArrowIcons,
  AccordionHeaderIcons,
  AccordionUpArrowIcons,
  TimelineHeaderIcons,
} from "../../../utilities/Icons/Icons";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { IP } from "../../../config";
import { getCookie } from "../../../Cookies";
import axios from "axios";

export const TimelineItemQuiz = ({ quizId, isLast }) => {
  const [quizName, setQuizName] = useState("");

  const getQuizInfo = httpsCallable(functions, "getQuizInfo");
  useEffect(() => {
    let CourseInfo = {
      method: "get",
      params: { quizId: quizId },
      url: `${IP}/userCourseDetails/quiz`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "",
    };
    axios(CourseInfo).then((res) => setQuizName(res.data.quizName));
  }, []);
  return (
    <TimelineItem>
      <TimelineSeparator className={"separator_padding"}>
        <TimelineDot className={"timeline_dot"} />
        {!isLast && <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent>
        <Box
          sx={{
            margin: "0px 0px 0px 6px",
            display: "flex",
            gap: "15px",
          }}
        >
          <Box>
            <AccordionHeaderIcons />
          </Box>
          <Typography
            sx={{
              color: "#007C84",
              fontSize: "18px",
              fontWeight: 500,
            }}
          >
            {quizName ? quizName : "Quiz"}
          </Typography>
        </Box>
      </TimelineContent>
    </TimelineItem>
  );
};
