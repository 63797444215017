import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { TRANSLATIONS_AR } from "./ArTranslate";
import { TRANSLATIONS_EN } from "./EnTranslate";
import { useTranslation } from 'react-i18next';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: TRANSLATIONS_EN,
    },
    ar: {
      translation: TRANSLATIONS_AR,
    },
  },
  interpolation: {
    escapeValue: false,
  },
  react: {
    wait: true,
    useSuspense: false,
 },
});

const languser = localStorage.getItem("languser")
  ? JSON.parse(localStorage.getItem("languser"))
  : localStorage.setItem("languser", JSON.stringify("en"));

i18n.changeLanguage(languser);
