import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { httpsCallable } from "firebase/functions";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { Link, useParams } from "react-router-dom";
import { getCookie } from "../../../Cookies";
import { functions } from "../../../Firebase";
import Navbar from "../../Component/NavbarTop";
import Sidebar from "../../Component/Sidebar";
import "../Dashboard/OverdueEnrollments.css";
import "../StyleAdminComponents/GroupList.css";
import Certificates from "./Components/Certificates";
import ExternalTraining from "./Components/ExternalTraining";
import InternalOfflineTraining from "./Components/InternalOfflineTraining";
import InternalTraining from "./Components/InternalTraining";
import PreviousTraining from "./Components/PreviousTraining";
import "./UserView.css";
import { IP } from "../../../config";
import axios from "axios";

function UserView() {
  const navigate = useNavigate();
  const goToCer = () => navigate("/admin/certificateRenewal/:id");
  const location = useLocation();
  const getCourses = httpsCallable(functions, "getCourses");
  const userEnrollment = httpsCallable(functions, "userEnrollment");
  const userDetails = location.state;
  const { clrs } = useSelector((state) => state.createClr);
  const [arr, setArr] = useState();
  const [pageNo, setPageNo] = useState();
  // const [curPage, setCurPage] = useState();
  const [popup, setpopup] = useState(false);
  const [open, setOpen] = useState(false);
  const [courseId, setCourseId] = useState(null);
  // const { t } = useTranslation();
  //console.log("userDetails", userDetails);
  const addPreviousCertificate = httpsCallable(
    functions,
    "addPreviousCertificate"
  );
  const [departmentObj, setDepartmentObj] = useState({});
  const [companyObj, setCompanyObj] = useState({});

  const [certificateDetail, setCertificateDetail] = useState({
    // employeeName: "",
    // employeeId: "",
    // employeeEmail: "",
    certificateName: "",
    refernceNumber: "",
    trainingProvider: "",
    issuedOn: "",
    expiry: "",
  });
  const [base64Certificate, setBase64Certificate] = useState({
    file: null,
    base64: "",
  });
  const handleInput = (e) => {
    const { name, value } = e.target;
    setCertificateDetail((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleFileInput = (e) => {
    const { files, name } = e.target;
    const reader = new FileReader();
    reader.onloadend = () => {
      setBase64Certificate({
        file: files[0],
        base64: reader.result,
      });
      e.target.value = ""; // to remove useless omkar bug
    };
    reader.readAsDataURL(files[0]);
  };

  const [disabled, setDisabled] = useState(false);
  const addCertificates = () => {
    //errs:=>
    if (
      certificateDetail.certificateName == "" ||
      // certificateDetail.refernceNumber == "" ||
      certificateDetail.trainingProvider == "" ||
      certificateDetail.issuedOn == ""
    ) {
      alert("Please enter all the fields");
      return;
    }
    if (base64Certificate.base64 == "" || base64Certificate.base64 == null) {
      alert("Please upload the certificate");
      return;
    }
    if (
      certificateDetail.expiry &&
      new Date(certificateDetail.issuedOn).getTime() >
        new Date(certificateDetail.expiry).getTime()
    ) {
      alert("Expiry date should be greater than issued date");
      return;
    }
    // if(new Date(certificateDetail.expiry) < new Date()){
    //   alert("Expiry date should be greater than current date");
    //   return;
    // }
    if (new Date(certificateDetail.issuedOn) > new Date()) {
      alert("Issued date should Not be greater than current date");
      return;
    }

    setDisabled(true);

    let configLesson = {
      method: "post",
      // params: { select: 0, uid: uid },
      url: `${IP}/userCourse/courseCertificate`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        certificateName: certificateDetail.certificateName,
        // employeeId: certificateDetail.employeeId,
        // employeeName: certificateDetail.employeeName,
        // employeeEmail: certificateDetail.employeeEmail,
        expiry: certificateDetail.expiry,
        issuedOn: certificateDetail.issuedOn,
        renewed: false,
        trainingProvider: certificateDetail.trainingProvider,
        trainingType: "Previous",
        refernceNumber: certificateDetail.refernceNumber,
        base64: base64Certificate.base64,
        uid: userDetails.userId,
      }),
    };
    axios(configLesson)
      .then(() => {
        setpopup(false);
        setDisabled(false);
      })
      .catch((err) => {
        setDisabled(false);
      });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleChange = (e) => {
    //console.log(e.target.value);
    setCourseId(e.target.value);
    //console.log("UserDetails", userDetails);
  };
  const handleCourseSubmit = () => {
    userEnrollment({
      uid: userDetails.userId,
      courseId: courseId,
      startDate: Date.now(),
    });
    setCourseId(null);
    handleClose();
  };
  const handleClose = () => {
    setBase64Certificate({
      file: null,
      base64: "",
    });
    setCertificateDetail({
      employeeName: "",
      employeeId: "",
      employeeEmail: "",
      certificateName: "",
      refernceNumber: "",
      trainingProvider: "",
      issuedOn: "",
      expiry: "",
    });
    setpopup(false);
    setOpen(false);
  };
  const [courseArray, setCourseArray] = useState(null);

  const data = [
    { name: "certifacte.pdf", DO: "22/02/20022", ED: "25/02/2022" },
    { name: "certifacte.pdf", DO: "22/02/20022", ED: "25/02/2022" },
  ];
  // console.log("userDetails", userDetails);
  const openModal = () => {
    setpopup(true);
  };
  useEffect(() => {
    // setCurPage(1);
    let k = Math.round(data.length / 5);
    let i = 1;
    let arr = [];
    while (k > 0) {
      arr.push(i++);
      k--;
    }
    setPageNo(arr);
    let tmparr = [];
    for (let i = 0; i < 5 && i < data.length; i++) {
      tmparr.push(data[i]);
    }
    setArr(tmparr);
    getCourses().then((res) => {
      setCourseArray(res.data?.map((item) => ({ ...item, selected: false })));
    });
  }, []);

  // const clickhandler = (i) => {
  //   if (i < 1) return;
  //   setCurPage(i);
  //   let tmparr = [];
  //   if (i == 1) {
  //     for (let j = 0; j < 5 && j < data.length; j++) {
  //       tmparr.push(data[j]);
  //     }
  //   } else {
  //     for (let j = (i - 1) * 5 - 1; j < i * 5 - 1 && j < data.length; j++) {
  //       tmparr.push(data[j]);
  //     }
  //   }
  //   if (tmparr != []) setArr(tmparr);
  // };

  /*Modal Function*/
  const goToHome = () => navigate("/");

  const getCompanyList = () => {
    let configLesson = {
      method: "get",
      // params: query,
      url: `${IP}/common/company`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      // data: "dataLesson",
    };
    axios(configLesson).then((res) => {
      // setUserCompany(res.data);

      res.data.map((el) => {
        setCompanyObj((prev) => ({ ...prev, [el.companyId]: el.companyName }));
      });
    });
  };

  const getDepartmentList = () => {
    let configLesson = {
      method: "get",
      // params: query,
      url: `${IP}/common/department`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      // data: "dataLesson",
    };
    axios(configLesson).then((res) => {
      // setUserDepart(res.data);

      res.data.map((el) => {
        setDepartmentObj((prev) => ({ ...prev, [el.departmentId]: el.name }));
      });
    });
  };
  useEffect(() => {
    if (getCookie("UID") == null || getCookie("ROLE") != "admin") {
      goToHome();
      return <></>;
    }
    getDepartmentList();
    getCompanyList();
  }, []);
  const { id } = useParams();
  var [file, setfile1] = useState("");
  var [trType, setType] = useState("");

  const onFileChange1 = (event) => {
    setfile1(event.target.files[0]);
  };

  // console.log(userDetails);
  return (
    <div className="section" style={{ backgroundColor: "#e5e5e5" }}>
      <Navbar page={t("Dashboard")} />
      <div className="createcourse-main">
        <Sidebar page={"user"} hidepage={true} />
        <div className="admin-overdue">
          <div className="admin-overdue-top">
            <div className="admin-overdue-top-item">
              {t("UserDetailsof")} {userDetails.name}
            </div>
          </div>
          <div className="admin-overdue-mid">
            <div className="admin-overdue-mid-user-view">
              <div className="admin-overdue-mid-user-view-cnt">
                <div> {t("FirstName")} :</div>
                <div className="admin-overdue-mid-user-view-txt-dim">
                  {userDetails.name}
                </div>
              </div>

              <div className="admin-overdue-mid-user-view-cnt">
                <div> {t("CompanyName")} :</div>
                <div className="admin-overdue-mid-user-view-txt-dim">
                  {companyObj[userDetails.companyId]}
                </div>
              </div>
              <div className="admin-overdue-mid-user-view-cnt">
                <div> {t("Department")} :</div>
                <div className="admin-overdue-mid-user-view-txt-dim">
                  {departmentObj[userDetails.departmentId]}
                </div>
              </div>
              <div className="admin-overdue-mid-user-view-cnt">
                <div> {t("JobTitle")} :</div>
                <div className="admin-overdue-mid-user-view-txt-dim">
                  {userDetails.jobTitle}
                </div>
              </div>
              <div className="admin-overdue-mid-user-view-cnt">
                <div> {t("JoiningDate")}:</div>
                <div className="admin-overdue-mid-user-view-txt-dim">
                  {new Date(
                    userDetails.joiningDate._seconds * 1000
                  ).toDateString()}
                </div>
              </div>
              <div className="admin-overdue-mid-user-view-cnt">
                <div>{t("EmailID")} :</div>
                <div className="admin-overdue-mid-user-view-txt-dim">
                  {userDetails.email}
                </div>
              </div>
              <div className="admin-overdue-mid-user-view-cnt">
                <div></div>
                <div className="admin-overdue-mid-user-view-txt-dim"></div>
              </div>
              <div className="admin-overdue-mid-user-view-cnt">
                <div> {t("EmployeeID")} :</div>
                <div className="admin-overdue-mid-user-view-txt-dim">
                  {userDetails.employeeId}
                </div>
              </div>
              <div className="admin-overdue-mid-user-view-cnt">
                <div> {t("Role")} :</div>
                <div className="admin-overdue-mid-user-view-txt-dim">
                  {userDetails.role == "ADMIN" && <div> {t("Admin")} </div>}
                  {userDetails.role == "USER" && <div> {t("User")} </div>}
                  {userDetails.role == "INSTRUCTOR" && (
                    <div>{t("Instructor")} </div>
                  )}
                </div>
              </div>
              {/*     <div  id="admin-course-main-top-id">
                <div className="label">Role</div>
                <div >
                  <div  className="elementLeft">
                    <input type="radio" value="admin" name="role" /> Admin      
                      </div>
                  <div  className="elementLeft">
                    <input type="radio" value="user" name="role" /> User 
                  </div>
                  <div className="elementLeft">
                    <input type="radio" value="instructor" name="role" />{" "}
                    Instructor
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <div
            className="admin-user-head"
            style={{
              height: "2.5vw",
              marginBottom: "-1.5vw",
            }}
          >
            {t("InternalLMSTraining")}
          </div>
          <button></button>
          {!open ? <InternalTraining uid={userDetails.userId} /> : ""}
          <div
            className="admin-user-head"
            style={{ backgroundColor: "#F47780" }}
          >
            {t("InternalOfflineTraining")}
          </div>
          <InternalOfflineTraining uid={userDetails.userId} />
          <div
            className="admin-user-head"
            style={{ backgroundColor: "#7783F4" }}
          >
            {t("ExternalTraining")}
          </div>

          <ExternalTraining uid={userDetails.userId} />
          <div
            className="admin-user-head"
            style={{
              background: "linear-gradient(90deg, #007848 0%, #004577 105.46%)",
              width: "30vw",
              height: "2.5vw",
              /* padding-top: 1vw; */
              /* margin-top: 3vw; */
              marginBottom: "-2.5vw",
            }}
          >
            {t("PreviousTrainingCertificates")}
          </div>
          <button
            style={{
              fontSize: "1.1vw",
              fontFamily: "'Readex Pro', sans-serif",
              color: "white",
              border: "none",
              borderRadius: "6px 6px 0px 0px",
              background: "linear-gradient(90deg, #007848 0%, #004577 105.46%)",
              marginLeft: "85.5vw",
              width: "5vw",
              height: "2.5vw",
            }}
            onClick={openModal}
            className="Add"
          >
            {t("Add")}
          </button>

          <PreviousTraining uid={userDetails.userId} />
          <div
            className="admin-user-head"
            style={{
              background: "linear-gradient(90deg, #007848 0%, #004577 105.46%)",
            }}
          >
            {t("Certificates")}
          </div>

          <Certificates uid={userDetails.userId} />
        </div>
        <div
          style={{ height: "100%", width: "6vw", backgroundColor: "white" }}
        ></div>
        <div className="courselist-main-cnt-back">
          <div className="courselist-main-cnt-back-btn">
            <Link to={"/admin/users"}>
              <button
                style={{
                  background: `${
                    clrs.CourseListBtn
                      ? clrs.CourseListBtn
                      : "hwb(224deg 78% 3%)"
                  }`,
                }}
              >
                {t("Back")}
              </button>{" "}
            </Link>
          </div>
        </div>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Add</DialogTitle>
          <DialogContent>
            <DialogContentText>Select Course Name</DialogContentText>
            <Select
              style={{ maxHeight: "50px" }}
              fullWidth
              variant="standard"
              onChange={handleChange}
            >
              {courseArray?.map((item) => {
                return (
                  <MenuItem value={item.courseId}>{item.courseName}</MenuItem>
                );
              })}
            </Select>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleCourseSubmit}>Add</Button>
          </DialogActions>
        </Dialog>
        <Dialog
          fullWidth={true}
          maxWidth="lg"
          open={popup}
          onClose={handleClose}
        >
          <DialogTitle>Add Previous Training Details</DialogTitle>
          <Container sx={{ background: "#fff" }}>
            <Box sx={{ width: "100%", background: "#fff", p: 2 }}>
              <form
                action=""
                style={{ display: "flex", alignItems: "flex-end" }}
              >
                <Box sx={{ width: "40%" }}>
                  <Box sx={{ mt: 1 }}>
                    <Typography sx={{ fontWeight: "700", mb: 1 }}>
                      Certificate Title
                    </Typography>
                    <input
                      type="text"
                      name="certificateName"
                      required
                      value={certificateDetail.certificateName}
                      onChange={handleInput}
                      style={{
                        width: "100%",
                        borderRadius: "6px",
                        padding: "6px 15px",
                        border: "1px solid #007C84",
                        outline: "none",
                      }}
                    />
                  </Box>
                  <Box sx={{ mt: 1 }}>
                    <Typography sx={{ fontWeight: "700", mb: 1 }}>
                      Reference Number
                    </Typography>
                    <input
                      type="text"
                      name="refernceNumber"
                      required
                      value={certificateDetail.refernceNumber}
                      onChange={handleInput}
                      style={{
                        width: "100%",
                        borderRadius: "6px",
                        padding: "6px 15px",
                        border: "1px solid #007C84",
                        outline: "none",
                      }}
                    />
                  </Box>
                  <Box sx={{ mt: 1 }}>
                    <Typography sx={{ fontWeight: "700", mb: 1 }}>
                      Course Provider
                    </Typography>
                    <input
                      type="text"
                      name="trainingProvider"
                      required
                      value={certificateDetail.trainingProvider}
                      onChange={handleInput}
                      style={{
                        width: "100%",
                        borderRadius: "6px",
                        padding: "6px 15px",
                        border: "1px solid #007C84",
                        outline: "none",
                      }}
                    />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "20px",
                      mt: 1,
                    }}
                  >
                    <Box>
                      <Typography sx={{ fontWeight: 600, mb: 1 }}>
                        Date Of Issue
                      </Typography>
                      <input
                        type="date"
                        name="issuedOn"
                        required
                        value={certificateDetail.issuedOn}
                        onChange={handleInput}
                        style={{
                          width: "100%",
                          borderRadius: "6px",
                          padding: "6px 15px",
                          border: "1px solid #007C84",
                          outline: "none",

                          fontWeight: 500,
                        }}
                      />
                    </Box>
                    <Box>
                      <Typography sx={{ fontWeight: 600, mb: 1 }}>
                        Expiry Date
                      </Typography>
                      <input
                        type="date"
                        name="expiry"
                        required
                        value={certificateDetail.expiry}
                        onChange={handleInput}
                        style={{
                          width: "100%",
                          borderRadius: "6px",
                          padding: "6px 15px",
                          border: "1px solid #007C84",
                          outline: "none",
                          fontWeight: 500,
                        }}
                      />
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "20px",
                      mt: 2,
                    }}
                  >
                    <Typography
                      sx={{
                        width: "50%",
                        fontWeight: 600,
                        mb: 1,
                      }}
                    >
                      Upload Certificate
                    </Typography>
                    <Box
                      sx={{
                        width: "50%",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button
                        component="label"
                        sx={{
                          textTransform: "none",
                          background:
                            "linear-gradient(90deg, rgb(0, 120, 72) 0%, rgb(0, 69, 119) 105.46%)",
                          color: "#fff",
                          width: "100px",
                          height: "40px",
                        }}
                      >
                        {"Upload"}
                        <input type="file" hidden onChange={handleFileInput} />
                      </Button>
                    </Box>
                  </Box>
                  {base64Certificate.file && (
                    <Box
                      sx={{
                        display: "-webkit-box",
                        justifyContent: "space-between",
                        gap: "30px",
                        mt: 2,
                      }}
                    >
                      <Typography
                        sx={{
                          width: "50%",
                          fontWeight: 600,
                          mb: 1,
                        }}
                      >
                        {base64Certificate.file?.name}
                      </Typography>
                      <Box
                        sx={{
                          width: "50%",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          component="label"
                          sx={{
                            textTransform: "none",
                            background:
                              "linear-gradient(90deg, rgb(0, 120, 72) 0%, rgb(0, 69, 119) 105.46%)",
                            color: "#fff",
                            width: "100px",
                            height: "40px",
                            fontSize: "14px",
                            fontWeight: 700,
                            fontFamily: "'Readex Pro', sans-serif",
                          }}
                          onClick={() =>
                            setBase64Certificate({ file: null, base64: "" })
                          }
                        >
                          {"Delete"}
                        </Button>
                      </Box>
                    </Box>
                  )}
                </Box>

                <Box
                  sx={{
                    width: "60%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    sx={{
                      background:
                        "linear-gradient(90deg, rgb(0, 120, 72) 0%, rgb(0, 69, 119) 105.46%)",
                      color: "#fff",
                      textTransform: "none",
                      width: "120px",
                      py: 1,
                      borderRadius: "10px",
                    }}
                    onClick={addCertificates}
                    disabled={disabled}
                  >
                    {disabled ? "Loading..." : "Submit"}
                  </Button>
                </Box>
              </form>
            </Box>
          </Container>
        </Dialog>
      </div>
    </div>
  );
}

export default UserView;
